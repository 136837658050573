import {
    MUTATION_ARCHIVE_PROJECT,
    MUTATION_PROJECT_UPDATE, MUTATION_UNARCHIVE_PROJECT,
    UPLOAD_DOCUMENT_MUTATION
} from "@/graphql/graphql";
import { MUTATION_ASSIGN_CONTACT, MUTATION_UNASSIGN_CONTACT } from "@/views/contacts/data/contacts_graphql";
import { MUTATION_PROJECT_CREATE_LOCATION, MUTATION_PROJECT_DELETE_LOCATION, MUTATION_PROJECT_UPDATE_LOCATION } from '@/views/locations/data/location_graphql';
import { MUTATION_PROJECT_CREATE_LOT, MUTATION_PROJECT_DELETE_LOT, MUTATION_PROJECT_UPDATE_LOT } from '@/views/lots/data/lots_graphql';

function sortLots(a, b) {
    const regex = /\d+/g;
    let aArray = a.number.match(regex) || [];
    let bArray = b.number.match(regex) || [];

    return sortLotNumber(aArray, bArray)
}
function sortLotNumber(aArray, bArray) {
    if (aArray.length === 0 && bArray.length === 0) {
        return 0
    }
    else if (aArray.length === 0 && bArray.length !== 0) {
        return -1
    }
    else if (aArray.length !== 0 && bArray.length === 0) {
        return 1
    }
    else {
        const a = aArray[0];
        const aRest = aArray.length > 1 ? aArray.slice(1) : []
        const b = bArray[0];
        const bRest = bArray.length > 1 ? bArray.slice(1) : []

        if (parseInt(a) < parseInt(b)) {
            return -1
        }
        else if (parseInt(a) > parseInt(b)) {
            return 1
        }
        else {
            return sortLotNumber(aRest, bRest);
        }
    }
}

function sortLocations(a, b) {
    return ('' + a.name).localeCompare(('' + b.name));
}

export const descriptionModule = {
    state: () => ({
        projects: [],
        defaultProject: {
            id: "",
            name: "",
            address: "",
            infoOpName: "",
            importantInfo: "",
            archived: false,
            lots: [],
            locations: [],
            contacts: [],
            doc: "",
            featureMultiLocation: false,
        },
        budgetModule: false,
        printValueLot: false,
        printValueQt: false,
        printValueUnitPrice: false,
    }),
    mutations: {
        archiveProjectMutation(state, { projectId, apolloClient }) {
            apolloClient.mutate({
                mutation: MUTATION_ARCHIVE_PROJECT,
                variables: {
                    projectId
                },
            })
        },
        unarchiveProjectMutation(state, { projectId, apolloClient }) {
            apolloClient.mutate({
                mutation: MUTATION_UNARCHIVE_PROJECT,
                variables: {
                    projectId
                },
            })
        },
        addLotMutation(state, { projectId, newLot, apolloClient }) {
            const oldProject = this.getters.getProjectById(projectId);

            if (oldProject.lots.find(l => l.number === newLot.number && l.name === newLot.name)) {
                newLot.name += " (1)"
            }

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_CREATE_LOT,
                variables: {
                    projectId,
                    newLot
                },
                update: (cache, result) => {
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: [...result.data.projectLotCreate.project.lots].sort(sortLots),
                        locations: oldProject.locations || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject });
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    projectLotCreate: {
                        __typename: 'ProjectLotCreate',
                        project: {
                            __typename: 'ProjectGType',
                            id: projectId,
                            lots: [...oldProject.lots, {
                                __typename: 'LotGType',
                                id: "-1",
                                number: newLot.number,
                                name: newLot.name,
                                fullName: "Lot n°" + newLot.number + " — " + newLot.name,
                                assignedCompany: {
                                    id: newLot.assignedCompany,
                                    name: newLot.assignedCompany,
                                },
                            }].sort(sortLots),
                        }
                    }
                }
            })
        },
        addLocationMutation(state, { projectId, newLocation, apolloClient }) {
            const oldProject = this.getters.getProjectById(projectId);

            if (oldProject.locations.find(l => l.name === newLocation.name)) {
                newLocation.name += " (1)"
            }

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_CREATE_LOCATION,
                variables: {
                    projectId,
                    newLocation
                },
                update: (cache, result) => {
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: oldProject.lots || [],
                        locations: [...result.data.projectLocationCreate.project.locations].sort(sortLocations) || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        initializeLocalProject(state, payload) {
            const projectId = payload.projectId;
            const projectData = payload.newProject;

            const sanitizedLots = [...payload.lots].sort(sortLots);
            const sanitizedLocations = [...payload.locations].sort(sortLocations);
            const sanitizedDoc = projectData.doc || "";
            const sanitizedContacts = [...payload.contacts];
            const sanitizedName = payload.name || "";
            const sanitizedAddress = payload.address || "";
            const sanitizedInfoOpName = payload.infoOpName || "";
            const sanitizedArchived = payload.archived || false;
            const sanitizedImportantInfo = payload.importantInfo || "";
            const sanitizedFeatureMultiLocation = payload.featureMultiLocation || false;


            const sanitizedProject = {
                id: projectId,
                name: sanitizedName,
                address: sanitizedAddress,
                infoOpName: sanitizedInfoOpName,
                importantInfo: sanitizedImportantInfo,
                archived: sanitizedArchived,
                lots: sanitizedLots,
                locations: sanitizedLocations,
                doc: sanitizedDoc,
                contacts: sanitizedContacts,
                featureMultiLocation: sanitizedFeatureMultiLocation,
                currentPhase: payload.currentPhase,
                phases: payload.phases,
            }

            state.projects.push(sanitizedProject);
        },
        updateProjectPhases(state, { projectId, currentPhase, phases }) {
            const oldProject = this.getters.getProjectById(projectId);
            const newProject = {
                ...oldProject, phases, currentPhase
            }
            this.commit("updateOrCreateLocalProject", { projectId, newProject })
        },
        updateProjectPropertiesMutation(state, { projectId, newProperties, apolloClient }) {
            const oldProject = this.getters.getProjectById(projectId);

            let newArchived = oldProject.archived;
            let newImportantInfo = oldProject.importantInfo;

            if ("archived" in newProperties) {
                newArchived = newProperties.archived;
            }
            if ("importantInfo" in newProperties) {
                newImportantInfo = newProperties.importantInfo;
            }
            const newName = newProperties?.name || oldProject.name;
            const newInfoOpAddress = newProperties?.address || oldProject.address;
            const newInfoOpName = newProperties?.infoOpName || oldProject.infoOpName;

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_UPDATE,
                variables: {
                    projectId,
                    newName,
                    newArchived,
                    newImportantInfo,
                    newInfoOpAddress,
                    newInfoOpName,
                },
                update: (cache, result) => {
                    const newProject = {
                        id: projectId,
                        name: result.data.projectUpdate.project.name,
                        address: result.data.projectUpdate.project.address,
                        infoOpName: result.data.projectUpdate.project.infoOpName,
                        importantInfo: result.data.projectUpdate.project.importantInfo,
                        archived: result.data.projectUpdate.project.archived,
                        lots: oldProject.lots || [],
                        locations: oldProject.locations || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        updateOrCreateLocalProject(state, payload) {
            const projectId = payload.projectId;
            const projectData = payload.newProject;

            const sanitizedLots = projectData.lots || [];
            const sanitizedLocations = projectData.locations || [];
            const sanitizedDoc = projectData.doc || "";
            const sanitizedContacts = projectData.contacts || [];
            const sanitizedName = projectData.name || "";
            const sanitizedAddress = projectData.address || "";
            const sanitizedInfoOpName = projectData.infoOpName || "";
            const sanitizedImportantInfo = projectData.importantInfo || "";
            const sanitizedArchived = projectData.archived || false;
            const sanitizedFeatureMultiLocation = projectData.featureMultiLocation || false;

            const sanitizedProject = {
                id: projectId,
                name: sanitizedName,
                address: sanitizedAddress,
                infoOpName: sanitizedInfoOpName,
                importantInfo: sanitizedImportantInfo,
                archived: sanitizedArchived,
                lots: sanitizedLots,
                locations: sanitizedLocations,
                doc: sanitizedDoc,
                contacts: sanitizedContacts,
                featureMultiLocation: sanitizedFeatureMultiLocation,
                currentPhase: projectData.currentPhase,
                phases: projectData.phases || [],
            }

            const projectIndex = state.projects.findIndex(p => p.id === projectId);

            if (projectIndex === -1) {
                state.projects.push(sanitizedProject)
            }
            else {
                state.projects[projectIndex] = sanitizedProject;
            }
        },
        deleteLotMutation(state, payload) {
            const projectId = payload.projectId;
            const apolloClient = payload.apolloClient;
            const lotId = payload.lotId;

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_DELETE_LOT,
                variables: {
                    projectId,
                    lotId
                },
                update: (cache, result) => {
                    const oldProject = this.getters.getProjectById(projectId);
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: [...result.data.projectLotDelete.project.lots].sort(sortLots) || [],
                        locations: oldProject.locations || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation || false,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        deleteLocationMutation(state, payload) {
            const projectId = payload.projectId;
            const apolloClient = payload.apolloClient;
            const locationId = payload.locationId;

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_DELETE_LOCATION,
                variables: {
                    projectId,
                    locationId
                },
                update: (cache, result) => {
                    const oldProject = this.getters.getProjectById(projectId);
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: oldProject.lots || [],
                        locations: [...result.data.projectLocationDelete.project.locations].sort(sortLocations) || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation || false,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                }
            })
        },
        updateLot(state, {projectId, lotId, name, number}) {
            console.log("updateLot", {projectId, lotId, name, number})
            let project = state.projects.find(p => p.id === projectId);

            if (project) {
                let lot = JSON.parse(JSON.stringify(project.lots.find(l => l.id === lotId)));

                lot.name = name
                lot.number = number

                project.lots = [...project.lots.filter(l => l.id !== lotId), lot].sort(sortLots);
            }
        },
        updateLots(state, {projectId, lots}) {
            let project = state.projects.find(p => p.id === projectId);

            if (project) {
                project.lots = JSON.parse(JSON.stringify(lots));
            }
        },
        editLotMutation(state, payload) {
            const projectId = payload.projectId;
            const lotId = payload.lotId;
            const newLot = payload.newLot;

            const apolloClient = payload.apolloClient;

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_UPDATE_LOT,
                variables: {
                    projectId,
                    lotId,
                    ...newLot,
                },
                update: (cache, result) => {
                    const oldProject = this.getters.getProjectById(projectId);
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: [...result.data.projectLotUpdate.project.lots].sort(sortLots) || [],
                        locations: oldProject.locations || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation || false,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        editLocationMutation(state, payload) {
            const projectId = payload.projectId;
            const locationId = payload.locationId;
            const newLocation = payload.newLocation;

            const apolloClient = payload.apolloClient;

            apolloClient.mutate({
                mutation: MUTATION_PROJECT_UPDATE_LOCATION,
                variables: {
                    projectId,
                    locationId,
                    newLocation,
                },
                update: (cache, result) => {
                    const oldProject = this.getters.getProjectById(projectId);
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: oldProject.lots || [],
                        locations: [...result.data.projectLocationUpdate.project.locations].sort(sortLocations) || [],
                        doc: oldProject.doc || "",
                        contacts: oldProject.contacts || [],
                        featureMultiLocation: oldProject.featureMultiLocation || false,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        pushProjectToServer(state, payload) {
            const projectId = payload.projectId;
            const newDoc = payload.newProject;

            // Sync changes to local project
            this.commit("updateOrCreateLocalProject", { projectId, newProject: payload.newProject })

            // Push to distant project
            payload.apolloClient.mutate(
                {
                    mutation: UPLOAD_DOCUMENT_MUTATION,
                    variables: {
                        projectId,
                        newDoc
                    }
                }
            )
        },
        assignContactMutation(state, { projectId, contactId, role, apolloClient }) {
            apolloClient.mutate({
                mutation: MUTATION_ASSIGN_CONTACT,
                variables: {
                    projectId: projectId,
                    contactId: contactId,
                    role: role,
                },
                update: (cache, result) => {
                    const oldProject = this.getters.getProjectById(projectId);
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: oldProject.lots || [],
                        locations: oldProject.locations || [],
                        doc: oldProject.doc || "",
                        contacts: [...result.data.projectAssignContact.project.contacts],
                        featureMultiLocation: oldProject.featureMultiLocation || false,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        unassignContactMutation(state, { projectId, contactId, apolloClient }) {
            apolloClient.mutate({
                mutation: MUTATION_UNASSIGN_CONTACT,
                variables: {
                    projectId: projectId,
                    contactId: contactId,
                },
                update: () => {
                    const oldProject = this.getters.getProjectById(projectId);
                    const newProject = {
                        id: projectId,
                        name: oldProject.name,
                        address: oldProject.address,
                        infoOpName: oldProject.infoOpName,
                        importantInfo: oldProject.importantInfo,
                        archived: oldProject.archived,
                        lots: oldProject.lots || [],
                        locations: oldProject.locations || [],
                        doc: oldProject.doc || "",
                        contacts: [...oldProject.contacts.filter(c => c.contact.id !== contactId)],
                        featureMultiLocation: oldProject.featureMultiLocation || false,
                        currentPhase: oldProject.currentPhase,
                        phases: oldProject.phases,
                    }
                    // Sync changes to local project
                    this.commit("updateOrCreateLocalProject", { projectId, newProject })
                },
            })
        },
        editDoc(state, payload) {
            const projectId = payload.projectId;
            const newJSONDoc = payload.newJSONDoc;

            const oldProject = this.getters.getProjectById(projectId);
            const newProject = {
                id: projectId,
                name: oldProject.name,
                address: oldProject.address,
                infoOpName: oldProject.infoOpName,
                importantInfo: oldProject.importantInfo,
                archived: oldProject.archived,
                lots: oldProject.lots || [],
                locations: oldProject.locations || [],
                doc: newJSONDoc,
                contacts: oldProject.contacts || [],
                featureMultiLocation: oldProject.featureMultiLocation || false,
                currentPhase: oldProject.currentPhase,
                phases: oldProject.phases,
            }

            this.commit("updateOrCreateLocalProject", { projectId, newProject })
        },
        toggleBudgetModule(state){
            state.budgetModule = !state.budgetModule
        },
        togglePrintValueLot(state){
            state.printValueLot = !state.printValueLot
        },
        togglePrintValueQt(state){
            state.printValueQt = !state.printValueQt
        },
        togglePrintValueUnitPrice(state){
            state.printValueUnitPrice = !state.printValueUnitPrice
        },
    },
    actions: {
        addLotToFocusProject(context, payload) {
            let apolloClient = payload.apolloClient;
            const projectId = context.rootState.display.currentProjectFocus;

            const newLot = {
                number: ("" + payload.number) || "0",
                name: payload.name || "Nouveau Lot",
                assignedCompany: payload.assignedCompany || null
            }

            this.commit("addLotMutation", { projectId, newLot, apolloClient })
        },
        addLocationToFocusProject(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;

            let newLocation = {
                name: payload.name || "Nouvel Emplacement"
            }

            this.commit("addLocationMutation", { projectId, newLocation, apolloClient })
        },
        deleteLot(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;
            let lotId = payload.lotId;

            if (projectId && apolloClient && lotId) {
                this.commit("deleteLotMutation", { projectId, apolloClient, lotId })
            }
        },
        editLot(context, payload) {
            let apolloClient = payload.apolloClient;

            const projectId = context.rootState.display.currentProjectFocus;
            const lotId = payload.lotId;

            const newLot = {
                name: payload.newLot.name || "Nouveau Lot",
                number: ("" + payload.newLot.number) || "0",
                assignedCompany: payload.newLot.assignedCompany || null
            };

            if (projectId && apolloClient && lotId) {
                this.commit("editLotMutation", { projectId, apolloClient, lotId, newLot })
            }
        },
        editLotPlanning(context, { lotId, apolloClient, startDate, endDate, comment }) {
            const projectId = context.rootState.display.currentProjectFocus;

            const newLot = {
                startDate: startDate || "",
                endDate: endDate || "",
                comment: comment || "",
            };

            if (projectId && apolloClient && lotId) {
                this.commit("editLotMutation", { projectId, apolloClient, lotId, newLot })
            }
        },
        editLocation(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;
            let locationId = payload.locationId;

            const newLocation = {
                name: payload.newLocation.name || "Nouvel Emplacement 01"
            };

            if (projectId && apolloClient && locationId) {
                this.commit("editLocationMutation", { projectId, apolloClient, locationId, newLocation })
            }
        },
        deleteLocation(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;
            let locationId = payload.locationId;

            if (projectId && apolloClient && locationId) {
                this.commit("deleteLocationMutation", { projectId, apolloClient, locationId })
            }
        },
        updateProjectProperties(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;
            const newProperties = payload.newProperties;

            if (projectId && apolloClient && newProperties) {
                this.commit("updateProjectPropertiesMutation", { projectId, apolloClient, newProperties })
            }
        },
        syncFocusDoc(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;
            let newJSONDoc = payload.newJSONDoc;

            if (projectId && newJSONDoc && apolloClient) {
                this.commit("editDoc", { projectId, newJSONDoc, apolloClient })
            }
        },
        pushFocus(context, payload) {
            let projectId = context.rootState.display.currentProjectFocus;
            let apolloClient = payload.apolloClient;
            let newProject = this.getters.getProjectById(projectId)

            if (projectId && apolloClient) {
                this.commit("pushProjectToServer", { projectId, newProject, apolloClient })
            }
        },
        assignContact(context, payload) {
            let apolloClient = payload.apolloClient;

            let projectId = context.rootState.display.currentProjectFocus;
            let contactId = payload.contactId;
            let role = payload.role;

            if (projectId && contactId && role && apolloClient) {
                this.commit("assignContactMutation", {
                    projectId, contactId, role, apolloClient
                })
            }
        },
        unassignContact(context, payload) {
            let apolloClient = payload.apolloClient;

            let projectId = context.rootState.display.currentProjectFocus;
            let contactId = payload.contactId;

            if (projectId && contactId && apolloClient) {
                this.commit("unassignContactMutation", {
                    projectId, contactId, apolloClient
                })
            }
        },

    },
    getters: {
        budgetModule(state){
            return state.budgetModule;
        },
        printValueLot(state){
            return state.printValueLot;
        },
        printValueQt(state){
            return state.printValueQt;
        },
        printValueUnitPrice(state){
            return state.printValueUnitPrice;
        },
        getProjectById: (state) => (projectId) => {
            const projectIndex = state.projects.findIndex(p => p.id && (p.id === projectId));

            let ret = {
                id: projectId,
                name: "",
                address: "",
                infoOpName: "",
                importantInfo: "",
                archived: false,
                lots: [],
                locations: [],
                doc: "",
                contacts: [],
                featureMultiLocation: false,
                ready: false,
                currentPhase: null,
                phases: null,
            }

            if (projectIndex !== -1 && state.projects[projectIndex]) {
                ret.lots = [...state.projects[projectIndex].lots].sort(sortLots);
                ret.locations = [...state.projects[projectIndex].locations].sort(sortLocations);
                ret.doc = state.projects[projectIndex].doc;
                ret.contacts = state.projects[projectIndex].contacts;
                ret.name = state.projects[projectIndex].name;
                ret.address = state.projects[projectIndex].address;
                ret.infoOpName = state.projects[projectIndex].infoOpName;
                ret.importantInfo = state.projects[projectIndex].importantInfo;
                ret.archived = state.projects[projectIndex].archived;
                ret.featureMultiLocation = state.projects[projectIndex].featureMultiLocation;
                ret.ready = true;
                ret.currentPhase = state.projects[projectIndex].currentPhase;
                ret.phases = state.projects[projectIndex].phases;
            }

            return ret;
        },
        canSafelyDeleteLot: (state) => (projectId, lotId) => {
            // checks if there are registered ouvrages blocks or text blocks for the given lot
            let ret = true;
            const project = state.projects.find(p => p.id === projectId)

            if (lotId && project) {
                if (project.doc && project.doc.content) {
                    const lotContent = project.doc.content.find(n => n?.attrs?.id === lotId)
                        ?.content.find(n => n.type === "lotContent")?.content;

                    if (lotContent) {
                        ret = lotContent.every(n => {
                            return n.type !== "lotOuvrage" && n.type !== "lotText"
                        });
                    }
                }
            }
            return ret;
        },
        canSafelyDeleteLocation: (state) => (projectId, locationId) => {
            // checks if there are registered ouvrages blocks or text blocks for the given lot
            let ret = true;
            const project = state.projects.find(p => p.id === projectId)

            if (locationId && project) {
                if (project.doc && project.doc.content) {
                    project.doc.content.forEach(lotNode => {
                        if (lotNode && lotNode.type === "docLot" && lotNode?.content) {
                            const lotContent = lotNode.content.find(n => n.type === "lotContent");
                            if (lotContent && lotContent?.content) {

                                lotContent.content.filter(n => n.type === "lotOuvrage").forEach(n => {
                                    if (n && n?.attrs && (n?.attrs?.locations || n?.attrs?.location)) {
                                        if (project.featureMultiLocation) {
                                            if (n?.attrs?.locations?.includes(locationId)) {
                                                ret = false;
                                            }
                                        } else {
                                            if (n?.attrs?.location === locationId) {
                                                ret = false;
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }
            return ret;
        },
    }
}
