<!--suppress ALL -->
<template>
    <!-- MAIN -->
    <div :class="modalActive ? 'blur-content' : ''">
        <PrintHeader pageTitle="Planning" sectionTitle="Phases du projet"></PrintHeader>
        <div class="row g-5 print-hidden">
            <div class="col-12">
                <div class="card">
                    <img v-if="this.currentProject?.image?.url || true" class="card-img-top mh-100px object-fit-cover"
                         :src="this.currentProject?.image?.url || 'https://app.kalm.ai/static/img/kalm-bg.png'"
                         alt="Image de couverture du projet">
                    <div class="card-body pt-5 pb-3">
                        <div class="d-flex justify-content-start align-items-baseline">
                            <h2 class="mb-4">{{ this.currentProject?.name }}</h2>
                            <!--              <button class="ms-2 btn btn-sm btn-icon btn-light-primary btn-active-secondary"><span class="bi bi-pencil"></span></button>-->
                        </div>
                        <p class="text-muted text-break align-baseline mb-2" v-if="this.currentProject?.infoOpName"><span
                            class="bi me-2 text-muted align-baseline bi-house-door"></span>{{
                                this.currentProject?.infoOpName
                            }}</p>
                        <p class="text-muted text-break align-baseline mb-2" v-if="this.currentProject?.address"><span
                            class="bi me-2 text-muted align-baseline bi-pin"></span>{{ this.currentProject?.address }}
                        </p>
                    </div>
                    <div class="card-footer d-flex pt-0 pb-4 border-0">
                        <span class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px cursor-pointer"
                            @click="$router.push({name: 'project.planning', params: {id: this.currentProject?.id}})">{{ this.currentProject?.currentPhase || "En cours" }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-5">
                <div class="card" id="ProjectInformation">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-gear"></i>Généralités</span>
                        </h3>
                    </div>
                    <div class="card-body w-100 row pt-2">
                        <div class="col-12">
                            <div class="form-group mb-3">
                                <label class="text-muted">Nom du projet</label>
                                <input class="form-control form-control-lg form-control-solid" type="text"
                                    v-model="this.projectProperties.name">
                            </div>
                            <div class="form-group mb-3">
                                <label class="text-muted">Nom de l'opération</label>
                                <input class="form-control form-control-lg form-control-solid" type="text"
                                    v-model="this.projectProperties.infoOpName">
                            </div>
                            <div class="form-group mb-3">
                                <label class="text-muted">Adresse</label>
                                <input class="form-control form-control-lg form-control-solid" type="text"
                                    v-model="this.projectProperties.address">
                            </div>
                            <div class="form-group mb-3">
                                <label class="text-muted">Tags</label>
                                <TagMultiSelect v-model="this.editProject.tags"></TagMultiSelect>
                            </div>
                        </div>
                        <div class="flex-column text-end">
                            <button :disabled="this.isArchived" class="btn btn-primary btn-active-accent"
                                    @click="this.updateProjectProperties()">
                                Enregistrer
                                <span v-if="this.buttonProjectPropertiesLoading"
                                    class="ms-1 spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Chargement...</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-5 print-hidden">
            <div class="col-xl-12 mb-5">
                <!--begin::Table Widget 2-->
                <div class="card card-stretch h-100 flex-grow-1">
                    <!--begin::Header-->
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 bi-person me-2"></i>Intervenants</span>
                        </h3>
                        <div class="card-toolbar">
                            <ul class="nav nav-pills nav-pills-sm nav-light">
                                <li class="nav-item align-middle">
                                    <button :disabled="this.isArchived"
                                            class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                                            @click="modalAssignContactShow()"
                                            id="AssignContactButton">+ Inviter
                                    </button>
                                </li>
                                <li class="nav-item align-middle">
                                    <router-link :to="{name: 'contacts'}"
                                                class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder">
                                        + Créer
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body pt-3 pb-0 mt-n3">
                        <div class="tab-content mt-4" id="ProjectHomePageContactTable">
                            <!--begin::Tap pane-->
                            <div class="tab-pane fade show active" id="kt_tab_pane_2_1" role="tabpanel"
                                aria-labelledby="kt_tab_pane_2_1">
                                <!--begin::Table-->
                                <div class="table-responsive">
                                    <table class="table table-borderless align-middle">
                                        <thead>
                                        <tr>
                                            <th class="p-0 w-80px"></th>
                                            <th class="p-0 min-w-150px"></th>
                                            <th class="p-0 min-w-120px"></th>
                                            <th class="p-0 min-w-70px"></th>
                                            <th class="p-0 min-w-70px"></th>
                                        </tr>
                                        </thead>
                                    <draggable tag="tbody"
                                               handle=".lot-drag-handle"
                                               v-model="this.projectContacts"
                                               @start="console.log('start')"
                                               @end="console.log('end')"
                                               v-bind="dragOptions"
                                               item-key="contact.id"
                                               :component-data="{
                                                        tag: 'div',
                                                        type: 'transition-group',
                                                        name: !drag ? 'flip-list' : null,
                                                   }"
                                    >
                                        <template #item="{element}">
                                            <tr class="h-100">
                                                <td class="px-0 py-3 rounded-start d-flex">
                                                    <div class="lot-drag-handle" style="cursor: grab;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="width: 15px; height: 100%; display: block;" class="align-self-center">
                                                            <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                                                        </svg>
                                                    </div>
                                                    <div class="symbol symbol-55px mt-1 me-5">
                                                    <span class="symbol-label bg-light-primary align-items-end">
                                                        <img alt="Logo" src="/assets/media/svg/avatars/001-boy.svg"
                                                             class="mh-40px"/>
                                                    </span>
                                                    </div>
                                                </td>
                                                <td class="px-0">
                                                    <a class="text-gray-800 fw-bold fs-6">{{ element.contact?.company }}</a>
                                                    <span class="text-muted fw-bold d-block mt-1">{{ element.contact?.name }}</span>
                                                </td>
                                                <td></td>
                                                <td class="text-center">
                                                <span class="text-gray-800 fw-bolder d-block fs-6">{{
                                                        contact_role_options.find(o => o.id === element.role)?.name || "Entreprise"
                                                    }}</span>
                                                </td>
                                                <td class="text-center">
                                                    <span class="d-block text-muted">{{ element.contact?.email }}</span>
                                                    <span class="d-block text-muted">{{ element.contact?.phone }}</span>
                                                </td>
                                                <td class="text-end pe-0 rounded-end">
                                                    <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                                                            @click="modalEditContactRole(element.contact.id)" disabled>
                                                        <span class="bi bi-pencil"></span>
                                                    </button>
                                                    <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                                                            v-tooltip:bottom="'Modifier le contact'"
                                                            @click="modalEditContactShow(element.contact.id)"
                                                    >
                                                        <span class="bi bi-person-vcard"></span>
                                                    </button>
                                                    <button :disabled="this.isArchived"
                                                            class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                                                            v-tooltip:bottom="'Retirer l\'intervenant'"
                                                            @click="modalUnassignContactShow(element.contact?.id)">
                                                        <span class="bi bi-x"></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </draggable>
                                    </table>
                                </div>
                                <!--end::Table-->
                            </div>
                            <!--end::Tap pane-->
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Table Widget 2-->
            </div>
        </div>
        <div class="row g-5 print-hidden">
            <div class="col-xl-6">
                <!--begin::Table Widget 2-->
                <div class="card card-stretch">
                    <!--begin::Header-->
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder text-dark fs-3"><i
                                class="bi fs-3 me-2 bi-grid"></i>Lots</span>
                        </h3>
                        <div class="card-toolbar">
                            <div>

                            </div>
                            <ul class="nav nav-pills nav-pills-sm nav-light">
                                <li class="align-middle" title="Ouvrir la fenêtre de numérotation auto des lots">
                                    <button :disabled="this.isArchived"
                                            class="align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                                            @click="modalAutoSortShow"><span class="bi bi-stars"></span></button>
                                </li>
                                <li class="align-middle">
                                    <button :disabled="this.isArchived" title="Importer des lots depuis un autre projet"
                                            class="align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                                            @click="modalImportLotShow">+ Importer
                                    </button>
                                </li>
                                <li class="align-middle">
                                    <button :disabled="this.isArchived" title="Ajouter un lot"
                                            class="align-middle btn btn-active-light btn-color-muted py-2 ms-2 px-4 fw-bolder"
                                            @click="modalLotShow">+ Ajouter
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body pt-3 mt-n3">
                        <div class="tab-content mt-4" id="myTabTables2">
                            <div class="table-responsive">
                                <table class="table table-borderless align-middle" id="ProjectHomePageLotTable">
                                    <thead>
                                    <tr>
                                        <th class="p-0 min-w-150px"></th>
                                        <th class="p-0 min-w-50px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="lot in this.currentProject?.lots" :key="lot.id">
                                        <td class="px-0" :class="'ps-'+(lot.number.match(/\./g) || []).length*5">
                                            <a class="text-gray-800 fw-bolder text-hover-primary cursor-pointer fs-6"
                                                @click="this.$router.push({name: 'project.description', params: {id: this.currentProject?.id}, hash: '#lot-'+lot.id})">{{
                                                    lot.fullName
                                                }}</a>
                                            <span
                                                class="text-muted fw-bold d-block mt-1">{{ lot.assignedCompany?.id ? lot.assignedCompany.company || lot.assignedCompany.name : "Non attribué" }}</span>
                                        </td>
                                        <td class="text-end w-100px pe-0">
                                            <button :disabled="this.isArchived"
                                                    class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                                                    v-tooltip:bottom="'Modifier le lot'"
                                                    @click="modalEditLotShow(lot.id)"
                                            >
                                                <span class="bi bi-pencil-fill"></span>
                                            </button>
                                            <button :disabled="this.isArchived"
                                                    class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white"
                                                    v-tooltip:bottom="'Supprimer le lot'"
                                                    @click="modalConfirmDeleteLotShow(lot.id)"
                                            >
                                                <span class="bi bi-trash-fill"></span>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Table Widget 2-->
            </div>
            <div class="col-xl-6">
                <!--begin::Table Widget 2-->
                <div class="card card-stretch">
                    <!--begin::Header-->
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-pin"></i>Emplacements</span>
                        </h3>
                        <div class="card-toolbar">
                            <ul class="nav nav-pills nav-pills-sm nav-light">
<!--                                <li class="nav-item align-middle">
                                    <button :disabled="this.isArchived" @click="this.modalImportLocationShow()"
                                            class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder">
                                        + Importer
                                    </button>
                                </li>-->
                                <li class="nav-item align-middle">
                                    <button :disabled="this.isArchived" @click="this.modalAddLocationShow()"
                                            class="nav-link align-middle btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                                            id="AddLocationButton">
                                        + Ajouter
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body pt-3 mt-n3">
                        <div class="tab-content mt-4" id="myTabTables2">
                            <div class="table-responsive">
                                <table class="table table-borderless align-middle" id="ProjectHomePageLocationTable">
                                    <thead>
                                    <tr>
                                        <th class="p-0 min-w-150px"></th>
                                        <th class="p-0 min-w-50px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="location in this.currentProject?.locations" :key="location.id">
                                        <td class="px-0">
                                            <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{
                                                    location.name
                                                }}</a>
                                        </td>
                                        <td class="text-end w-100px pe-0">
                                            <button :disabled="this.isArchived"
                                                    class="ms-1 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                                                    @click="modalEditLocationShow(location.id)"
                                                    v-tooltip:bottom="'Modifier l\'emplacement'"
                                            >
                                                <span class="bi bi-pencil-fill"></span>
                                            </button>
                                            <button :disabled="this.isArchived"
                                                    class="ms-1 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white"
                                                    v-tooltip:bottom="'Supprimer l\'intervenant'"
                                                    @click="modalConfirmDeleteLocationShow(location.id)"
                                            >
                                                <span class="bi bi-trash-fill"></span>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Table Widget 2-->
            </div>
        </div>
    </div>
    <!-- MODAL ADD LOT -->
    <AddLotModal @close="modalLotCancel" @validate="modalLotValidate" v-model="modals.addLot.default"
        :contacts="this.project.contacts" :projectId="this.$route.params.id" v-if="modals.addLot.show"
        :lots="this.currentProject?.lots"></AddLotModal>
    <!-- MODAL EDIT LOT -->
    <EditLotModal @close="modalEditLotCancel" @validate="modalEditLotValidate" v-model="modals.editLot.newLot"
        :contacts="this.project.contacts" :projectId="this.$route.params.id" v-if="modals.editLot.show"></EditLotModal>
    <!-- CONFIRM DELETE LOT -->
    <DeleteLotModal @close="modalConfirmDeleteLotCancel" @validate="modalConfirmDeleteLotValidate"
        :lotId="modals.confirmDeleteLot.id" v-if="modals.confirmDeleteLot.show" :project="this.project"></DeleteLotModal>
    <!-- ADD LOCATION -->
    <AddLocationModal @close="modalAddLocationCancel" @validate="modalAddLocationValidate"
        v-model="modals.addLocation.current.name" :projectId="this.$route.params.id" v-if="modals.addLocation.show"></AddLocationModal>
    <!-- CONFIRM DELETE LOCATION -->
    <DeleteLocationModal @close="modalConfirmDeleteLocationCancel" @validate="modalConfirmDeleteLocationValidate"
        :locationId="modals.confirmDeleteLocation.id" :project="this.project" v-if="modals.confirmDeleteLocation.show"></DeleteLocationModal>
    <!-- EDIT LOCATION -->
    <EditLocationModal @close="modalEditLocationCancel" @validate="modalEditLocationValidate" v-model="modals.editLocation.name"
        :projectId="this.$route.params.id" :locationId="modals.editLocation.id" v-if="modals.editLocation.show"></EditLocationModal>
    <!-- ASSIGN CONTACT -->
    <AssignContactModal @close="modalAssignContactCancel" @validate="modalAssignContactValidate"
        v-model="modals.assignContact.current" :contacts="this.contacts" :projectId="this.$route.params.id"
        v-if="modals.assignContact.show"></AssignContactModal>
    <!-- CONFIRM UNASSIGN CONTACT -->
    <UnassignContactModal @close="modalUnassignContactCancel" @validate="modalUnassignContactValidate"
    :contactId="modals.unassignContact.contactId" :projectId="this.project?.id" v-if="modals.unassignContact.show"></UnassignContactModal>
    <!-- MODAL - Import from lot -->
    <ImportLotModal v-if="modals.importLot.show" @close="modalImportLotCancel"
        @validate="modalImportLotValidate" :currentProject="this.currentProject"></ImportLotModal>
    <!-- MODAL - Lot Sort -->
    <SortLotModal :project="this.currentProject" v-if="this.modals.autoSort.show" @close="modalAutoSortCancel"
        @validate="modalAutoSortValidate"></SortLotModal>
    <!-- MODAL - Import from location -->
    <ImportLocationModal v-if="modals.importLocation.show" @close="modalImportLocationCancel" @validate="modalImportLocationValidate"
        :currentProject="this.currentProject"></ImportLocationModal>
    <!-- MODAL CONTACT UPDATE -->
    <ContactUpdateModal v-model="this.editContact" v-if="this.modals.editContact.show" @close="this.modalEditContactCancel" :callback="this.modalEditContactValidate"></ContactUpdateModal>
</template>

<script>
import { 
    GET_PROJECT_BY_ID,
    MUTATION_PROJECT_UPDATE
} from "@/graphql/graphql";
import kalmStore from "@/store";
import { GET_ALL_CONTACTS_SIMPLE } from "@/views/contacts/data/contacts_graphql";
import PrintHeader from "@/views/project/tender/PrintHeader";
import TagMultiSelect from "@/views/tags/presentation/tag-select/TagMultiSelect.vue";

import { toast } from "vue3-toastify";
import EditLotModal from "../components/EditLotModal.vue";
import AddLotModal from "../components/AddLotModal.vue";
import DeleteLotModal from "../components/DeleteLotModal.vue";
import AddLocationModal from "../components/AddLocationModal.vue";
import DeleteLocationModal from "../components/DeleteLocationModal.vue";
import EditLocationModal from "../components/EditLocationModal.vue";
import AssignContactModal from "../components/AssignContactModal.vue";
import UnassignContactModal from "../components/UnassignContactModal.vue";
import ImportLotModal from "../components/ImportLotModal.vue";
import SortLotModal from "../components/SortLotModal.vue";
import ImportLocationModal from "../components/ImportLocationModal.vue";
import ContactUpdateModal from "@/views/contacts/presentation/components/ContactUpdateModal.vue";
import draggable from "vuedraggable";

export default {
    name: "ProjectHome",
    components: {
        draggable,
        ContactUpdateModal,
        PrintHeader,
        TagMultiSelect,
        EditLotModal,
        AddLotModal,
        DeleteLotModal,
        AddLocationModal,
        DeleteLocationModal,
        EditLocationModal,
        AssignContactModal,
        UnassignContactModal,
        ImportLotModal,
        SortLotModal,
        ImportLocationModal,
    },
    apollo: {
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: this.$route.params.id
                }
            },
            update(data) {
                this.editProject.tags = data.project.tags.map(t => t.id);

                return data.project
            }
        },
        contacts: {
            query: GET_ALL_CONTACTS_SIMPLE,
        },
    },
    data() {
        return {
            dragOptions: {
                animation: 150,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            },
            modals: {
                editContact: {
                    show: false,
                    contact: null
                },
                autoSort: {
                    show: false,
                },
                importLot: {
                    show: false,
                },
                importLocation: {
                    show: false,
                },
                editLot: {
                    show: false,
                    newLot: {
                        lotId: "",
                        number: "",
                        name: "",
                        assignedCompanyId: ""
                    },
                },
                addLot: {
                    show: false,
                    default: {
                        number: 0,
                        name: "Nouveau Lot",
                        assignedCompanyId: null,
                    },
                },
                confirmDeleteLot: {
                    show: false,
                    id: ""
                },
                addLocation: {
                    show: false,
                    default: {
                        name: "Nouvel Emplacement 01",
                    },
                    current: {
                        name: "Nouvel Emplacement 01",
                    },
                },
                confirmDeleteLocation: {
                    show: false,
                    id: ""
                },
                editLocation: {
                    show: false,
                    id: "",
                    name: "",
                },
                assignContact: {
                    show: false,
                    default: {
                        contactId: "",
                        role: "CONTRACTOR",
                    },
                    current: {
                        contactId: "",
                        role: "CONTRACTOR",
                    },
                },
                unassignContact: {
                    show: false,
                    contactId: '',
                },
            },
            contact_role_options: [
                {
                    id: "MOA",
                    name: "MOA - Maîtrise d'Ouvrage",
                },
                {
                    id: "MOE",
                    name: "MOE - Maîtrise d'Œuvre",
                },
                {
                    id: "CONTRACTOR",
                    name: "Entreprise",
                },
                {
                    id: "BET",
                    name: "Bureaux d'études & conseil",
                }
            ],
            editProject: {
                name: "",
                address: "",
                infoOpName: "",
                importantInfo: "",
                archived: "",
                tags: [],
            },
            project: {
                contacts: [],
            },
            contacts: [],
            buttonProjectPropertiesLoading: false,
        }
    },
    methods: {
        getContactById(contactId) {
            return this.contacts.find(c => c.id === contactId);
        },
        getLotById(lotId) {
            return this.currentProject?.lots.find(l => l.id === lotId);
        },
        getLocationById(locationId) {
            return this.currentProject?.locations.find(l => l.id === locationId);
        },
        modalEditContactShow(contactId) {
            this.modals.editContact.contact = this.getContactById(contactId);
            this.modals.editContact.show = true;
        },
        modalEditContactCancel() {
            this.modals.editContact.show = false;
            this.modals.editContact.contact = null;
        },
        modalEditContactValidate() {
            this.$apollo.queries.project.refetch();

            this.modalEditContactCancel();
        },
        modalImportLotShow() {
            this.modals.importLot.show = true;
        },
        modalImportLotCancel() {
            this.modals.importLot.show = false;
        },
        modalImportLotValidate() {
            this.$apollo.queries.project.refetch();

            this.modalImportLotCancel();
        },
        modalAutoSortShow() {
            this.modals.autoSort.show = true;
        },
        modalAutoSortCancel() {
            this.modals.autoSort.show = false;
        },
        modalAutoSortValidate() {
            this.$apollo.queries.project.refetch();
            this.modals.autoSort.show = false;
        },
        modalImportLocationShow() {
            this.modals.importLocation.show = true;
        },
        modalImportLocationCancel() {
            this.modals.importLocation.show = false;
        },
        modalImportLocationValidate() {
            this.$apollo.queries.project.refetch();

            this.modalImportLocationCancel();
        },
        modalLotShow() {
            this.modals.addLot.show = true;
        },
        modalLotCancel() {
            this.modals.addLot.default = {
                        number: 0,
                        name: "Nouveau Lot",
                        assignedCompanyId: null,
                    };
            this.modals.addLot.show = false;
        },
        modalLotValidate() {
            this.$apollo.queries.project.refetch();
            this.modals.addLot.show = false;
        },
        modalConfirmDeleteLotShow(id) {
            this.modals.confirmDeleteLot.id = id
            this.modals.confirmDeleteLot.show = true;
        },
        modalConfirmDeleteLotValidate() {
            this.$apollo.queries.project.refetch();

            this.modalConfirmDeleteLotCancel();
        },
        modalConfirmDeleteLotCancel() {
            this.modals.confirmDeleteLot.show = false;
        },
        modalEditLotShow(id) {
            this.modals.editLot.newLot.lotId = id
            const oldLot = JSON.parse(JSON.stringify(this.getLotById(id)))
            this.modals.editLot.newLot = {
                lotId: oldLot.id,
                name: oldLot.name,
                number: oldLot.number,
                assignedCompanyId: oldLot.assignedCompany?.id || null
            }
            this.modals.editLot.show = true;
        },
        modalEditLotValidate() {
            this.$apollo.queries.project.refetch();

            this.modalEditLotCancel();
        },
        modalEditLotCancel() {
            this.modals.editLot.show = false;
            this.modals.editLot.newLot.lotId = "";
        },
        modalConfirmDeleteLocationShow(id) {
            this.modals.confirmDeleteLocation.id = id
            this.modals.confirmDeleteLocation.show = true;
        },
        modalConfirmDeleteLocationValidate() {
            this.$apollo.queries.project.refetch();

            this.modalConfirmDeleteLocationCancel();
        },
        modalConfirmDeleteLocationCancel() {
            this.modals.confirmDeleteLocation.id = "";
            this.modals.confirmDeleteLocation.show = false;
        },
        modalAddLocationShow() {
            this.modals.addLocation.current.name = this.modals.addLocation.default.name;
            this.modals.addLocation.show = true;
        },
        modalAddLocationCancel() {
            this.modals.addLocation.current.name = this.modals.addLocation.default.name;
            this.modals.addLocation.show = false;
        },
        modalAddLocationValidate() {
            this.$apollo.queries.project.refetch();

            this.modalAddLocationCancel()
        },
        modalEditLocationShow(id) {
            this.modals.editLocation.id = id
            this.modals.editLocation.name = this.project?.locations?.find(l => l.id === id).name || "Nouvel Emplacement 01";
            this.modals.editLocation.show = true;
        },
        modalEditLocationValidate() {
            this.$apollo.queries.project.refetch();

            this.modalEditLocationCancel();
        },
        modalEditLocationCancel() {
            this.modals.editLocation.show = false;
            this.modals.editLocation.id = "";
            this.modals.editLocation.name = "Nouvel Emplacement 01";
        },
        modalAssignContactShow() {
            this.modals.assignContact.current.contactId = this.modals.assignContact.default.contactId
            this.modals.assignContact.current.role = this.modals.assignContact.default.role
            this.modals.assignContact.show = true;
        },
        modalAssignContactValidate() {
            this.$apollo.queries.project.refetch();

            this.modals.assignContact.show = false;
        },
        modalAssignContactCancel() {
            this.modals.assignContact.show = false;
        },
        modalUnassignContactShow(contactId) {
            this.modals.unassignContact.show = true;
            this.modals.unassignContact.contactId = contactId;
        },
        modalUnassignContactCancel() {
            this.modals.unassignContact.show = false;
            this.modals.unassignContact.contactId = '';
        },
        modalUnassignContactValidate() {
            this.$apollo.queries.project.refetch();

            this.modalUnassignContactCancel();
        },
        async updateProjectProperties() {
            this.buttonProjectPropertiesLoading = true;

            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_PROJECT_UPDATE,
                    variables: {
                        projectId: this.currentProject?.id,
                        newName: this.projectProperties.name,
                        newInfoOpName: this.projectProperties.infoOpName,
                        newInfoOpAddress: this.projectProperties.address,
                        tagIds: this.projectProperties.tags,
                    },
                    refetchQueries: [
                        {
                            query: GET_PROJECT_BY_ID,
                            variables: {
                                id: this.currentProject?.id
                            },
                        }
                    ]
                })

                toast.success("Les informations du projet ont été mises à jour avec succès.")
                this.buttonProjectPropertiesLoading = false;
            } catch {
                toast.error("Une erreur est survenue lors de la mise à jour des informations du projet.")
                this.buttonProjectPropertiesLoading = false;
            }
        },
    },
    computed: {
        projectContacts: {
            get() {
                return this.project?.contacts;
            },
            set(newValue) {
                this.$apollo.mutate({
                    mutation: MUTATION_PROJECT_UPDATE,
                    variables: {
                        projectId: this.project.id,
                        projectContactsOrder: newValue.map(c => c.contact.id),
                    },
                    optimisticResponse: {
                        __typename: "Mutation",
                        projectUpdate: {
                            __typename: "ProjectUpdate",
                            project: {
                                __typename: "ProjectGType",
                                id: this.project.id,
                                contacts: newValue,
                            }
                        }
                    },
                }).then(() => {
                    toast.success("L'ordre des intervenants a été enregistré")
                }).catch((error) => {
                    console.error(error)
                    toast.error("Une erreur est survenue lors de la mise à jour de l'ordre des intervenants")
                })
            }
        },
        modalActive() {
            return kalmStore.state.display.profileToggled
                || Object.values(this.modals).some(m => m.show);
        },
        currentProject() {
            return this.project;
        },
        isArchived() {
            return this.currentProject?.archived;
        },
        getProjects() {
            return kalmStore.state.description.projects;
        },
        projectProperties: {
            get() {
                return {
                    name: this.editProject.name || this.currentProject.name,
                    address: this.editProject.address || this.currentProject.address,
                    infoOpName: this.editProject.infoOpName || this.currentProject.infoOpName,
                    importantInfo: this.editProject.importantInfo || this.currentProject.importantInfo,
                    archived: this.editProject.archived,
                    tags: this.editProject.tags || this.currentProject.tags.map(t => t.id),
                }
            },
            set(newValue) {
                this.editProject = newValue;
            }
        },
        editContact() {
            return {...this.modals.editContact.contact}
        }
    },
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}
</style>

<style scoped>

@media print {
    .print-hidden {
        display: none !important;
    }

    .planning-card-shadow {
        box-shadow: none;
    }
}
</style>