<template>
    <KalmModal modalTitle="Supprimer la tâche" @close="close">
        <template v-slot:body>
            <p>{{this.message}}</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
            <button v-if="!isSaving" type="button" class="btn btn-danger" @click="confirm">Supprimer</button>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import {
DELETE_TASK,
GET_TASKS,
BATCH_DELETE_TASKS,
} from "../../../data/tasks_graphql";
import { toast } from 'vue3-toastify';

export default {
    name: "DeleteTaskConfirmModal",
    components: {
        KalmModal,
        LoadingButton
    },
    props: {
        taskId: {
            type: String || Object,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isSaving: false,
        };
    },
    methods: {
        async confirm() {
            if (typeof this.taskId === "object") return this.confirmBatchDelete();
            this.isSaving = true;
            try {
                await this.$apollo.mutate({
                    mutation: DELETE_TASK,
                    variables: {
                        id: this.taskId,
                    },
                    refetchQueries: [
                        {
                            query: GET_TASKS,
                            variables: {
                                projectId: this.projectId
                            }
                        },
                    ],
                });
                toast.success('Tâche supprimée avec succès');
            } catch (error) {
                toast.error('Erreur lors de la suppression de la tâche');
                console.error('Delete task error:', error);
            }
            this.isSaving = false;
            this.$emit("validate");
            this.close();
        },
        async confirmBatchDelete() {
            this.isSaving = true;
            try {
                await this.$apollo.mutate({
                    mutation: BATCH_DELETE_TASKS,
                    variables: {
                        ids: this.taskId,
                        projectId: this.projectId,
                    },
                    refetchQueries: [
                        {
                            query: GET_TASKS,
                            variables: {
                                projectId: this.projectId
                            }
                        },
                    ],
                });
                toast.success('Tâches supprimées avec succès');
            } catch (error) {
                toast.error('Erreur lors de la suppression des tâches');
                console.error('Delete task error:', error);
            }
            this.isSaving = false;
            this.$emit("validate");
            this.close();
        },
        close() {
            this.$emit("close");
        },
    },
    computed: {
        message() {
            if (typeof this.taskId === "object") {
                return this.taskId?.length > 1 ? `Etes-vous sur de vouloir supprimer ces ${this.taskId.length} tâches ?` : "Etes-vous sur de vouloir supprimer cette tâche ?";
            }
            return "Etes-vous sur de vouloir supprimer cette tâche ?";
        }
    }
};
</script>