import gql from 'graphql-tag';

export const LINK_CREATE = gql`
    mutation LinkCreate($fileId: UUID!) {
        linkCreate(fileId: $fileId) {
            link {
                id
                expirationDate
                createdAt
                updatedAt
            }
        }
    }
`;