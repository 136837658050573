<template>
  <div class="symbol symbol-55px mt-1 me-5">
    <span class="symbol-label bg-light-primary align-items-end">
      <img
        alt="Logo"
        :src="this.url"
        :class="this.height"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "AvatarLogo",
  props: {
    url: {
      type: String,
      required: false,
      default: "/assets/media/svg/avatars/001-boy.svg",
    },
    height: {
      type: String,
      required: false,
      default: "mh-80px",
    },
  },
};
</script>

<style lang="scss" scoped></style>
