<template>
    <KalmModal modalTitle="Supprimer" @close="close">
        <template v-slot:body>
            <p>Etes-vous sur de vouloir supprimer ?</p>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
            <button v-if="!isSaving" type="button" class="btn btn-danger" @click="confirm">Supprimer</button>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import {
FILE_ARCHIVE,
} from "../../../data/files_graphql";
export default {
    name: "DeleteFileConfirmModal",
    components: {
        KalmModal,
        LoadingButton
    },
    props: {
        fileId: {
            type: String,
            required: true,
        },
        callback: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isSaving: false,
        };
    },
    methods: {
        confirm() {
            this.isSaving = true;
            this.$apollo.mutate({
                mutation: FILE_ARCHIVE,
                variables: {
                    fileId: this.fileId,
                },
            }).then((data) => {
                console.log('File deleted:', data);
                this.callback();
            }).catch((error) => {
                console.error('Delete file error:', error);
            });
            this.isSaving = false;
            this.close();
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>