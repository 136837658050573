<template>
    <div
        class="modal"
        tabindex="-1"
        @keydown.esc="this.$emit('close')"
        style="display: block;"
    >
        <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
            <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
                <!--begin::Close-->
                <div
                class="btn btn-icon btn-sm btn-light-primary"
                @click="this.$emit('close')"
                >
                <i class="bi bi-x-lg"></i>
                </div>
                <!--end::Close-->
            </div>

            <div class="modal-body p-0">
                <!--begin::Stepper-->
                <div
                class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between"
                >
                <!--begin::Content2-->
                <div class="d-flex flex-row-fluid justify-content-center">
                    <!--begin::Form-->
                    <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-3 completed">
                        <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                            <h3 class="fw-bolder text-dark display-6">
                            <span v-if="modal?.contactId"
                                >Demande de chiffrage</span
                            >
                            <span v-else>Budget estimatif</span>
                            </h3>
                            <p class="text-gray-500 fw-bold">
                            <span v-if="modal?.contactId">
                                <i class="bi bi-building me-2"></i
                                >{{
                                getContactNameById(modal.contactId)
                                }}
                            </span>
                            </p>
                        </div>
                        <!--end::Heading-->

                        <!--
                            <div class="mb-5 w-100">
                            <div class="d-flex justify-content-between">
                                <label class="fs-6 mb-2 fw-bolder text-dark form-label">Lien à partager</label>
                            </div>
                            <div class="input-group input-group-lg input-group-solid">
                                <input type="text" ref="dceModalLinkShareField" class="form-control form-control-lg form-control-solid" value="dce.kalm.ai/iAkzndaIaBJAUhaU" placeholder="Email">
                                <span @click="copyLinkToClipboard()"
                                    class="input-group-text btn rounded-end btn-outline-primary btn-active-bg-light btn-active-light pe-0">
                                <i class="la la-copy fs-3 pe-4"></i>
                                </span>
                            </div>
                            </div>
                        -->
                        <!--begin::Form Group-->
                        <div class="mb-5 w-100">
                            <div class="d-flex justify-content-between">
                            <label class="fs-6 mb-2 form-label"
                                >Fichiers à télécharger et partager :</label
                            >
                            </div>
                            <div class="row g-1">
                            <button
                                @click="
                                this.downloadAsXlsxV2(
                                    modal.proposalId
                                )
                                "
                                :disabled="
                                !this.canDownloadAsXlsxV2(
                                    modal.proposalId
                                )
                                "
                                class="col-12 w-100 btn btn-light btn-active-accent align-content-center"
                            >
                                <span
                                class="bi bi-file-earmark-spreadsheet align-self-baseline me-1"
                                ></span
                                >DPGF.xlsx
                            </button>
                            <!--                          <button class="col-12 w-100 btn btn-light btn-active-accent" disabled><span class="bi bi-file-earmark-pdf me-2"></span>CCTP.pdf — À VENIR</button>-->
                            </div>
                        </div>
                        <!--end::Form Group-->
                        </div>
                    </div>
                    <!--end::Step-->

                    <!--                  <div class="alert bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                        &lt;!&ndash;begin::Icon&ndash;&gt;
                        <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i class="bi fs-1 bi-check"></i></span>
                        &lt;!&ndash;end::Icon&ndash;&gt;
                        <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                        begin::Title
                        <h5 class="mb-1">Demande de chiffrage créée</h5>
                        end::Title
                        begin::Content
                        <span>Vous pouvez télécharger le DCE et l'envoyer à {{ getContactNameById(modal.contactId) }}</span>
                        end::Content
                        </div>
                    </div>-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <div>
                        <button
                            type="button"
                            @click="this.$emit('close')"
                            class="btn btn-lg btn-primary btn-active-light-primary"
                        >
                            Terminer
                        </button>
                        </div>
                    </div>
                    <!--end::Actions-->
                    </div>
                    <!--end::Form-->
                </div>
                <!--end::Content2-->
                </div>
                <!--end::Stepper-->
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { sortLots, sortChiffrages } from '@/utils/sort';
import writeXlsxFile from "write-excel-file";
import kalmStore from '@/store';
import { GET_PROJECT_BY_ID } from '@/graphql/graphql';

export default {
    name: "ProposalDownloadModal",
    apollo: {
        query: GET_PROJECT_BY_ID,
        variables() {
            return {
                id: kalmStore.getters.currentProjectFocus
            }
        },
    },
    props: {
        modal: {
            type: Object,
            required: true,
        },
        proposals: {
            type: Array,
            required: true,
        },
        contacts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            headerRowCore: [
                {
                    value: 'Lot',
                    fontWeight: 'bold'
                },
                {
                    value: 'Emplacement',
                    fontWeight: 'bold'
                },
                {
                    value: 'Ouvrage',
                    fontWeight: 'bold'
                },
                {
                    value: 'Description détaillée',
                    fontWeight: 'bold'
                },
                {
                    value: 'Quantité',
                    fontWeight: 'bold'
                },
                {
                    value: 'Prix HT',
                    fontWeight: 'bold'
                },
                {
                    value: 'TVA',
                    fontWeight: 'bold'
                },
                {
                    value: 'Prix TTC',
                    fontWeight: 'bold'
                }
            ],
        }
    },
    computed: {
        currentProject() {
            return this.project;
        }
    },
    methods: {
        async downloadAsXlsx(proposalId) {
            if (!this.proposals) return;

            const proposal = this.proposals.find(p => p.id === proposalId);
            const project = this.currentProject

            const includeChiffrages = this.modal.includeChiffrages;

            const HEADER_ROW_CORE = this.headerRowCore;
            let chiffrages = [];  

            JSON.parse(JSON.stringify(proposal.lots)).sort(sortLots).forEach(l => {
                const sortedChiffrages = proposal.chiffrages.filter(c => c.lot.id === l.id).sort(sortChiffrages);

                sortedChiffrages.forEach(c => {
                if(project.featureMultiLocation){
                    chiffrages.push([
                        { type: String, value: c.lot.fullName ? String(c.lot.fullName) : "-" },
                        { type: String, value: c.locations.map(l => l.name) ? c.locations.map(l => l.name).join(", ") : "-" },
                        { type: String, value: c.title ? String(c.title) : "-" },
                        { type: String, value: c.description ? String(c.description) : "-" },
                        { type: String, value: c.qt ? String(c.qt) : "-" },
                        { type: String, value: c.value && includeChiffrages ? String(c.value) : "-" },
                        { type: String, value: "" },
                        { type: String, value: "" },
                    ])
                }
                else {
                    chiffrages.push([
                        { type: String, value: c.lot.fullName ? String(c.lot.fullName) : "-" },
                        { type: String, value: c.location?.name ? String(c.location?.name) : "-" },
                        { type: String, value: c.title ? String(c.title) : "-" },
                        { type: String, value: c.description ? String(c.description) : "-" },
                        { type: String, value: c.qt ? String(c.qt) : "-" },
                        { type: String, value: includeChiffrages ? c.value && c.value !== 0 ? String(c.value) : "-" : "-" },
                        { type: String, value: "" },
                        { type: String, value: "" },
                    ])
                }
                })
            })


            const data_core = [HEADER_ROW_CORE, ...chiffrages];
            const data_general = [[
                { value: 'nom', fontWeight: 'bold' },
                { value: 'adresse', fontWeight: 'bold' },
                { value: 'moe_name', fontWeight: 'bold' },
                { value: 'moe_number', fontWeight: 'bold' },
                { value: 'moe_email', fontWeight: 'bold' },
                { value: 'moa_name', fontWeight: 'bold' },
                { value: 'moa_number', fontWeight: 'bold' },
                { value: 'moa_email', fontWeight: 'bold' },
            ],
                [
                { type: String, value: project.name },
                { type: String, value: project.address },
                { type: String, value: project.contacts.find(c => c.role === "MOE")?.contact?.name || "" },
                { type: String, value: project.contacts.find(c => c.role === "MOE")?.contact?.phone || "" },
                { type: String, value: project.contacts.find(c => c.role === "MOE")?.contact?.email || "" },
                { type: String, value: project.contacts.find(c => c.role === "MOA")?.contact?.name || "" },
                { type: String, value: project.contacts.find(c => c.role === "MOA")?.contact?.phone || "" },
                { type: String, value: project.contacts.find(c => c.role === "MOA")?.contact?.email || "" },
                ]]
            const today_date = (new Date()).toISOString().slice(0, 10);

            await writeXlsxFile([data_core, data_general], {
                fileName: `kalm_chiffrage_${proposal.contact.name}_${today_date}.xlsx`,
                sheets: ['DATA', 'GENERAL']
            })
        },
        canDownloadAsXlsxV2(proposalId) {
            if (!this.proposals) return;
            
            const proposal = this.proposals.find(p => p.id === proposalId);
            return proposal?.fileXlsxUrl
        },
        downloadAsXlsxV2(proposalId) {
            if (!this.proposals) return;
            
            const proposal = this.proposals.find(p => p.id === proposalId);

            if(proposal?.fileXlsxUrl){
                if(proposal.fileXlsxUrl.startsWith("http")){
                    window.open(proposal.fileXlsxUrl);
                }
                else {
                    window.open(process.env.VUE_APP_URL_BACK + proposal.fileXlsxUrl);
                }
            }
        },
        getContactNameById(contactId) {
            let ret = "";
            if(contactId) {
                const contact = this.contacts.find( c => c.id === contactId );
                ret = contact?.company || contact?.name || "";
            }
            return ret;
        },
    },
};
</script>

<style lang="scss" scoped></style>
