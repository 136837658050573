<template>
    <div class="row g-5 print-hidden">
        <div class="col-12 mb-5">
            <div class="card">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark fs-3"><i
                            class="bi bi-arrow-left cursor-pointer align-middle fs-1 me-3"
                            @click="this.$router.push({name: 'canevas'})"></i>Mon caneva</span>
                    </h3>
                </div>
                <div class="card-body w-100 row pt-2">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="text-muted">Nom du caneva</label>
                            <input class="form-control form-control-lg form-control-solid" type="text"
                                v-model="this.localCaneva.name">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <TagMultiSelect v-model="this.localCaneva.tags"></TagMultiSelect>
                        </div>
                    </div>
                    <div class="flex-column text-end">
                        <!--:disabled="this.biblio.type === 'DEFAULT'"-->
                        <button class="btn btn-primary btn-active-accent"
                                :disabled="!this.leaveGuard"
                                @click="this.saveCaneva()">
                            <span v-if="this.loading" class="spinner-border spinner-border-sm"></span>
                            <span v-else>Enregistrer</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <CanevaTodo :caneva="this.caneva" @importCaneva="importCaneva" @close="refetchApolloQueries"/>
    </div>
    <ImportCanevaModal :caneva-id="modals.importCaneva.canevaId" v-if="modals.importCaneva.show" @close="close" />
</template>

<script>
import { toast } from 'vue3-toastify';
import { GET_CANEVA_BY_ID_QUERY, UPDATE_CANEVA_MUTATION } from '../data/canevas_graphql';
import CanevaTodo from './components/CanevaTodo.vue';
import ImportCanevaModal from './components/ImportCanevaModal.vue';
import TagMultiSelect from '@/views/tags/presentation/tag-select/TagMultiSelect.vue';

export default {
    name: "CanevaItemPage",
    data() {
        return {
            localCaneva: {
                name: '',
                tags: []
            },
            leaveGuard: false,
            caneva: {
                name: ''
            },
            loading: false,
            modals: {
                importCaneva: {
                    show: false,
                    canevaId: null
                }
            }
        }
    },
    components: {
        CanevaTodo,
        ImportCanevaModal,
        TagMultiSelect
    },
    apollo: {
        caneva: {
            query: GET_CANEVA_BY_ID_QUERY,
            variables() {
                return {
                    id: this.canevaId
                }
            },
            update(data) {
                console.log(data)
                this.localCaneva = { ...data.caneva, tags: data.caneva.tags.map(t => t.id) }
                return data.caneva
            },
        }
    },
    watch: {
        'localCaneva.name': function() {
            if (!this.caneva) return;

            if (this.localCaneva.name !== this.caneva.name) {
                this.leaveGuard = true;
            }
        },
        'localCaneva.tags': function() {
            if (!this.caneva) return;
            console.log(this.localCaneva.tags)
            if (JSON.stringify(this.localCaneva.tags) !== JSON.stringify(this.caneva.tags.map(t => t.id))) {
                this.leaveGuard = true;
            }
        }
    },
    methods: {
        async saveCaneva() {
            try {
                this.loading = true;
                console.log(this.localCaneva.tags)
                await this.$apollo.mutate({
                    mutation: UPDATE_CANEVA_MUTATION,
                    variables: {
                        canevaId: this.canevaId,
                        name: this.localCaneva.name,
                        tagIds: this.localCaneva.tags
                    },
                    refetchQueries: {
                        query: GET_CANEVA_BY_ID_QUERY,
                        variables: { id: this.canevaId }
                    },
                })
                this.leaveGuard = false;
                toast.success('Caneva enregistré avec succès')
            } catch (e) {
                toast.error('Erreur lors de l\'enregistrement du caneva')
                console.error(e)
            }
            this.loading = false;
        },
        importCaneva(canevaId) {
            this.modals.importCaneva.canevaId = canevaId;
            this.modals.importCaneva.show = true;
        },
        refetchApolloQueries() {
            this.$apollo.queries.caneva.refetch();
        },
        close() {
            this.modals.importCaneva.show = false;
            this.modals.importCaneva.canevaId = null;
            this.refetchApolloQueries();
        }
    },
    computed: {
        canevaId() {
            return this.$route.params.id
        },
    },
}
</script>

<style lang="scss" scoped>

</style>