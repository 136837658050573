import gql from 'graphql-tag';

export const GET_TAGS = gql`
    query Tags {
        tags {
            id
            name
            color
        }
    }
`;