<template>
    <div class="modal"
        tabindex="-1"
        style="display: block;"
        id="SortLotModal">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="closeModal()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Content-->
                        <div class="d-flex flex-row-fluid justify-content-center">
                            <!--begin::Form-->
                            <div ref="contactEditModalForm"
                                class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                <!--begin::Step-->
                                <div class="pb-3 completed">
                                    <div class="w-100">
                                        <!--begin::Heading-->
                                        <div class="pb-5">
                                            <h3 class="fw-bolder text-dark display-6">Réordonner les lots</h3>
                                            <p class="text-muted">Glissez les lots pour changer l'ordre et la numérotation, puis enregistrez.</p>
                                        </div>
                                        <!--end::Heading-->

                                        <div class="">
                                            <h3>Lots</h3>
                                            <draggable handle=".lot-drag-handle"
                                                    v-model="this.lots"
                                                    @start="drag = true"
                                                    @end="drag = false; this.renumberLots()"
                                                    v-bind="dragOptions"
                                                    :component-data="{
                                                        tag: 'div',
                                                        type: 'transition-group',
                                                        name: !drag ? 'flip-list' : null
                                                    }"
                                                    item-key="id"
                                            >
                                                <template #item="{ element }">
                                                    <div class="d-flex mb-2 bg-white border-primary-subtle rounded" :class="'ms-'+4*element.indentation">
                                                        <div class="lot-drag-handle" style="cursor: grab;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="width: 15px; height: 100%; display: block;" class="align-self-center">
                                                                <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                                                            </svg>
                                                        </div>
                                                        <div class="btn mx-1 btn-icon btn-sm btn-secondary" title="Décaler vers la gauche (lot parent)" @click="element.indentation > 0 ? element.indentation-- : 0; this.renumberLots()">&lt;</div>
                                                        <div class="btn mx-1 btn-icon btn-sm btn-secondary" title="Décaler vers la droite (sous-lot)" @click="element.indentation++; this.renumberLots()">&gt;</div>
                                                        <div class="ms-2">Lot n°{{ element?.number }} — {{ element?.name }}</div>
                                                    </div>
                                                </template>
                                            </draggable>
                                        </div>

                                        <div class="form-group mt-3 mb-2">
                                            <label class="form-label">Option : numéro du premier lot</label>
                                            <input type="number" class="form-control"
                                                v-model="this.defaultStartNumber"
                                                placeholder="0" @input="this.renumberLots()">
                                        </div>
                                    </div>
                                </div>
                                <!--end::Step-->

                                <!--begin::Actions-->
                                <div class="d-flex justify-content-end">
                                    <div>
                                        <button class="btn btn-lg btn-light"
                                                @click="closeModal()">
                                            Annuler
                                        </button>
                                        <button class="btn btn-lg btn-primary ms-2"
                                                @click="validateSortLot()"
                                        >
                                            Enregistrer
                                        </button>
                                    </div>
                                </div>
                                <!--end::Actions-->
                            </div>
                            <!--end::Form-->
                        </div>
                        <!--end::Content-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { MUTATION_PROJECT_UPDATE_LOT } from '@/views/lots/data/lots_graphql'

export default {
    name: 'SortLotModal',
    components: {
        draggable
    },
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            drag: false,
            dragOptions: {
                animation: 150,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            },
            defaultStartNumber: 0,
            selectedLots: []
        }
    },
    mounted() {
        this.selectedLots = JSON.parse(JSON.stringify(this.project?.lots.map((l) => ({
            id: l.id,
            number: l.number,
            name: l.name,
            indentation: l.number.split('.').length - 1,
        }))));
    },
    methods: {
        renumberLots() {
            let counter = this.defaultStartNumber;
            let indentationCounters = [];

            this.selectedLots = this.selectedLots.map((lot) => {
                if (lot.indentation === 0) {
                    // Reset the indentation counters and increment the main counter
                    indentationCounters = [];
                    lot.number = counter++;
                } else {
                    // Update the indentation counter for the current level
                    if (!indentationCounters[lot.indentation - 1]) {
                        indentationCounters[lot.indentation - 1] = 0;
                    }
                    indentationCounters[lot.indentation - 1]++;

                    // Construct the number using the main counter and indentation counters
                    lot.number = `${counter - 1}.${indentationCounters.slice(0, lot.indentation).join('.')}`;
                }
                return lot;
            });
        },
        closeModal() {
            this.defaultStartNumber = 0;
            this.$emit('close');
        },
        async validateSortLot() {
            try {
                this.selectedLots.forEach(async (lot) => {
                    const variables = {
                        projectId: this.project?.id,
                        lotId: lot.id,
                        number: lot.number.toString(),
                        name: lot.name,
                    }
    
                    await this.$apollo.mutate({
                        mutation: MUTATION_PROJECT_UPDATE_LOT,
                        variables,
                    })
                })
            } catch (error) {
                console.error(error);
            }

            this.$emit('validate');
        }
    },
    computed: {
        lots: {
            get() {
                return JSON.parse(JSON.stringify(this.selectedLots));
            },
            set(value) {
                this.selectedLots = JSON.parse(JSON.stringify(value))
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>