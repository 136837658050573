<template>
  <div :class="isModalActive ? 'blur-content' : ''"
       class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div class="toolbar mb-5" id="kt_toolbar">
      <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
          <!--begin::Title-->
          <h3 class="text-dark fw-bolder my-1">Mon studio</h3>
          <!--end::Title-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
            <li class="breadcrumb-item">Paramètres</li>
            <li class="breadcrumb-item text-dark">Mon studio</li>
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Info-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
          <router-link :to="{name: 'settings.account_settings'}" class="btn btn-active-accent fw-bolder ms-3">Mon compte</router-link>
          <router-link :to="{name: 'settings.studio_settings'}" class="btn btn-active-accent active fw-bolder ms-3">Mon studio</router-link>
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div class="content fs-6 d-flex flex-column-fluid" id="kt_content">
      <!--begin::Container-->
      <div class="container row gx-0 gy-5">
        <!-- My studio -->
        <div class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Mon studio</span>
            </h3>
          </div>
          <div class="card-body pt-3 mt-3">
            <div class="">
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Nom de l'agence</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.name" placeholder="Niemeyer Studio">
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">SIRET</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.infoSiret" placeholder="339 863 417 00418">
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Adresse</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <textarea type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.infoAddress" placeholder="15 rue du Petit Musc&#10;75004 Paris 4ème"></textarea>
                  </div>
                </div>
              </div>

              <h3 class="card-title align-items-start flex-column mt-5 mb-5">
                <span class="card-label fw-bolder text-dark fs-3">Paramètres du descriptif</span>
              </h3>

              <div class="row mb-3">
                <div class="col-lg-9">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="persoAlwaysForceSave" v-model="this.localCurrentUser.org.persoAlwaysForceSave">
                    <label class="form-check-label" for="persoAlwaysForceSave">Forcer la sauvegarde automatique du descriptif</label>
                  </div>
                </div>
              </div>


            </div>
            <div class="flex-column text-end">
              <button v-if="this.currentUser.role === 'OWNER'"
                      :disabled="!this.localCurrentUserEdited"
                      class="btn btn-primary btn-active-accent"
                      @click="this.updateOrgProperties()">
                Enregistrer
                <span v-if="this.buttonOrgPropertiesLoading" class="ms-1 spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Chargement...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <!-- My team -->
        <div class="card col-12">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Mon équipe</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item">
                  <button v-if="this.currentUser.role === 'OWNER'" class="nav-link btn btn-active-light btn-color-muted fw-bolder me-2" @click="this.modalAddUserShow()">+ Ajouter</button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div class="tab-content mt-4" id="myTabTables2">
              <!--begin::Table-->
              <div class="table-responsive">
                <table v-if="this.currentUser.org.team" class="table table-borderless align-middle">
                  <thead>
                  <tr>
                    <th class="px-2 py-0 w-50px"></th>
                    <th class="px-2 py-0 w-100"></th>
                    <th class="px-2 py-0 min-w-100px"></th>
                    <th class="px-2 py-0 min-w-50px"></th>
                    <th class="px-2 py-0 min-w-100px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="user in this.currentUser.org.team" :key="user.id" class="bg-hover-light cursor-default">
                    <td class="px-0 py-3 rounded-start p-5">
                      <div class="symbol symbol-55px ms-5 mt-1 me-5">
                        <span class="symbol-label bg-light-primary align-middle align-items-end">
                          <img v-if="user.photo" alt="Logo" :src="user.photo" class="mh-50px rounded rounded-circle" :style="'border: 5px solid '+(user?.color || '#FFFAAA')"/>
                          <div class="d-flex h-50px w-50px my-auto rounded rounded-circle justify-content-center" :style="'background-color: '+(user?.color || '#FFFAAA')+'; border: 5px solid '+(user?.color || '#FFFAAA')">
                            <span class="text-white align-self-center fs-1">{{(user?.firstName[0] || "")+(user?.lastName[0] || "")}}</span>
                          </div>
                        </span>
                      </div>
                    </td>
                    <td class="px-0">
                      <p class="text-gray-800 fw-bolder fs-6 my-1">{{ user.firstName+' '+user.lastName }}</p>
                      <span class="text-muted fw-bold d-block mt-1">{{ user.email}}</span>
                    </td>
                    <td class="px-0 text-center">
                      <div><span class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px">{{ user.role }}</span></div>
                    </td>
                    <td></td>
                    <td class="px-0 rounded-end">
                      <button v-if="this.currentUser.role === 'OWNER'" @click="this.modalEditUserShow(user.id)" class="me-2 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"><i class="bi bi-pencil align-baseline text-center"></i></button>
                      <button v-if="this.currentUser.role === 'OWNER'" @click="this.modalEditContactShow(user.linkedContact.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"><i class="bi bi-person align-baseline text-center"></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
        <div class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Personnalisation</span>
            </h3>
          </div>
          <div class="card-body pt-3 mt-n3">
            <div class="card-body pt-3 mt-3">
              <div class="">
                <h3 class="mb-0">Comptes rendus et exports</h3>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur principale</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColorMain" class="form-control-color w-20px h-20px ms-4 me-0 my-auto mx-auto align-middle rounded-circle rounded-circle border-0" :style="'background-color: '+this.localCurrentUser.org.persoColorMain">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColorMain" :placeholder="this.localCurrentUser.org.persoColorMain">
                    </div>
                  </div>
                </div>
                <h3 class="mt-4 mb-0">Descriptif</h3>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur 1</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColor1" class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.localCurrentUser.org.persoColor1">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColor1" :placeholder="this.localCurrentUser.org.persoColor1">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur 2</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColor2" class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.localCurrentUser.org.persoColor2">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColor2" :placeholder="this.localCurrentUser.org.persoColor2">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur 3</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColor3" class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.localCurrentUser.org.persoColor3">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColor3" :placeholder="this.localCurrentUser.org.persoColor3">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur 4</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColor4" class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.localCurrentUser.org.persoColor4">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColor4" :placeholder="this.localCurrentUser.org.persoColor4">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur 5</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColor5" class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.localCurrentUser.org.persoColor5">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColor5" :placeholder="this.localCurrentUser.org.persoColor5">
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Couleur 6</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input type="color" v-model="this.localCurrentUser.org.persoColor6" class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.localCurrentUser.org.persoColor6">
                      <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoColor6" :placeholder="this.localCurrentUser.org.persoColor6">
                    </div>
                  </div>
                </div>
                <h3 class="mb-0">Comptes rendus et exports</h3>
                <div class="row mb-3">
                  <label class="col-lg-9 col-form-label">Personnalisation des polices d'écriture</label>
                  <div class="row mb-3">
                    <label class="col-lg-3 col-form-label">Titres</label>
                    <div class="col-lg-9">
                      <div class="input-group input-group-lg input-group-solid">
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoFontTitle" placeholder="DM Serif Text">
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-3 col-form-label">Corps de texte</label>
                    <div class="col-lg-9">
                      <div class="input-group input-group-lg input-group-solid">
                        <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.localCurrentUser.org.persoFontBody" placeholder="DM Sans">
                      </div>
                    </div>
                  </div>
                </div>
                  <h3 class="mt-4 mb-0">Compte rendu de chantier - ordre des tâches</h3>

                  <div class="row mb-5">
                      <div class="form-check">
                          <input class="form-check-input" v-model="this.localCurrentUser.org.persoTaskHideArchived"
                                 type="checkbox" value="" id="checkHideArchived">
                          <label class="form-check-label" for="checkHideArchived">
                              Masquer les tâches archivées
                          </label>
                      </div>
                  </div>
                  <div class="row mb-3">
                      <label class="col-lg-3 col-form-label">Tri 1</label>
                      <select class="form-select" v-model="this.localCurrentUser.org.persoTaskSort1">
                          <option value="">Sélectionnez un tri</option>
                          <option value="status__order">Statut (croissant)</option>
                          <option value="-status__order">Statut (décroissant)</option>
                          <option value="start_date">Date de début (croissante)</option>
                          <option value="-start_date">Date de début (décroissante)</option>
                          <option value="end_date">Date de fin (croissante)</option>
                          <option value="-end_date">Date de fin (décroissante)</option>
                          <option value="assigned_company__name">Entreprise (croissante)</option>
                          <option value="-assigned_company__name">Entreprise (décroissante)</option>
                          <option value="numero">Numéro de l'ouvrage (croissant)</option>
                          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                          <option value="order">Ordre personnalisé (croissant)</option>
                          <option value="-order">Ordre personnalisé (décroissant)</option>
                      </select>
                  </div>
                  <div class="row mb-3">
                      <label class="col-lg-3 col-form-label">Tri 2</label>
                      <select class="form-select" v-model="this.localCurrentUser.org.persoTaskSort2">
                          <option value="">Sélectionnez un tri</option>
                          <option value="status__order">Statut (croissant)</option>
                          <option value="-status__order">Statut (décroissant)</option>
                          <option value="start_date">Date de début (croissante)</option>
                          <option value="-start_date">Date de début (décroissante)</option>
                          <option value="end_date">Date de fin (croissante)</option>
                          <option value="-end_date">Date de fin (décroissante)</option>
                          <option value="assigned_company__name">Entreprise (croissante)</option>
                          <option value="-assigned_company__name">Entreprise (décroissante)</option>
                          <option value="numero">Numéro de l'ouvrage (croissant)</option>
                          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                          <option value="order">Ordre personnalisé (croissant)</option>
                          <option value="-order">Ordre personnalisé (décroissant)</option>
                      </select>
                  </div>
                  <div class="row mb-3">
                      <label class="col-lg-3 col-form-label">Tri 3</label>
                      <select class="form-select" v-model="this.localCurrentUser.org.persoTaskSort3">
                          <option value="">Sélectionnez un tri</option>
                          <option value="status__order">Statut (croissant)</option>
                          <option value="-status__order">Statut (décroissant)</option>
                          <option value="start_date">Date de début (croissante)</option>
                          <option value="-start_date">Date de début (décroissante)</option>
                          <option value="end_date">Date de fin (croissante)</option>
                          <option value="-end_date">Date de fin (décroissante)</option>
                          <option value="assigned_company__name">Entreprise (croissante)</option>
                          <option value="-assigned_company__name">Entreprise (décroissante)</option>
                          <option value="numero">Numéro de l'ouvrage (croissant)</option>
                          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                          <option value="order">Ordre personnalisé (croissant)</option>
                          <option value="-order">Ordre personnalisé (décroissant)</option>
                      </select>
                  </div>
                  <div class="row mb-3">
                      <label class="col-lg-3 col-form-label">Tri 4</label>
                      <select class="form-select" v-model="this.localCurrentUser.org.persoTaskSort4">
                          <option value="">Sélectionnez un tri</option>
                          <option value="status__order">Statut (croissant)</option>
                          <option value="-status__order">Statut (décroissant)</option>
                          <option value="start_date">Date de début (croissante)</option>
                          <option value="-start_date">Date de début (décroissante)</option>
                          <option value="end_date">Date de fin (croissante)</option>
                          <option value="-end_date">Date de fin (décroissante)</option>
                          <option value="assigned_company__name">Entreprise (croissante)</option>
                          <option value="-assigned_company__name">Entreprise (décroissante)</option>
                          <option value="numero">Numéro de l'ouvrage (croissant)</option>
                          <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                          <option value="order">Ordre personnalisé (croissant)</option>
                          <option value="-order">Ordre personnalisé (décroissant)</option>
                      </select>
                  </div>
                <div v-if="this.currentUser.role === 'OWNER'" class="flex-column text-end">
                  <button class="btn btn-primary btn-active-accent"
                          :disabled="!this.localCurrentUserEdited"
                          @click="this.updateOrgProperties()">
                    Enregistrer
                    <span v-if="this.buttonOrgPropertiesLoading" class="ms-1 spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Chargement...</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Content-->
  </div>
  <div>
    <!-- MODAL EDIT CONTACT -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.editContact.show ? 'display: block;' : 'display: none;'">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditContactCancel()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-3">
                          <h3 class="fw-bolder text-dark display-6">Modifier le contact</h3>
                          <p class="">Voici le profil tel qu'il apparaîtra dans les différents exports et comptes-rendus s'il est ajouté comme MOE sur un projet.</p>
                        </div>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom</label>
                          <input v-model="this.modals.editContact.current.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                          <input v-model="this.modals.editContact.current.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 23 45 67 89">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                          <input v-model="this.modals.editContact.current.email" type="text" class="form-control form-control-lg form-control-solid" placeholder="jean.dupont@gmail.com">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Informations supplémentaires</label>
                          <textarea v-model="this.modals.editContact.current.infoComment" type="text" class="form-control form-control-lg form-control-solid"></textarea>
                        </div>
                        <!--end::Form Group-->

                          <p>Alternative : Affichage personnalisé du contact</p>

                          <!--begin::Form Group-->
                          <div class="fv-row mb-4">
                              <label class="fs-6 fw-bolder text-dark form-label">Informations personnalisées - ligne 1</label>
                              <input v-model="this.modals.editContact.current.infoLine1" type="text" class="form-control form-control-lg form-control-solid">
                          </div>
                          <!--end::Form Group-->

                          <!--begin::Form Group-->
                          <div class="fv-row mb-4">
                              <label class="fs-6 fw-bolder text-dark form-label">Informations personnalisées - lignes 2+</label>
                              <textarea v-model="this.modals.editContact.current.infoLine2" type="text" class="form-control form-control-lg form-control-solid"></textarea>
                          </div>
                          <!--end::Form Group-->

                        <h2 class="mt-5">Aperçu</h2>
                        <div class="border border-primary rounded p-3">
                            <div v-if="this.modals.editContact.current.infoLine1 || this.modals.editContact.current.infoLine2">
                                <p v-if="this.modals.editContact.current.infoLine1" class="fw-bolder mb-0">{{this.modals.editContact.current.infoLine1}}</p>
                                <p v-if="this.modals.editContact.current.infoLine2" class="mb-0" style="white-space: pre-wrap;">{{this.modals.editContact.current.infoLine2}}</p>
                            </div>
                          <div v-else>
                              <p v-if="this.modals.editContact.current.name" class="fw-bolder mb-0">{{this.modals.editContact.current.name}}</p>
                              <p v-if="this.modals.editContact.current.phone" class="mb-0">{{this.modals.editContact.current.phone}}</p>
                              <p v-if="this.modals.editContact.current.email" class="mb-0">{{this.modals.editContact.current.email}}</p>
                              <p v-if="this.modals.editContact.current.infoComment" style="white-space: pre-wrap;" class="mb-0">{{this.modals.editContact.current.infoComment}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light"
                                @click="modalEditContactCancel()">
                          Annuler
                        </button>
                        <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                                @click="modalEditContactValidate()">
                          Modifier
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL ADD USER -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.addUser.show ? 'display: block;' : 'display: none;'">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalAddUserClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-3">
                          <h3 class="fw-bolder text-dark display-6">Ajouter un utilisateur</h3>
                        </div>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Prénom</label>
                          <input v-model="this.modals.addUser.newUser.firstName" type="text" class="form-control form-control-lg form-control-solid" placeholder="Oscar">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom</label>
                          <input v-model="this.modals.addUser.newUser.lastName" type="text" class="form-control form-control-lg form-control-solid" placeholder="Niemeyer">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                          <input v-model="this.modals.addUser.newUser.email" type="text" class="form-control form-control-lg form-control-solid" placeholder="oscar@niemeyer.archi">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                          <input v-model="this.modals.addUser.newUser.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 12 45 67 89">
                        </div>
                        <!--end::Form Group-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light"
                                @click="modalAddUserClose()">
                          Annuler
                        </button>
                        <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                                @click="modalAddUserValidate()">
                          Ajouter
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL EDIT USER -->
    <div class="modal"
         tabindex="-1"
         :style="this.modals.editUser.show ? 'display: block;' : 'display: none;'">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditUserClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-3">
                          <h3 class="fw-bolder text-dark display-6">Modifier les infos du compte</h3>
                        </div>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Prénom</label>
                          <input v-model="this.modals.editUser.user.firstName" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom</label>
                          <input v-model="this.modals.editUser.user.lastName" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                          <input v-model="this.modals.editUser.user.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 23 45 67 89">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Couleur</label>
                          <div class="input-group input-group-lg input-group-solid">
                            <div class="w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle" :style="'background-color: '+this.modals.editUser.user.color"></div>
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="this.modals.editUser.user.color" :placeholder="this.modals.editUser.user.color">
                          </div>
                        </div>
                        <!--end::Form Group-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light"
                                @click="modalEditUserClose()">
                          Annuler
                        </button>
                        <button class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                                @click="modalEditUserValidate()">
                          Modifier
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
CURRENT_USER_QUERY,
MUTATION_ORG_UPDATE,
MUTATION_USER_CREATE,
MUTATION_USER_UPDATE
} from "@/graphql/graphql";
import kalmStore from "@/store";
import { ALL_CONTACTS_QUERY, MUTATION_CONTACT_UPDATE } from "@/views/contacts/data/contacts_graphql";

function sortContacts(a,b) {
  return ('' + a.name).localeCompare(('' + b.name));
}

export default {
  name: "SettingsAccountPage",
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      result({data}) {
        this.localCurrentUser = JSON.parse(JSON.stringify(data.currentUser));
        this.localCurrentUserEdited = false;
      },
    },
    contacts: ALL_CONTACTS_QUERY,
  },
  data() {
    return {
      buttonOrgPropertiesLoading: false,
      currentUser: {
        email: '',
        firstName: '',
        lastName: '',
        org: {}
      },
      localCurrentUserEdited: false,
      localCurrentUser: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        org: {
          name: '',
        }
      },
      contacts: [],
      modals: {
        editContact: {
          show: false,
          id: "",
          current: {
            id: "",
            type: "",
            company: "",
            name: "",
            role: "",
            email: "",
            phone: "",
            infoSiret: "",
            infoInsurance: "",
            infoAddress: "",
            infoTva: "",
            infoComment: "",
              infoLine1: "",
              infoLine2: "",
          },
        },
        addUser: {
          show: false,
          newUser: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          }
        },
        editUser: {
          show: false,
          user: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          }
        }
      }
    }
  },
  methods: {
    modalAddUserShow(){
      this.modals.addUser.show = true;
    },
    modalAddUserClose(){
      this.modals.addUser.show = false;
    },
    modalAddUserValidate(){
      this.$apollo.mutate({
        mutation: MUTATION_USER_CREATE,
        variables: {
          firstName: this.modals.addUser.newUser.firstName,
          lastName: this.modals.addUser.newUser.lastName,
          phone: this.modals.addUser.newUser.phone,
          email: this.modals.addUser.newUser.email,
        },
        update: (store, { data: { userCreate }  }) => {
          // Read the data from our cache for this query.
          let data = store.readQuery({query: CURRENT_USER_QUERY})

          // Add new user from the mutation to cache

          data = {
            currentUser: {
              ...data.currentUser,
              org: {
                ...data.currentUser.org,
                team: [
                    ...data.currentUser.org.team,
                  userCreate.user,
                ]
              }
            }
          }

          // Write our data back to the cache.
          store.writeQuery({query: CURRENT_USER_QUERY, data})
        },
      })

      this.modalAddUserClose();
    },
    modalEditUserShow(id){
      this.modals.editUser.user = {...this.currentUser.org.team.find(u => u.id === id)}
      this.modals.editUser.show = true;
    },
    modalEditUserClose(){
      this.modals.editUser.show = false;
    },
    modalEditUserValidate(){
      this.$apollo.mutate({
        mutation: MUTATION_USER_UPDATE,
        variables: {
          id: this.modals.editUser.user.id,
          firstName: this.modals.editUser.user.firstName,
          lastName: this.modals.editUser.user.lastName,
          phone: this.modals.editUser.user.phone,
          color: this.modals.editUser.user.color,
        },
      })

      this.modalEditUserClose();
    },
    getContactById(id) {
      return this.contacts.find(c => c.id === id);
    },
    modalEditContactShow(id) {
      if(id) {
        this.modals.editContact.show = true;
        this.modals.editContact.id = id;
        this.modals.editContact.current.name = this.getContactById(id).name;
        this.modals.editContact.current.email = this.getContactById(id).email;
        this.modals.editContact.current.phone = this.getContactById(id).phone;
        this.modals.editContact.current.company = this.getContactById(id).company;
        this.modals.editContact.current.type = this.getContactById(id).type;
        this.modals.editContact.current.infoSiret = this.getContactById(id).infoSiret;
        this.modals.editContact.current.infoInsurance = this.getContactById(id).infoInsurance;
        this.modals.editContact.current.infoComment = this.getContactById(id).infoComment;
        this.modals.editContact.current.infoAddress = this.getContactById(id).infoAddress;
          this.modals.editContact.current.infoTva = this.getContactById(id).infoTva;
          this.modals.editContact.current.infoLine1 = this.getContactById(id).infoLine1;
          this.modals.editContact.current.infoLine2 = this.getContactById(id).infoLine2;
      }
    },
    modalEditContactCancel() {
      this.modals.editContact.show = false;
    },
    modalEditContactValidate() {
      this.modals.editContact.show = false;

      const contactId = this.modals.editContact.id;
      const newContact = {
        name: this.modals.editContact.current.name,
        email: this.modals.editContact.current.email,
        phone: this.modals.editContact.current.phone,
        company: this.modals.editContact.current.company,
        type: this.modals.editContact.current.type,
        infoSiret: this.modals.editContact.current.infoSiret || "",
        infoInsurance: this.modals.editContact.current.infoInsurance || "",
        infoComment: this.modals.editContact.current.infoComment || "",
        infoAddress: this.modals.editContact.current.infoAddress || "",
        infoTva: this.modals.editContact.current.infoTva || "",
        infoLine1: this.modals.editContact.current.infoLine1 || "",
        infoLine2: this.modals.editContact.current.infoLine2 || "",
      }

      if (contactId) {
        this.$apollo.mutate(
            {
              mutation: MUTATION_CONTACT_UPDATE,
              variables: {
                contactId,
                newContact
              },
              update: (store, { data: { contactUpdate }  }) => {
                // Read the data from our cache for this query.
                let data = store.readQuery({query: ALL_CONTACTS_QUERY})

                // Add new contact from the mutation to cache
                data = {
                  ...data,
                  contacts: [
                    ...data.contacts.filter(c => c.id !== contactId),
                    contactUpdate.contact
                  ].sort(sortContacts),
                }

                // Write our data back to the cache.
                store.writeQuery({query: ALL_CONTACTS_QUERY, data})
              },
            }
        )
      }
    },
    updateOrgProperties() {
      this.buttonOrgPropertiesLoading = true;
      this.$apollo.mutate(
          {
            mutation: MUTATION_ORG_UPDATE,
            variables: {
              id: this.localCurrentUser.org.id,
              name: this.localCurrentUser.org.name,
              infoSiret: this.localCurrentUser.org.infoSiret,
              infoAddress: this.localCurrentUser.org.infoAddress,
              persoAlwaysForceSave: this.localCurrentUser.org.persoAlwaysForceSave,
              persoColorMain: this.localCurrentUser.org.persoColorMain,
              persoColor1: this.localCurrentUser.org.persoColor1,
              persoColor2: this.localCurrentUser.org.persoColor2,
              persoColor3: this.localCurrentUser.org.persoColor3,
              persoColor4: this.localCurrentUser.org.persoColor4,
                persoTaskSort1: this.localCurrentUser.org.persoTaskSort1,
                persoTaskSort2: this.localCurrentUser.org.persoTaskSort2,
                persoTaskSort3: this.localCurrentUser.org.persoTaskSort3,
                persoTaskSort4: this.localCurrentUser.org.persoTaskSort4,
                persoTaskHideArchived: this.localCurrentUser.org.persoTaskHideArchived,
              persoColor5: this.localCurrentUser.org.persoColor5,
              persoColor6: this.localCurrentUser.org.persoColor6,
              persoFontBody: this.localCurrentUser.org.persoFontBody,
              persoFontTitle: this.localCurrentUser.org.persoFontTitle,
            },
            update: () => {
              this.buttonOrgPropertiesLoading = false;
            },
          }
      )
    },
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled
          || this.modals.editContact.show || this.modals.addUser.show || this.modals.editUser.show
    },
    internalContacts(){
      return this.contacts.filter(c => c?.type === "INTERNAL").sort(sortContacts);
    }
  },
  watch: {
    localCurrentUser: {
      handler() {
        if(JSON.stringify(this.localCurrentUser) !== JSON.stringify(this.currentUser)) {
          this.localCurrentUserEdited = true;
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>