<template>
  <!--begin::Row-->
  <div class="row g-0 g-xl-5 g-xxl-8"
       :class="isModalActive ? 'blur-content' : ''">
    <div class="col-12">
      <!-- MES CONTACTS - TABLE -->
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Mes contacts</span>
            <span class="text-muted mt-2 fw-bold fs-6">{{ contacts_count }} contact<span v-if="contacts_count > 1">s</span></span>
          </h3>
          <div class="card-toolbar justify-content-between">
            <div>
              <ul class="nav nav-pills nav-pills-sm nav-light justify-content-end">
                <!--              <li class="nav-item align-middle">
                                <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="openContactModal"><i class="bi bi-cloud-download"></i> Importer</button>
                              </li>-->
                <li class="nav-item align-middle">
                  <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="this.modalCreateContactShow()">+ Ajouter</button>
                </li>
                <li>
                  <input class="form-control form-control-solid ms-3" v-model="this.contact_search_filter" placeholder="Rechercher">
                </li>
              </ul>
            </div>

          </div>
            <div class="card-toolbar align-self-center justify-content-center w-100 w-xl-auto">
                <ul class="nav nav-pills nav-light justify-content-center">
                    <li class="nav-item m-0">
                        <button class="btn btn-active-light btn-color-muted btn-outline btn-outline-light border-end-0 rounded-end-0" :class="{'active': this.filterMode === 'all'}" @click="this.setFilterMode('all')">Tous</button>
                    </li>
                    <li class="nav-item m-0">
                        <button class="btn btn-active-light btn-color-muted btn-outline btn-outline-light border-end-0 rounded-0" :class="{'active': this.filterMode === 'customers'}" @click="this.setFilterMode('customers')">Clients</button>
                    </li>
                    <li class="nav-item m-0">
                        <button class="btn btn-active-light btn-color-muted btn-outline btn-outline-light rounded-start-0" :class="{'active': this.filterMode === 'companies'}" @click="this.setFilterMode('companies')">Entreprises</button>
                    </li>
                </ul>
    </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-3 mt-n3">
          <div class="tab-content mt-4" id="myTabTables2">
            <div class="table-responsive">
              <table class="table table-borderless align-middle">
                <thead>
                <tr>
                  <th class="p-0 w-50px"></th>
                  <th class="p-0"></th>
                  <th class="p-0"></th>
                  <th class="p-0"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="contact in getContactsFiltered" :key="contact.id" @click="this.navigateToContact(contact.id)" class="bg-hover-light">
                  <td class="px-0 py-3 ps-sm-5 rounded rounded-start">
                    <AvatarLogo :height="'mh-40px'" />
                  </td>
                  <td class="px-0">
                    <span class="text-black fw-bold d-block mt-1">{{ contact.company }}</span>
                    <span class="text-muted fw-bold d-block mt-1">{{ contact.name }}</span>
                      <span class="d-block d-sm-none">
                        <span class="text-gray-800 fw-bolder fs-6">{{ contact.infoCorps }}</span>
                        <span class="text-gray-800 d-block fs-6">{{ contact.email }}</span>
                        <span class="text-gray-800 d-block fs-6">{{ contact.phone }}</span>
                      </span>
                  </td>
                  <td class="text-center d-none d-sm-flex flex-column justify-content-center align-items-center h-100px">
                    <span class="text-gray-800 fw-bolder fs-6">{{ contact.infoCorps }}</span>
                    <span class="text-gray-800 d-block fs-6">{{ contact.email }}</span>
                    <span class="text-gray-800 d-block fs-6">{{ contact.phone }}</span>
                  </td>
                  <td class="text-end pe-0 rounded rounded-end">
                    <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-danger btn-sm btn-active-white me-2 mb-1"
                      @click="modalConfirmDeleteShow(contact.id)">
                      <span class="bi bi-trash-fill"></span>
                    </button>
                    <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2 mb-1"
                      @click="modalEditContactShow(contact.id)">
                      <span class="bi bi-pencil-fill"></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Table Widget 2-->
    </div>
  </div>
  <!--end::Row-->
  <!-- MODAL - CREATE CONTACT -->
  <div class="modal"
       tabindex="-1"
       :style="this.modals.createContact.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalCreateContactCancel()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">Nouveau Contact</h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Nom(s)</label>
                        <input v-model="modals.createContact.current.name" type="text" class="form-control form-control-lg form-control-solid" placeholder="Jean & Laura Dupont">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                        <input v-model="modals.createContact.current.phone" type="text" class="form-control form-control-lg form-control-solid" placeholder="+33 6 23 45 67 89">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div v-if="modals.createContact.current.phone" class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Téléphone <span class="text-muted">(2)</span></label>
                        <input v-model="modals.createContact.current.phone2" type="text" class="form-control form-control-lg form-control-solid" placeholder="01 23 45 67 89">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                        <input v-model="modals.createContact.current.email" type="text" class="form-control form-control-lg form-control-solid" placeholder="jean.dupont@gmail.com">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                        <label class="fs-6 fw-bolder text-dark form-label">Entreprise</label>
                        <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                        <input v-model="modals.createContact.current.company" type="text" class="form-control form-control-lg form-control-solid" placeholder="Sanzot SARL">
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div class="fv-row"
                           v-for="typeOption in contact_modal_type_options"
                           :key="typeOption.id">
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label" :class="typeOption.bg">
                                <i class="fs-1 bi" :class="typeOption.icon"></i>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">{{ typeOption.title }}</span>
                              <span class="fs-7 text-muted">{{ typeOption.subtitle }}</span>
                            </span>
                          </span>
                          <span class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input" type="radio" v-model="modals.createContact.current.type"
                                   :value="typeOption.id">
                          </span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->

                      <div v-if="modals.createContact.current.type === 'CUSTOMER_PRO' || modals.createContact.current.type === 'COMPANY'">
                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">SIRET</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modals.createContact.current.infoSiret" type="text" class="form-control form-control-lg form-control-solid" placeholder="339 863 417 00418">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Corps d'état</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modals.createContact.current.infoCorps" type="text" class="form-control form-control-lg form-control-solid" placeholder="Électricité">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Site web</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modals.createContact.current.infoWebsite" type="url" class="form-control form-control-lg form-control-solid" placeholder="https://www.kalm.ai">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">N° TVA</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <input v-model="modals.createContact.current.infoTva" type="text" class="form-control form-control-lg form-control-solid" placeholder="FR03339863417">
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Adresse</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <textarea v-model="modals.createContact.current.infoAddress" class="form-control form-control-lg form-control-solid" placeholder="15 rue du Petit Musc&#10;75004 Paris 4ème"></textarea>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Assurance</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Lien vers le fichier ou Numéro)</label>
                          <div class="input-group align-middle">
                            <input v-model="modals.createContact.current.infoInsurance" type="text" class="form-control form-control-lg form-control-solid" placeholder="https://drive.google.com/uazdAZOjazdlakzdLKAZlkaz">
                            <a v-if="modals.createContact.current.infoInsurance && modals.createContact.current.infoInsurance.startsWith('http')" :href="modals.createContact.current.infoInsurance || '#'" class="input-group-text border-0" :class="{'cursor-default': !modals.createContact.current.infoInsurance}" :target="modals.createContact.current.infoInsurance ? '_blank' : ''"><span class="bi bi-link"></span></a>
                          </div>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                          <label class="fs-6 fw-bolder text-dark form-label">Commentaire</label>
                          <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                          <textarea v-model="modals.createContact.current.infoComment" class="form-control form-control-lg form-control-solid" placeholder="Électricité générale"></textarea>
                        </div>
                        <!--end::Form Group-->

                      </div>
                        <div class="mt-5">
                            <p class="mt-7 fw-bold">Remplacer l'affichage par défaut du contact par des infos personnalisées :</p>

                            <!--begin::Form Group-->
                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label class="fs-6 fw-bolder text-dark form-label">Affichage personnalisé - Ligne 1</label>
                                <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                                <input v-model="modals.createContact.current.infoLine1" class="form-control form-control-lg form-control-solid" :placeholder="modals.createContact.current.name">
                            </div>
                            <!--end::Form Group-->

                            <!--begin::Form Group-->
                            <div class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                <label class="fs-6 fw-bolder text-dark form-label">Affichage personnalisé - Ligne 2+</label>
                                <label class="ms-1 fs-8 text-muted form-label">(Optionnel)</label>
                                <textarea v-model="modals.createContact.current.infoLine2" class="form-control form-control-lg form-control-solid" :placeholder="modals.createContact.current.email+' - '+modals.createContact.current.phone"></textarea>
                            </div>
                            <!--end::Form Group-->
                        </div>
                    </div>

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-primary btn-active-light-primary" @click="this.modalCreateContactValidate()">
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- MODAL CONFIRM DELETE CONTACT-->
  <div class="modal"
       tabindex="-1"
       :style="modals.confirmDelete.show ? 'display: block;' : 'display: none;'">
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="modalConfirmDeleteCancel">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
            <!--begin::Stepper-->
            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
              <!--begin::Content0-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <template v-if="this.getProposalsForContact(this.modals.confirmDelete.id).length === 0">
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Supprimer le contact</h3>
                        </div>
                        <div class="pb-5">
                          <p>
                            Vous êtes sur le point de supprimer le contact <strong>{{ this.getContactById(this.modals.confirmDelete.id)?.name }}</strong>. Cette action est irréversible.
                          </p>
                        </div>
                      </template>
                      <template v-else>
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Êtes-vous sûr ?</h3>
                        </div>

                        <p>
                          Si vous supprimez ce contact, <strong>les devis associés seront également supprimés</strong>.
                        </p>

                        <!--begin::Alert-->
                        <div class="alert bg-danger d-flex flex-column flex-sm-row p-5 mb-10">
                          <!--begin::Icon-->
                          <span class="text-white me-4 mb-5 mb-sm-0"><i class="bi fs-1 text-white bi-exclamation-triangle"></i></span>
                          <!--end::Icon-->

                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                            <!--begin::Title-->
                            <h5 class="mb-1">{{ this.getProposalsForContact(this.modals.confirmDelete.id).length }} devis
                              <span v-if="this.getProposalsForContact(this.modals.confirmDelete.id).length === 1"> est associé </span><span v-else> sont associés </span>
                              à ce contact :</h5>
                            <ul>
                              <li v-for="proposal in this.getProposalsForContact(this.modals.confirmDelete.id)" :key="proposal.id">
                                Projet {{ proposal.project.name }} — {{ proposal?.name }}
                              </li>
                            </ul>
                          </div>
                          <!--end::Wrapper-->
                        </div>
                      </template>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button type="button" @click="modalConfirmDeleteCancel" class="btn btn-lg btn-light">
                        Annuler
                      </button>
                      <button type="button" @click="modalConfirmDeleteValidate" class="btn btn-lg btn-danger ms-2">
                        Supprimer
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content0-->
            </div>
            <!--end::Stepper-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL EDIT CONTACT -->
  <ContactUpdateModal v-model="this.modals.editContact.current" v-if="this.modals.editContact.show" @close="modalEditContactCancel" :callback="callbackOnUpdateContact"/>
</template>

<script>

import kalmStore from "@/store";
import ContactUpdateModal from "../components/ContactUpdateModal.vue";
import AvatarLogo from "@/views/_core/components/AvatarLogo.vue";
import {
ALL_CONTACTS_QUERY,
MUTATION_CONTACT_CREATE,
MUTATION_CONTACT_DELETE,
} from '../../data/contacts_graphql';

function sortContacts(a,b) {
  return ('' + a?.company + a?.name).localeCompare(('' + b?.company + b?.name));
}

export default {
  name: "ContactsPage",
  apollo: {
    contacts: ALL_CONTACTS_QUERY,
  },
  components: { 
    ContactUpdateModal,
    AvatarLogo,
  },
  methods: {
    getProposalsForContact(contactId) {
      return this.getContactById(contactId)?.proposals || [];
    },
    setFilterMode(mode) {
      switch (mode) {
        case 'companies':
          this.filterMode = 'companies'
          break;
        case 'customers':
          this.filterMode = 'customers'
          break;
        case 'all':
        default:
          this.filterMode = 'all'
      }
    },
    getContactById(id) {
      return this.contacts.find(c => c.id === id);
    },
    modalEditContactShow(id) {
      event.stopPropagation();
      if (!id) return;
      
      this.modals.editContact.current.id = id;
      this.modals.editContact.current.name = this.getContactById(id).name;
      this.modals.editContact.current.email = this.getContactById(id).email;
      this.modals.editContact.current.phone = this.getContactById(id).phone;
      this.modals.editContact.current.phone2 = this.getContactById(id).phone2;
      this.modals.editContact.current.company = this.getContactById(id).company;
      this.modals.editContact.current.type = this.getContactById(id).type;
      this.modals.editContact.current.infoSiret = this.getContactById(id).infoSiret;
      this.modals.editContact.current.infoInsurance = this.getContactById(id).infoInsurance;
      this.modals.editContact.current.infoComment = this.getContactById(id).infoComment;
      this.modals.editContact.current.infoAddress = this.getContactById(id).infoAddress;
      this.modals.editContact.current.infoTva = this.getContactById(id).infoTva;
      this.modals.editContact.current.infoCorps = this.getContactById(id).infoCorps;
      this.modals.editContact.current.infoLine1 = this.getContactById(id).infoLine1;
      this.modals.editContact.current.infoLine2 = this.getContactById(id).infoLine2;
      this.modals.editContact.current.infoWebsite = this.getContactById(id).infoWebsite;
      this.modals.editContact.show = true;
    },
    modalEditContactCancel() {
      this.modals.editContact.show = false;
    },
    callbackOnUpdateContact() {
      console.log(this.$apollo.queries);
      this.$apollo.queries.contacts.refetch();
    },
    modalCreateContactShow() {
      this.modalCreateContactCancel()
      this.modals.createContact.show = true;
    },
    modalCreateContactCancel() {
      this.modals.createContact.show = false;
      this.modals.createContact.current.name = "";
      this.modals.createContact.current.email = "";
      this.modals.createContact.current.phone = "";
      this.modals.createContact.current.phone2 = "";
      this.modals.createContact.current.company = "";
      this.modals.createContact.current.type = "";
      this.modals.createContact.current.infoSiret = "";
      this.modals.createContact.current.infoInsurance = "";
      this.modals.createContact.current.infoComment = "";
      this.modals.createContact.current.infoAddress = "";
      this.modals.createContact.current.infoTva = "";
      this.modals.createContact.current.infoCorps = "";
      this.modals.createContact.current.infoWebsite = "";
      this.modals.createContact.current.infoLine1 = "";
      this.modals.createContact.current.infoLine2 = "";
    },
    modalCreateContactValidate() {
      this.modals.createContact.show = false;

      const newContact = {
        name: this.modals.createContact.current.name,
        email: this.modals.createContact.current.email,
        phone: this.modals.createContact.current.phone,
        phone2: this.modals.createContact.current.phone2,
        company: this.modals.createContact.current.company,
        type: this.modals.createContact.current.type,
        infoSiret: this.modals.createContact.current.infoSiret || "",
        infoInsurance: this.modals.createContact.current.infoInsurance || "",
        infoComment: this.modals.createContact.current.infoComment || "",
        infoAddress: this.modals.createContact.current.infoAddress || "",
        infoTva: this.modals.createContact.current.infoTva || "",
        infoCorps: this.modals.createContact.current.infoCorps || "",
        infoWebsite: this.modals.createContact.current.infoWebsite || "",
        infoLine1: this.modals.createContact.current.infoLine1 || "",
        infoLine2: this.modals.createContact.current.infoLine2 || "",
      }

      this.$apollo.mutate(
        {
          mutation: MUTATION_CONTACT_CREATE,
          variables: {
            newContact
          },
          update: (store, { data: { contactCreate }  }) => {
            // Read the data from our cache for this query.
            let data = store.readQuery({query: ALL_CONTACTS_QUERY})

            // Add new contact from the mutation to cache
            data = {
              ...data,
              contacts: [
                ...data.contacts, contactCreate.contact
              ].sort(sortContacts),
            }

            // Write our data back to the cache.
            store.writeQuery({query: ALL_CONTACTS_QUERY, data})
          },
        }
      )
    },
    modalConfirmDeleteShow(id) {
      event.stopPropagation();
      this.modals.confirmDelete.id = id
      this.modals.confirmDelete.show = true;
    },
    modalConfirmDeleteValidate() {
      const contactId = this.modals.confirmDelete.id;

      if(contactId){
        this.$apollo.mutate(
            {
              mutation: MUTATION_CONTACT_DELETE,
              variables: {
                contactId
              },
              update: (store) => {
                // Read the data from our cache for this query.
                let data = store.readQuery({ query: ALL_CONTACTS_QUERY })

                // Add new contact from the mutation to cache
                data = {
                  ...data,
                  contacts: [
                    ...data.contacts.filter(c => c.id !== contactId)
                  ].sort(sortContacts),
                }
                // Write our data back to the cache.
                store.writeQuery({ query: ALL_CONTACTS_QUERY, data })
              },
            }
        )
      }

      this.modals.confirmDelete.id = "";
      this.modals.confirmDelete.show = false;
    },
    modalConfirmDeleteCancel() {
      this.modals.confirmDelete.show = false;
    },
    navigateToContact(id) {
      this.$router.push({ 
        name: 'contact_detail', 
        params: { id } 
      });
    }
  },
  computed: {
    isModalActive: function() {
      return kalmStore.state.display.profileToggled || this.modals.createContact.show || this.modals.editContact.show
          || this.modals.confirmDelete.show || this.modals.confirmDelete.show;
    },
    contacts_count: function() {
      return this.getContactsFiltered.length;
    },
    getContactsFiltered: function() {
      let contacts = [...this.contacts].filter(c => c.type !== "INTERNAL");
      if(this.filterMode === 'customers') {
        contacts = contacts.filter(c => c.type === "CUSTOMER_PRO" || c.type === "CUSTOMER_INDIVIDUAL" );
      } else if(this.filterMode === 'companies') {
        contacts = contacts.filter(c => c.type === "COMPANY");
      }

      if (this.contact_search_filter === "") {
        return contacts.sort(sortContacts);
      }
      else {
        return contacts.filter(c =>
          (c?.name || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.phone || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.phone2 || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.email || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.company || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
          || (c?.infoCorps || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || (c?.infoComment || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || (c?.infoLine1 || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
            || (c?.infoLine2 || "").toLowerCase().includes(this.contact_search_filter.toLowerCase())
        ).sort(sortContacts)
      }
    },
  },
  data() {
    return {
      filterMode: 'all',
      modals: {
        editContact: {
          show: false,
          id: "",
          current: {
            id: "",
            type: "",
            company: "",
            name: "",
            role: "",
            email: "",
            phone: "",
            phone2: "",
            infoWebsite: "",
            infoCorps: "",
            infoSiret: "",
            infoInsurance: "",
            infoAddress: "",
            infoTva: "",
            infoComment: "",
            infoLine1: "",
            infoLine2: "",
          },
        },
        createContact: {
          show: false,
          id: "",
          current: {
            id: "",
            type: "",
            company: "",
            name: "",
            role: "",
            email: "",
            phone: "",
            phone2: "",
            infoWebsite: "",
            infoCorps: "",
            infoSiret: "",
            infoInsurance: "",
            infoAddress: "",
            infoTva: "",
            infoComment: "",
            infoLine1: "",
            infoLine2: "",
          },
        },
        confirmDelete: {
          show: false,
          id: "",
        },
      },
      contact_modal_type_options: [
        {
          id: "CUSTOMER_INDIVIDUAL",
          title: "Client — Particulier",
          subtitle: "Un particulier, client ou prospect",
          icon: "bi-person",
          bg: "bg-light-success",
          checked: true
        },
        {
          id: "CUSTOMER_PRO",
          title: "Client — Pro",
          subtitle: "Un professionnel, client ou prospect",
          icon: "bi-person-check",
          bg: "bg-light-info"
        },
        {
          id: "COMPANY",
          title: "Entreprise",
          subtitle: "Professionnel - Prestataire ou fournisseur",
          icon: "bi-building",
          bg: "bg-light-warning"
        },
      ],
      contact_search_filter: "",
      contacts: [
        {
          id: "ct_01",
          contact_type: "client",
          company: "Kalm",
          name: "Bob - Kalm",
          role: "MOA",
          email: "bob@kalm.ai",
          phone: "+33 6 00 00 00 00",
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>