<template>
    <KalmModal modalTitle="Todo" @close="close">
        <template v-slot:body>
            <TodoItemForm :isEmbedded="true" :todoItem="todoItem" :projectId="projectId"
                :todo-id="todoId" :assignedToCurrentUser="assignedToCurrentUser" @close="close" />
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import TodoItemForm from './TodoItemForm.vue';
export default {
    name: "TodoItemFormModal",
    props: {
        todoItem: Object,
        todoId: {
            type: String,
            required: true,
        },
        projectId: {
            type: String,
            required: false,
        },
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        KalmModal,
        TodoItemForm,
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
}
</script>