<template>
    <td class="lot-drag-handle me-2" style="cursor: grab;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
            style="width: 15px; height: 100%; display: block;" class="align-self-center">
            <path fill-opacity="0.2"
                d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
    </td>
    <td>
        <div class="d-flex ms-2">
            <button
            class="btn btn-sm btn-primary px-2 py-0 h-40px w-40px"
            v-tooltip:bottom="'Mettre à jour les ouvrages'"
            @click="emitModal('update', proposal)"
            >
                <span v-if="loading"
                    class="spinner-border spinner-border-sm"
                ></span>
                <span v-else class="bi bi-arrow-repeat"></span>
            </button>
            <div>
                <h3 class="mb-1 ms-2">
                    {{ proposal.name || getContactNameById(proposal.contact.id) }}
                </h3>
                <span class="ms-2">{{ this.getTextByproposalModeLots() }}</span>
            </div>
        </div>
    </td>
    <td>
        <button class="btn ms-4 btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2" @click="emitModal('showFiles', proposal.id)">
            <span class="ms-2">{{ countFiles }}</span>
            <i class="bi bi-file-text align-baseline text-center m-2"></i>
        </button>
    </td>
    <td >
        <div v-if="proposal?.contact && proposal.steps && proposal.steps.length">
            <div v-for="step in JSON.parse(proposal.steps)" :key="step.id" class="text-gray-800">
            <span class="me-2 bi" :class="step.status ? 'bi-check-circle-fill' : 'bi-circle'"></span>{{ step.name }}
            </div>
        </div>
    </td>
    <td>
        <div v-if="proposal.notes">
            <label class="text-muted">Notes :</label>
            <div class="w-100 border-0 text-dark" style="min-height: 66px;">{{ proposal.notes }}</div>
        </div>
    </td>
    <td class="px-0 rounded-end">
        <button type="button"
                v-tooltip:bottom="'Modifier'"
                @click="emitModal('edit', proposal.id)"
                class="btn flex-grow-1 rounded-0 btn-active-light-primary"
                style="border-bottom-left-radius: 15px;"><i class="bi bi-pencil"></i></button>
        <button type="button"
                v-tooltip:bottom="'Dupliquer'"
                @click="emitModal('duplicate', proposal.id)"
                class="btn flex-grow-1 rounded-0 btn-active-light-primary"><i class="bi bi-clipboard-plus"></i></button>
        <button type="button"
                v-tooltip:bottom="'Ajouter un fichier'"
                @click="emitModal('uploadFile', proposal.id)"
                class="btn flex-grow-1 rounded-0 btn-active-light-primary"><i class="bi bi-file-text"></i></button>
        <button type="button"
                v-tooltip:bottom="'Télécharger'"
                @click="emitModal('download', proposal.id)"
                class="btn flex-grow-1 rounded-0 btn-active-light-primary"><i class="bi bi-download"></i></button>
        <button type="button"
                v-tooltip:bottom="'Supprimer'"
                @click="emitModal('delete', proposal.id)"
                class="btn flex-grow-1 rounded-0 btn-active-light-primary" style="border-bottom-right-radius: 15px;"><i class="bi bi-trash"></i></button>
    </td>
</template>

<script>
export default {
    name: "ProposalCard",
    props: {
        proposal: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fileExplorer: {
                show: false,
            },
        };
    },
    emits: ["clickOption"],
    methods: {
        getContactNameById(contactId) {
            let ret = "";
            if(contactId) {
                const contact = this.contacts.find( c => c.id === contactId );
                ret = contact?.company || contact?.name || "";
            }
            return ret;
        },
        getTextByproposalModeLots() {
            if (this.proposal.modeLots === "ALL") {
                return this.proposal.contact ? "Consulté sur tous les lots" : "Sur tous les lots";
            } else if (this.proposal.lots.length === 0) {
                return this.proposal.contact ? "Consulté sur aucun lot" : "Sur aucun lot";
            } else {
                return this.proposal.contact ? `Consulté sur ${this.proposal.lots.length === 1 ? "le lot" : "les lots"} ${this.proposal.lots.map(l => l.number).join(", ") }` : `Sur ${this.proposal.lots.length === 1 ? "le lot" : "les lots"} ${this.proposal.lots.map(l => l.number).join(", ") }`;
            }
        },
        emitModal(action, data) {
            this.$emit("clickOption", { action, data });
        },
    },
    computed: {
        countFiles() {
            return this.proposal?.countFilesInSpecialFolder || 0;
        },
    }
};
</script>

<style lang="scss" scoped></style>
