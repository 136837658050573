export const getFileIconClass = (file) => {
    const extension = file.url.split(".").pop();
    const iconMapping = {
        jpg: "bi bi-file-image",
        jpeg: "bi bi-file-image",
        png: "bi bi-file-image",
        svg: "bi bi-file-image",
        pdf: "bi bi-file-pdf",
        doc: "bi bi-file-word",
        docx: "bi bi-file-word",
        xls: "bi bi-file-excel",
        xlsx: "bi bi-file-excel",
        default: "bi bi-file-earmark", // Default icon for other file types
    };
    return iconMapping[extension.toLowerCase()] || iconMapping.default;
}