<template>
    <div>
        <KalmModal v-if="!showCreatedReportModal" modalTitle="Créer un compte rendu" @close="close">
            <template v-slot:body>
                <form @submit.prevent="createReport">
                    <div class="input-group input-group-solid mb-3 mt-6">
                        <label class="input-group-text">Nom du CR</label>
                        <input type="text" class="form-control form-control-solid" v-model="title">
                    </div>
                    <div class="input-group input-group-solid mb-3 mt-6">
                        <label class="input-group-text">N°</label>
                        <input type="number" class="form-control form-control-solid" v-model="reportNumber">
                    </div>
                    <div class="input-group input-group-solid mb-3 mt-6">
                        <label class="input-group-text">Date :</label>
                        <input type="date" class="form-control form-control-solid" v-model="reportDate">
                    </div>
                    <div class="input-group input-group-solid mb-3 mt-6">
                        <label class="input-group-text">Prochaine réunion :</label>
                        <input type="datetime-local" class="form-control form-control-solid" v-model="prochaineReunion">
                    </div>
                    <div class="mb-6">
                        <label for="description" class="col-form-label">Informations importantes</label>
                        <textarea v-model="importantInfo" type="text" class="form-control" style="white-space: pre-wrap;" id="description"></textarea>
                    </div>
                    <div class="form-check form-switch mb-6">
                        <label class="form-check-label" for="switch-planning">Inclure le planning de chantier</label>
                        <input class="form-check-input" type="checkbox" role="switch" id="switch-planning"
                            v-model="includePlanning">
                    </div>
                    <StatusMultiSelect class="mb-6" title="Status inclus" :statuses="this.statuses" v-model="crIncludedStatusIds"></StatusMultiSelect>
                    <ContactsAttendance :projectId="projectId" v-model="contacts"></ContactsAttendance>
                    <div class="mb-6">
                        <label class="col-form-label">Grouper les tâches par :</label>
                        <div class="form-check mb-2">
                            <input v-model="iterationMode" value="LOT" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                LOT
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input v-model="iterationMode" value="EMPLACEMENT" class="form-check-input" type="radio"
                                   name="flexRadioDefault" id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                                EMPLACEMENT
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input v-model="iterationMode" value="CONTACT" class="form-check-input" type="radio"
                                   name="flexRadioDefault" id="flexRadioDefault3">
                            <label class="form-check-label" for="flexRadioDefault3">
                                ENTREPRISE
                            </label>
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
                <LoadingButton v-if="isSaving"></LoadingButton>
                <button v-if="!isSaving" type="button" class="btn btn-primary" @click="createReport"
                    :disabled="!canSave()">Sauvegarder</button>
            </template>
        </KalmModal>
        <ReportCreatedModal @close="closeReportCreatedModal" v-if="showCreatedReportModal" :report="createdReport">
        </ReportCreatedModal>
    </div>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import ContactsAttendance from '@/views/contacts/presentation/contacts-attendance/ContactsAttendance.vue';
import StatusMultiSelect from '@/views/status/presentation/status-select/StatusMultiSelect.vue';
import dayjs from 'dayjs';
import { CREATE_REPORT, GET_REPORTS, UPDATE_REPORT } from '../../data/reports_graphql';
import ReportCreatedModal from './components/ReportCreatedModal.vue';
import kalmStore from "@/store";
import { ALL_STATUSES_QUERY } from "@/views/status/data/status_graphql";
import { GET_PROJECT_BY_ID } from '@/graphql/graphql';

export default {
    name: "ReportForm",
    apollo: {
        statuses: {
            query: ALL_STATUSES_QUERY,
        },
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: kalmStore.getters.currentProjectFocus,
                }
            }
        }
    },
    props: {
        initialProjectId: String,
        initialReportNumber: Number,
        taskSortOrder: [String],
        report: Object,
    },
    components: {
        KalmModal,
        ReportCreatedModal,
        ContactsAttendance,
        StatusMultiSelect,
        LoadingButton,
    },
    data() {
        const currentDate = dayjs();
        const nextWeek = currentDate.add(7, 'day').set('hour', 8).set('minute', 30);

        return {
            projectId: undefined,
            statuses: [],
            title: "Compte-rendu de chantier",
            reportDate: currentDate.format('YYYY-MM-DD'),
            prochaineReunion: nextWeek.format('YYYY-MM-DDTHH:mm'),
            importantInfo: "",
            includePlanning: false,
            crIncludedStatusIds: [],
            iterationMode: "LOT",
            reportNumber: '',
            contacts: [],
            showCreatedReportModal: false,
            createdReport: undefined,
            isSaving: false,
        };
    },
    created() {
        this.initializeData();
    },
    computed: {
        currentProject() {
            return this.project;
        },
        defaultCrIncludedStatusIds() {
            return this.statuses.filter(status => status.defaultIncluded).map(status => status.id);
        }
    },
    methods: {
        initializeData() {
            if (this.initialProjectId) {
                this.projectId = this.initialProjectId;
                this.crIncludedStatusIds = this.defaultCrIncludedStatusIds
                if(this.initialReportNumber) {
                    this.reportNumber = this.initialReportNumber;
                }
            }
            if (this.report) {
                this.title = this.report?.title || "Compte-rendu de Chantier"
                this.reportDate = this.report.reportDate;
                // todo check value of this.report.prochaineReunion to avoid timezone issues
                this.prochaineReunion = new dayjs(this.report.prochaineReunion).tz('Europe/Paris').format('YYYY-MM-DDTHH:mm');
                this.importantInfo = this.report.importantInfo;
                this.includePlanning = this.report.includePlanning;
                this.crIncludedStatusIds = this.report.crIncludedStatus?.map(status => status.id);
                this.iterationMode = this.report.iterationMode;
                this.reportNumber = this.report.reportNumber;
                this.contacts = this.report.projectReportContacts.map((reportContact) => {
                    return {
                        contactId: reportContact.contactProjectParticipation.contact.id,
                        attendanceStatus: reportContact.attendanceStatus,
                        isConvoque: reportContact.isConvoque,
                    }
                })
            }
            else {
                this.importantInfo = this.currentProject.importantInfo;
            }
        },
        canSave() {
            return !!this.projectId && !!this.reportDate && !!this.prochaineReunion;
        },
        async createReport() {
            this.isSaving = true;
            const input = {
                projectId: this.projectId,
                title: this.title,
                reportDate: this.reportDate,
                prochaineReunion: this.prochaineReunion,
                importantInfo: this.importantInfo,
                includePlanning: this.includePlanning,
                crIncludedStatusIds: this.crIncludedStatusIds,
                contacts: this.contacts,
                iterationMode: this.iterationMode,
                reportNumber: this.reportNumber,
                taskSortOrder: this.taskSortOrder,
            };
            if (this.report) {
                try {
                    const res = await this.$apollo.mutate({
                        mutation: UPDATE_REPORT,
                        variables: {
                            id: this.report.id,
                            input,
                        },
                        refetchQueries: [
                            {
                                query: GET_REPORTS,
                                variables: {
                                    projectId: this.projectId
                                }
                            },
                        ],
                    });
                    this.createdReport = res.data.projectReportUpdate.projectReport;
                    this.showCreatedReportModal = true;
                } catch (error) {
                    console.error('Update report error:', error);
                }
            } else {
                try {
                    const res = await this.$apollo.mutate({
                        mutation: CREATE_REPORT,
                        variables: {
                            input,
                        },
                        refetchQueries: [
                            {
                                query: GET_REPORTS,
                                variables: {
                                    projectId: this.projectId
                                }
                            },
                        ],
                    });
                    this.createdReport = res.data.projectReportCreate.projectReport;
                    this.showCreatedReportModal = true;
                } catch (error) {
                    console.error('Create report error:', error);
                }
            }
            this.isSaving = false;
        },
        closeReportCreatedModal() {
            this.close();
            this.showCreatedReportModal = false;
        },
        close() {
            this.$emit("close");
        },
    },
}
</script>