<template>
    <div class="text-center">
        <img alt="No Todo" :src="image" class="img-fluid w-75"/>
        <h3 class="card-title mt-8 mb-5">
            <span class="card-label fw-bolder text-dark fs-3">{{ message }}</span>
        </h3>
        <div>
            <!-- bootstrap numbered list -->
            <ul class="list-group list-group-numbered">
                <li class="list-group-item text-muted">Créez une consultation pour chaque entreprise</li>
                <li class="list-group-item text-muted">Partagez chaque DPGF Excel à chaque entreprise</li>
                <li class="list-group-item text-muted">Rendez-vous sur l'onglet Comparatif pour comparer</li>
            </ul>
        </div>
        <a class="btn btn-primary fw-bolder" @click="emitMainAction">{{ buttonMessage }}</a>
    </div>
</template>

<script>
export default {
    name: "TenderProposalEmptyState",
    props: {
        message: {
            type: String,
            default: "Cette consultation n'a pas démarré"
        },
        buttonMessage: {
            type: String,
            default: "Consulter une entreprise"
        },
        image: {
            type: String,
            default: "/assets/media/svg/empty-states/empty-tender.svg"

        }
    },
    emits: ['mainAction'],
    methods: {
        emitMainAction() {
            this.$emit('mainAction');
        }
    }
}
</script>