<template xmlns:title="http://www.w3.org/1999/xhtml">
    <node-view-wrapper class="vue-component lot-ouvrage-wrapper no-page-break-before"
     :class="{'print-hidden': this.propPrintHidden}">
        <div @mouseover="this.hover = true"
             @mouseleave="this.hover = false"
        >
            <div class="mt-3 mb-3 ms-8 print-border">
                <div class="ps-0 mt-0">
                    <div class="card lot-component-shadow" :class="{'bg-light': this.propPrintHidden}" style="position: relative;"
                        >
                        <!--begin::Body-->
                        <div class="p-0 card-body">
                            <!--begin::Section-->
                            <div class="print-margins py-6 ps-0 pe-5 align-items-center justify-content-between d-flex">

                                <!-- LEFT HOVER ACTIONS -->
                                <div class="position-absolute d-flex align-content-center print-hidden" style="left: -50px;">
                                    <div role="button"
                                         @click="plusAction()"
                                         contenteditable="false"
                                         title="Ajouter une ligne"
                                         :style="'visibility: ' + (this.showActionButtons ? 'visible' : 'hidden')"
                                         class="ouvrage-actions-left ouvrage-action-plus">
                                        <svg viewBox="0 0 16 16" style="width: 15px; height: 100%; display: block;">
                                            <path d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path>
                                        </svg>
                                    </div>
                                    <div
                                        role="button" tabindex="0"
                                        contenteditable="false"
                                        draggable="true"
                                        data-drag-handle
                                        title="Déplacer l'ouvrage"
                                        class="ouvrage-actions-left ouvrage-action-drag-handle drag-handle"
                                        @click="this.toggleContextMenu()"
                                        :style="'visibility: ' + (this.showActionButtons ? 'visible' : 'hidden')"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 16 16" style="width: 15px; height: 100%; display: block;">
                                            <path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                                        </svg>
                                    </div>
                                    <div v-if="this.contextMenu.show" class="position-relative" @mouseleave="hideContextMenu()">
                                        <div class="position-absolute mt-8 card border border-1 border-dark-subtle shadow" style="z-index: 1000;">
                                            <div class="">
                                                <button class="w-100 text-nowrap btn btn-sm btn-outline-danger rounded-bottom-0" @click="this.deleteNode(); this.hideContextMenu()">Supprimer</button>
                                                <button class="w-100 text-nowrap btn btn-sm btn-outline-primary rounded-0" @click="this.duplicateNode(); this.hideContextMenu()">Dupliquer</button>
                                                <button class="w-100 text-nowrap btn btn-sm btn-outline-primary rounded-0" @click="this.modalAddOuvrageToBiblioShow(); this.hideContextMenu()">Exporter vers biblio</button>
                                                <button class="w-100 text-nowrap btn btn-sm btn-outline-primary rounded-top-0" @click="this.togglePrintHidden(); this.hideContextMenu()">Masquer à l'export</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- MODE - FULL -->
                                <div class="digital-margins flex-grow-1 text-break">

                                    <!-- Node number & collapsed mode badges -->
                                    <div class="d-flex no-page-break-after">
                                        <span class="text-gray-500 font-monospace align-baseline fs-6 mb-1">{{ this.propNumero }}</span>
                                        <div v-if="this.isDescriptionDisplayModeCollapsed" class="d-flex print-hidden">
                                            <span class="ms-2 badge badge-light" v-if="this.propQt">Qt: {{this.propQt}} {{this.propUnit}}</span>
                                            <span class="ms-2 badge badge-light" v-if="this.showBudgetModule && this.propUnitPrice">PU: {{this.propUnitPrice}}€</span>
                                            <span v-if="this.project?.locations.length > 0 && this.propLocations">
                                                <template v-for="locationId in this.propLocations" :key="locationId">
                                                    <span class="mx-1 badge badge-primary">{{ locationOptions.find(l => l.id === locationId)?.name }}</span>
                                                </template>
                                            </span>
                                        </div>
                                    </div>

                                    <!-- Node main content -->
                                    <div class="w-100" style="">
                                        <node-view-content id="ouvrage-node-content" ref="content"
                                                           class="content p-0 lot-ouvrage-content no-page-break-before"
                                                           :style="isDescriptionDisplayModeCollapsed ? 'text-overflow: ellipsis; overflow: hidden; height: 20px; max-height: 20px;' : ''"/>
                                    </div>
                                    <!-- Print Info -->
                                    <div class="print-info">
                                        <div v-if="this.project?.locations?.length > 0 && this.getLocationObjects?.length > 0" class="fw-bold">
                                            Emplacement<span v-if="this.getLocationObjects?.length > 1">s</span> :
                                            <span v-for="(location, index) in this.getLocationObjects"
                                                  :key="location?.id" class="fw-normal">
                                                {{ location?.name }}<span v-if="index !== this.getLocationObjects.length - 1">, </span>
                                            </span>
                                        </div>
                                        <div v-if="this.propQt" class="fw-bold">Quantité : <span class="fw-normal">{{ this.propQt }} {{ this.propUnit }}</span></div>
                                        <div v-if="this.propUnitPrice && this.showPrintUnitPrice" class="fw-bold">Prix unitaire : <span class="fw-normal">{{ this.propUnitPrice }} €</span></div>
                                    </div>
                                    <!-- Actionable node variables -->
                                    <div class="row print-hidden" v-if="!isDescriptionDisplayModeCollapsed">
                                        <!--  EMPLACEMENT  -->
                                        <div class="col-12 col-sm-6 align-items-center">
                                            <div class="mt-3 fs-8 text-muted">
                                                Emplacement :
                                                <LocationMultiselect v-if="projectHasFeatureMultiLocation"
                                                                    :options="locationOptions"
                                                                    v-model="propLocations"
                                                                    @validate="this.$apollo.queries.project.refetch()"></LocationMultiselect>
                                                <select v-else
                                                        class="form-select select text-dark p-0 m-0 fs-6 form-select-white"
                                                        v-model="propLocation">
                                                    <template v-if="this.project?.locations.length > 0 && this.propLocations">
                                                        <option
                                                            v-for="location in [{name: '', id: ''}, ...this.locationOptions]"
                                                            :key="location.id" :value="location.id"
                                                            :selected="this.$props.node.attrs.location === location.id">
                                                            {{
                                                                location.name
                                                            }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div>
                                        </div>
                                        <!--  QUANTITE  -->
                                        <div v-if="this.propQt && isNaN(this.propQt)" class="col-12 cols-sm-3 align-items-center">
                                            <div class="mt-3 fs-8 text-muted">
                                                Quantité :
                                                <input type="text"
                                                       class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6"
                                                       v-model="this.propQt">
                                            </div>
                                        </div>
                                        <!--  QUANTITE / UNITE  -->
                                        <div v-else class="col-12 col-sm-3">
                                            <div class="mt-3 fs-8 text-muted">
                                                Quantité
                                                <div class="input-group">
                                                    <input
                                                        class="form-control text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 fs-6"
                                                        type="number" v-model="this.propQt">
                                                    <input v-if="isFirefox"
                                                           class="form-control text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 border-start-0"
                                                           v-model="this.propUnit"
                                                           placeholder="unité">
                                                    <select v-else
                                                            class="form-control form-select text-start bg-white placeholder-gray-500 text-gray-800 py-2 px-3 m-0 border-start-0"
                                                            v-model="this.propUnit">
                                                        <option selected>ens</option>
                                                        <option>u</option>
                                                        <option>m</option>
                                                        <option>m2</option>
                                                        <option>m3</option>
                                                        <option>ml</option>
                                                        <option>g</option>
                                                        <option>kg</option>
                                                        <option>t</option>
                                                        <option>forfait</option>
                                                        <option hidden>m²</option>
                                                        <option hidden>m³</option>
                                                        <option>jours</option>
                                                        <option>mois</option>
                                                        <option>h</option>
                                                        <option></option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  PRIX UNITAIRE  -->
                                        <div v-if="showBudgetModule" class="col-12 col-sm-3 align-items-center">
                                            <div class="mt-3 fs-8 text-muted">
                                                Prix unitaire
                                                <input type="number"
                                                       class="field-qt form-control text-start bg-white placeholder-gray-500 w-100 text-gray-800 py-2 px-3 m-0 fs-6"
                                                       v-model="this.propUnitPrice">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- RIGHT BUTTON ACTIONS -->
                                <div class="ouvrage-actions position-absolute end-0 top-0 row-cols-1 w-50px h-100 align-content-center justify-content-center">
                                    <button v-if="!isDescriptionDisplayModeCollapsed"
                                            title="Ajouter à une bibliothèque"
                                            class="btn btn-icon btn-sm btn-outline-secondary"
                                            @click="this.modalAddOuvrageToBiblioShow()">
                                        <i class="fa fa-light fa-plus-square"></i>
                                    </button>
                                    <button class="btn btn-icon btn-sm btn-outline-secondary"
                                            title="Dupliquer l'ouvrage"
                                            @click="duplicateNode()">
                                        <i class="fa fa-light fa-copy"></i>
                                    </button>
                                    <button class="btn btn-icon btn-sm btn-outline-secondary"
                                            title="Supprimer l'ouvrage"
                                            @click="deleteNode()">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--end::Body-->
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL - Add ouvrage to biblio -->
        <div class="modal"
             tabindex="-1"
             :style="this.modals.addOuvrageToBiblio.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'">
            <div class="modal-dialog modal-dialog-centered mw-600px">
                <div class="modal-content mx-5">
                    <div class="container px-10 py-10">
                        <div class="modal-header p-0 d-flex justify-content-end border-0">
                            <!--begin::Close-->
                            <div class="btn btn-icon btn-sm btn-light-primary"
                                 @click="this.modalAddOuvrageToBiblioCancel()">
                                <i class="bi bi-x-lg"></i>
                            </div>
                            <!--end::Close-->
                        </div>

                        <div class="modal-body p-0">
                            <!--begin::Stepper-->
                            <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                                <!--begin::Content-->
                                <div class="d-flex flex-row-fluid justify-content-center">
                                    <div class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                        <!--begin::Step-->
                                        <div class="pb-5 completed">
                                            <div class="w-100">
                                                <div class="pb-5">
                                                    <h3 class="fw-bolder text-dark display-6">Ajouter l'ouvrage dans une
                                                        bibliothèque</h3>
                                                </div>
                                                <div class="row">

                                                    <!--begin::Biblio Selection-->
                                                    <div class="w-100">
                                                        <label class="fs-6 me-1 fw-bolder text-muted form-label">Bibliothèque</label>
                                                        <select v-model="this.modals.addOuvrageToBiblio.biblioId"
                                                                class="form-select form-select-solid">
                                                            <option :value="null" disabled>Choisissez une bibliothèque
                                                                d'ouvrages...
                                                            </option>
                                                            <option
                                                                v-for="biblio in this?.biblios?.filter(b => b.type === 'PRIVATE')"
                                                                :key="biblio.id" :value="biblio.id">{{ biblio.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <!--end::Biblio Selection-->

                                                </div>
                                            </div>
                                        </div>
                                        <!--end::Step-->

                                        <!--begin::Actions-->
                                        <div class="d-flex justify-content-end">
                                            <div>
                                                <button class="btn btn-lg btn-light"
                                                        @click="this.modalAddOuvrageToBiblioCancel()">
                                                    Annuler
                                                </button>
                                                <button class="btn btn-lg btn-primary ms-2"
                                                        @click="this.modalAddOuvrageToBiblioValidate()"
                                                        :disabled="!this.modals.addOuvrageToBiblio.biblioId"
                                                >
                                                    Ajouter
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Actions-->
                                    </div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Stepper-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import kalmStore from "@/store";
import LocationMultiselect from "@/views/locations/presentation/location-select/LocationMultiselect.vue";
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';
import {toast} from "vue3-toastify";
import {ALL_BIBLIO_QUERY, BIBLIO_ADD_OUVRAGE_MUTATION, GET_PROJECT_BY_ID} from "@/graphql/graphql";

export default {
    name: "LotOuvrageComponent",
    components: {
        NodeViewWrapper,
        NodeViewContent,
        LocationMultiselect,
    },
    apollo: {
        biblios: {
            query: ALL_BIBLIO_QUERY,
        },
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: kalmStore.getters.currentProjectFocus,
                }
            }
        }
    },
    props: {
        deleteNode: {
            type: Function,
            required: true,
        },
        node: {
            type: Object,
            required: true,
            default: () => ({
                location: "",
                locations: [],
                qt: "",
                unit: "",
                id: "",
                numero: "",
                unitPrice: "",
                printHidden: false,
            })
        },
        updateAttributes: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {
            project: {
                locations: [],
                featureMultiLocation: true,
            },
            contextMenu: {
                show: false
            },
            biblios: [],
            hover: false,
            lots: [],
            selectedLocations: [],
            modals: {
                addOuvrageToBiblio: {
                    show: false,
                    biblioId: null,
                    ouvrage: null,
                },
            }
        }
    },
    computed: {
        showActionButtons() {
            return this.hover || this.contextMenu.show
        },
        propPrintHidden() {
            console.log("print hidden", this.node.attrs.printHidden)
            return this.node.attrs.printHidden;
        },
        showPrintUnitPrice(){
            return this.$store.getters.printValueUnitPrice;
        },
        showBudgetModule() {
            return this.$store.getters.budgetModule;
        },
        isFirefox: () => {
            return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        },
        locationOptions() {
            return this.project?.locations || []
        },
        projectHasFeatureMultiLocation() {
            return this.project?.featureMultiLocation
        },
        propLocation: {
            get() {
                return this.node.attrs.location
            },
            set(value) {
                this.updateAttributes({
                    location: value,
                })
            }
        },
        propUnitPrice: {
            get() {
                return this.node.attrs.unitPrice
            },
            set(value) {
                this.updateAttributes({
                    unitPrice: value,
                })
            }
        },
        propLocations: {
            get() {
                let ret = [];
                try {
                    ret = JSON.parse(this.node.attrs.locations)
                } catch (e) {
                    ret = this.node.attrs?.locations || []
                }

                return Array.isArray(ret) ? ret : []
            },
            set(value) {
                this.updateAttributes({
                    locations: value,
                })
            }
        },
        propNumero() {
            return this.node.attrs.numero;
        },
        getLocationObjects() {
            return (this.propLocations || []).map(l => this.locationOptions.find(o => o.id === l)) || [];
        },
        isDescriptionDisplayModeCollapsed() {
            return kalmStore.state.display.descriptionMode === "collapsed";
        },
        propQt: {
            get() {
                return this.node.attrs.qt
            },
            set(value) {
                this.updateAttributes({
                    qt: value,
                })
            }
        },
        propUnit: {
            get() {
                return this.node.attrs.unit
            },
            set(value) {
                this.updateAttributes({
                    unit: value,
                })
            }
        },
    },
    methods: {
        hideContextMenu(timing = 0) {
            setTimeout(() => {
                this.toggleContextMenu(false)
            }, timing);
        },
        toggleContextMenu(forceState = null) {
            this.contextMenu.show = forceState ?? !this.contextMenu.show
        },
        togglePrintHidden() {
            this.updateAttributes({
                printHidden: !this.node.attrs.printHidden,
            })
        },
        addOuvrageToBiblio() {

        },
        duplicateNode() {
            const end = this.getPos() + this.node.nodeSize

            const ouvrageCopy = {
                "type": "lotOuvrage",
                "attrs": {
                    "location": this.propLocation,
                    "locations": this.propLocations,
                    "qt": this.propQt,
                    "unit": this.propUnit,
                    "unitPrice": this.unitPrice,
                },
                "content": JSON.parse(JSON.stringify(this.node.content.content))
            }

            this.editor.chain().insertContentAt(end, ouvrageCopy).focus().run()
        },
        plusAction() {
            const {editor, getPos, node} = this
            const end = getPos() + node.nodeSize

            editor.chain().insertContentAt(end, {type: "lotParagraph", content: []}).focus().run()
        },
        updateLocation(event) {
            const newLoc = event.target.value;
            this.updateAttributes({
                location: newLoc,
            })
        },
        updateQt(event) {
            const newQt = event.target.value;
            this.updateAttributes({
                qt: newQt,
            })
        },
        modalAddOuvrageToBiblioShow() {
            this.modalAddOuvrageToBiblioCancel()
            this.modals.addOuvrageToBiblio.show = true;
            this.modals.addOuvrageToBiblio.ouvrage = JSON.stringify(this.node);
        },
        modalAddOuvrageToBiblioCancel() {
            this.modals.addOuvrageToBiblio.show = false;
            this.modals.addOuvrageToBiblio.biblioId = null;
            this.modals.addOuvrageToBiblio.ouvrage = null;
        },
        modalAddOuvrageToBiblioValidate() {
            this.$apollo.mutate({
                mutation: BIBLIO_ADD_OUVRAGE_MUTATION,
                variables: {
                    id: this.modals.addOuvrageToBiblio.biblioId,
                    ouvrage: this.modals.addOuvrageToBiblio.ouvrage,
                },
            }).then(() => {
                toast.success('Ouvrage ajouté à la bibliothèque');
                this.modalAddOuvrageToBiblioCancel();
            }).catch(() => {
                toast.error('Erreur lors de l\'ajout de l\'ouvrage à la bibliothèque');
            })
        }
    },
    watch: {
        selectedLocations(newValue) {
            this.updateAttributes({
                locations: newValue,
            })
        },
    }
}
</script>

<style>
#ouvrage-node-content p, ul {
    margin-bottom: 7px !important;
}

@media print {
    .lot-ouvrage-content > h4 {
        break-after: avoid;
        break-before: avoid;
    }
    .no-page-break-after {
        page-break-after: avoid !important;
        break-after: avoid !important;
    }
    .no-page-break-before {
        page-break-before: avoid !important;
        break-before: avoid !important;
    }
    .no-page-break-inside {
        page-break-inside: avoid !important;
        break-inside: avoid !important;
    }
    .force-page-break-before {
        page-break-before: always !important;
    }
}
</style>

<style scoped>

/*noinspection CssRedundantUnit*/
.lot-component-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ProseMirror-focused:focus {
    outline: none !important;
}

.ProseMirror:focus {
    outline: none !important;
}

.ProseMirror-focused:focus-visible {
    outline: none !important;
}

.ProseMirror:focus-visible {
    outline: none !important;
}

.ouvrage-action-drag-handle {
    flex: 0 0 auto;
    order: 2;

    width: 20px;
    height: 24px;

    border-radius: 3px;
    pointer-events: auto;

    cursor: grab;
}

.ouvrage-action-drag-handle:hover {
    background: rgba(55, 53, 47, 0.08);
}

.ouvrage-actions-left {
    display: flex;
    gap: 10px;

    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    border-radius: 3px;
    pointer-events: auto;
}

.ouvrage-action-plus {
    order: 1;
    transition: background 20ms ease-in 0s;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    fill: rgba(55, 53, 47, 0.35);
    cursor: pointer;
    border-radius: 3px;
    pointer-events: auto;
}

.ouvrage-action-plus:hover {
    background: rgba(55, 53, 47, 0.08);
}

.print-info {
    display: none;
}

@media screen {
    div .digital-margins {
        margin-left: 40px;
        margin-right: 60px;
    }
}

@media print {
    div .digital-margins {
        margin-left: 0px;
        margin-right: 0px;
    }
    .lot-component-shadow {
        box-shadow: none;
    }

    .drag-handle {
        display: none;
    }

    .field-qt {
        border: 0;
    }

    .field-multilocation {
        border: 0;
        background-image: unset;
    }

    div .print-margins {
        margin-left: 0 !important;
        padding-left: 5px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .print-border {
        border-left: 1px solid lightgray;
        padding-left: 10px !important;
        margin-bottom: 20px !important;
        margin-top: 15px !important;
        margin-left: 5px !important;
    }

    .ouvrage-left-actions {
        display: none !important;
        visibility: hidden;
    }

    .print-hidden {
        display: none !important;
    }

    .print-info {
        display: block;
    }
}

</style>
