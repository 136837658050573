<template>
    <div class="modal"
        tabindex="-1"
        style="display: block;"
        id="AddLotModal">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                    class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Ajouter un lot</h3>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Form Group-->
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Numéro</label>
                                                <input ref="lotNumberInput" type="text" pattern="\d+[\.\d+]*"
                                                    v-model="lot.number"
                                                    class="form-control form-control-lg form-control-solid"
                                                    placeholder="0">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom du
                                                    lot</label>
                                                <input type="text" v-model="lot.name"
                                                    class="form-control form-control-lg form-control-solid"
                                                    placeholder="Généralités">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="mb-10 w-100">
                                                <label
                                                    class="fs-6 me-1 fw-bolder text-dark form-label">Entreprise</label>
                                                <label
                                                    class="fs-8 me-2 fw-bolder text-muted form-label">(Optionnel)</label>
                                                <p class="text-muted">Note : pour assigner une entreprise à un lot, il
                                                    faut qu'elle soit ajoutée aux intervenants du projet.</p>
                                                <select v-model="lot.assignedCompanyId"
                                                        class="form-select form-select-solid">
                                                    <option :value="null">Lot non attribué</option>
                                                    <option v-for="assignedContact in this.currentProject?.contacts"
                                                            :key="assignedContact.contact.id"
                                                            :value="assignedContact.contact.id">
                                                        {{
                                                            assignedContact.contact.company || assignedContact.contact.name
                                                        }}
                                                    </option>
                                                </select>
                                            </div>
                                            <!--end::Form Group-->
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="$emit('close')" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="validateAddLot"
                                                    :disabled="!validateLotNumberInput(this.lot.number)"
                                                    class="btn btn-lg btn-primary ms-2"
                                                    id="AddButtonLotModal">
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    MUTATION_PROJECT_CREATE_LOT
} from '@/views/lots/data/lots_graphql';
import {toast} from "vue3-toastify";

export default {
    name: 'AddLotModal',
    props: {
        contacts: {
            type: Array,
            required: true
        },
        modelValue: {
            type: Object,
            required: true
        },
        projectId: {
            type: String,
            required: true
        },
        lots: {
            type: Array,
            required: true
        }
    },
    methods: {
        async validateAddLot() {
            
            const newLot = {
                name: this.lot.name || "Nouveau Lot",
                number: ("" + this.lot.number) || "0",
                assignedCompany: this.lot.assignedCompany || null
            };
            
            const count = this.lots.find(l => l.number === newLot.number && l.name === newLot.name)

            if (count) {
                newLot.name += ` (1)`
            }

            try {
                this.$apollo.mutate({
                    mutation: MUTATION_PROJECT_CREATE_LOT,
                    variables: {
                        projectId: this.projectId,
                        newLot,
                    },
                }).then(() => {
                    toast.success("Le lot a été ajouté")
                    this.$emit('validate');
                });
            } catch (error) {
                console.error(error);
                toast.error("Une erreur est survenue lors de l'ajout du nouveau lot")
            }
        },
        validateLotNumberInput(input) {
            return !!input || input === 0;
        },
    },
    computed: {
        lot: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:lot', value);
            }
        },

    }
}
</script>

<style lang="scss" scoped>

</style>