<template>
    <div
        class="modal"
        tabindex="-1"
        @keydown.esc="this.$emit('close')"
        style="display: block;"
    >
        <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
            <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
                <!--begin::Close-->
                <div
                class="btn btn-icon btn-sm btn-light-primary"
                @click="this.$emit('close')"
                >
                <i class="bi bi-x-lg"></i>
                </div>
                <!--end::Close-->
            </div>

            <div class="modal-body p-0">
                <!--begin::Stepper-->
                <div
                class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between"
                >
                <!--begin::Content0-->
                <div class="d-flex flex-row-fluid justify-content-center">
                    <!--begin::Form-->
                    <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                        <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                            <h3 class="fw-bolder text-dark display-6 fs-1">
                            Importer depuis le descriptif
                            </h3>
                            <p class="text-gray-600">
                            Pour correspondre à la dernière version du descriptif,
                            les nouveaux ouvrages seront ajoutés à la
                            consultation, les ouvrages supprimés seront enlevés.
                            </p>

                            <p class="mt-3 text-dark fw-bold">Nouveaux ouvrages</p>

                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="updateProposalImportUnit"
                            >
                                Unités
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="modal.importUnit"
                                id="updateProposalImportUnit"
                            />
                            </div>
                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="updateProposalImportQt"
                            >
                                Quantités
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="modal.importQt"
                                id="updateProposalImportQt"
                            />
                            </div>
                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="updateProposalImportPrice"
                            >
                                Prix unitaires
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="modal.importPrice"
                                id="updateProposalImportPrice"
                            />
                            </div>

                            <p class="mt-5 text-dark fw-bold">
                            Réinitialisation des ouvrages déjà importés
                            </p>

                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="resetExisting"
                            >
                                Réinitialiser les prix, unités et quantités des
                                ouvrages existants à partir du descriptif
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="modal.resetExisting"
                                id="resetExisting"
                            />
                            </div>
                            <div v-if="modal.resetExisting">
                            <p class="text-danger">
                                Attention, cela supprimera tous les prix, quantités
                                et unités déjà saisis et les remplacera par ceux du
                                descriptif.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <div>
                        <button
                            type="button"
                            @click="this.$emit('close')"
                            class="btn btn-lg btn-light"
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            @click="this.$emit('validate')"
                            class="btn btn-lg btn-primary ms-2"
                        >
                            Importer
                        </button>
                        </div>
                    </div>
                    <!--end::Actions-->
                    </div>
                    <!--end::Form-->
                </div>
                <!--end::Content0-->
                </div>
                <!--end::Stepper-->
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProposalUpdateModal",
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },
    computed: {
        modal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
