<template>
    <div class="modal"
        tabindex="-1"
        style="display: block;"
        id="ImportLocationModal">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="closeModal()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div v-if="this.page === 0"
                            class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Importer des emplacements</h3>
                                                <p class="text-muted">Depuis un projet existant</p>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Project Selection-->
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-1 fw-bolder text-dark form-label">Projet</label>
                                                <select v-model="this.projectId"
                                                        class="form-select form-select-solid">
                                                    <option
                                                        v-for="project in [{id: '', name: 'Sélectionnez un projet...', disabled: true}, ...this.projects]"
                                                        :disabled="project.disabled" :key="project.id"
                                                        :value="project.id">{{ project.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <!--end::Project Selection-->

                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="modalImportLocationClose()">
                                                Annuler
                                            </button>
                                            <button class="btn btn-lg btn-primary ms-2"
                                                    @click="this.page++;"
                                                    :disabled="!this.projectId"
                                            >
                                                Suivant
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                        <!--begin::Stepper-->
                        <div v-else
                            class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Importer des emplacements</h3>
                                                <p class="text-muted">Choisissez les emplacements que vous voulez
                                                    importer :</p>
                                            </div>
                                            <!--end::Heading-->

                                            <div class="text-start text-muted mb-3">
                                                <a class="cursor-pointer"
                                                    @click="importLocationsQuickSelect(this.selectedLocations, 'all')">Tout
                                                    sélectionner</a>
                                                /
                                                <a class="cursor-pointer"
                                                    @click="importLocationsQuickSelect(this.selectedLocations, 'none')">Aucun</a>
                                            </div>

                                            <!--begin::Project Selection-->
                                            <div class="mb-10 w-100">
                                                <div v-for="location in this.locations"
                                                    :key="location.id"
                                                    class="form-check form-check-custom mb-3">
                                                    <input class="form-check-input" type="checkbox" :value="location.id"
                                                            v-model="this.selectedLocations"
                                                            :id="location.id">
                                                    <label class="form-check-label text-gray-800 ms-2 text-muted"
                                                            :for="location.id">
                                                        {{ location.name }}
                                                    </label>
                                                </div>
                                            </div>
                                            <!--end::Project Selection-->
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="closeModal()">
                                                Annuler
                                            </button>
                                            <button class="btn btn-lg btn-primary ms-2"
                                                    @click="validateImportLocations()"
                                                    :disabled="!this.projectId || !this.selectedLocations"
                                            >
                                                Importer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    ALL_PROJECTS_NAMES_QUERY,
    GET_PROJECT_BY_ID,
} from '@/graphql/graphql'

import {
    MUTATION_PROJECT_CREATE_LOCATION
} from "@/views/locations/data/location_graphql";

export default {
    name: 'ImportLocationModal',
    data() {
        return {
            page: 0,
            selectedLocations: [],
            projectId: null,
            project: null
        }
    },
    props: {
        currentProject: {
            type: Object,
            default: null
        }
    },
    apollo: {
        projects: ALL_PROJECTS_NAMES_QUERY
    },
    methods: {
        importLocationsQuickSelect(locations, mode) {
            if (mode === 'all') {
                locations.length = 0;
                this.project.locations.forEach(l => {
                    locations.push(l)
                })
            } else {
                locations.length = 0
            }
        },
        closeModal() {
            this.page = 0;
            this.selectedLocations = []; 
            this.$emit('close');
        },
        async validateImportLocations() {
            try {
                this.selectedLocations.forEach(async (location) => {
                    const locationData = this.project.locations.find(l => l.id === location)
                    const newLocation = {
                        name: locationData.name || "Nouvel Emplacement",
                    }

                    if (this.currentProject.locations.find(l => l.name === newLocation.name)) {
                        newLocation.name += " (1)" 
                    }


                    await this.$apollo.mutate({
                        mutation: MUTATION_PROJECT_CREATE_LOCATION,
                        variables: {
                            projectId: this.currentProject.id,
                            newLocation: newLocation
                        }
                    })
                })
            } catch (error) {
                console.error(error)
            }

            this.$emit('validate')
        },
    },
    computed: {
        locations() {
            return this.project?.locations
        }
    },
    watch: {
        projectId: {
            immediate: true,
            async handler(newVal) {
                if (!newVal) {
                    this.project = null
                    return
                }

                const result = await this.$apollo.query({
                    query: GET_PROJECT_BY_ID,
                    variables: {
                        id: newVal
                    }
                })
                this.project = result.data.project
            },
        }
    }
}
</script>

<style lang="scss" scoped>

</style>