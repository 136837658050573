function sortLotNumber(aArray, bArray) {
    if (aArray.length === 0 && bArray.length === 0) {
        return 0
    }
    else if (aArray.length === 0 && bArray.length !== 0) {
        return -1
    }
    else if (aArray.length !== 0 && bArray.length === 0) {
        return 1
    }
    else {
        const a = aArray[0];
        const aRest = aArray.length > 1 ? aArray.slice(1) : []
        const b = bArray[0];
        const bRest = bArray.length > 1 ? bArray.slice(1) : []

        if (parseInt(a) < parseInt(b)) {
            return -1
        }
        else if (parseInt(a) > parseInt(b)) {
            return 1
        }
        else {
            return sortLotNumber(aRest, bRest);
        }
    }
}

function sortLots(a, b) {
    const regex = /\d+/g;
    let aArray = a.number.match(regex) || [];
    let bArray = b.number.match(regex) || [];

    return sortLotNumber(aArray, bArray)
}

function sortChiffrages(a, b) {
    if (a.order < b.order) { return -1; }
    if (a.order > b.order) { return 1; }
    return 0;
}

function sortProposals(a,b) {
    return ('' + a?.name + a?.contact?.name + a.id).localeCompare(('' + b?.name + b?.contact?.name + b.id));
}

export { sortLots, sortChiffrages, sortProposals }