<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  beforeMount() {
    const startPoint = new Date().getTime();
    const delay = 200;
    let authenticated = false;
    fetch(process.env.VUE_APP_URL_BACK+"/auth/session/", {credentials: "include"})
        .then(response => response.json())
        .then((data) => {
          if(!data.isAuthenticated && !window.location.pathname.startsWith("/shared-files/")) {
            authenticated = false;
            window.location.replace(process.env.VUE_APP_URL_BACK+"/auth/login");
          }
          else {
            authenticated = true;
          }
        })
    console.log("[AUTH] ", authenticated)
    while ((new Date().getTime() - startPoint <= delay)) {
      /* wait */
    }
  },
}
</script>

<style scoped>
@media print {
  .print-no-padding {
    padding-left: 0 !important;
  }
}
</style>

<style lang="scss">
  @use '@/sass/style.scss';

  .blur-content{
    filter: blur(5px);
  }

</style>