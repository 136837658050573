<template>
    <div class="row g-0 g-xl-5 g-xxl-8"
       :class="isModalActive ? 'blur-content' : ''">
    <div class="col-12">
      <!-- MES CONTACTS - TABLE -->
      <div class="card card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder text-dark fs-3">Mes Canevas</span>
            <span class="text-muted mt-2 fw-bold fs-6">{{ canevas_count }} caneva<span v-if="canevas_count > 1">s</span></span>
          </h3>
          <div class="card-toolbar justify-content-between">
            <div>
              <ul class="nav nav-pills nav-pills-sm nav-light justify-content-end">
                <!--              <li class="nav-item align-middle">
                                <button class="nav-link align-middle btn btn-active-light btn-color-muted fw-bolder px-4" @click="openContactModal"><i class="bi bi-cloud-download"></i> Importer</button>
                              </li>-->
                <li class="nav-item align-middle">
                  <button class="nav-link align-middle btn btn-primary fw-bolder px-4" @click="this.modalCreateCanevaShow">+ Créer un caneva</button>
                </li>
                <li>
                  <input class="form-control form-control-solid ms-3" v-model="this.canevas_search_filter" placeholder="Rechercher">
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="card-body pt-3 mt-n3">
            <div class="tab-content mt-4" id="myTabTables2">
                <!--begin::Table-->
                <div class="table-responsive">
                    <div v-if="this.getCanevasFiltered.length">
                        <table class="table table-borderless align-middle">
                            <thead>
                            <tr>
                              <th class="px-2 py-0 w-50px"></th>
                              <th class="px-2 py-0 w-100 w-100 w-md-200px"></th>
                              <th class="px-2 py-0 w-100 d-none d-md-table-cell"></th>
                              <th class="px-2 py-0 min-w-100px"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="caneva in this.canevas" :key="caneva.id" @click="this.navigateToCaneva(caneva.id)" class="bg-hover-light">
                                <td class="px-0 py-3 rounded-start p-5">
                                    <div class="symbol symbol-55px ms-5 mt-1 me-5">
                                      <span class="symbol-label bg-light-primary align-middle align-items-end">
                                        <i class="bi bi-bookmark-check fs-1 align-middle align-self-center"></i>
                                      </span>
                                    </div>
                                </td>
                                <td class="px-0">
                                    <p class="text-gray-800 fw-bolder fs-6 my-1">{{ caneva.name }}</p>
                                    <div class="d-flex align-items-center w-100px w-sm-100 overflow-x-scroll d-md-none">
                                        <span v-for="tag in biblio?.tags" :key="tag.id" class="d-inline-block cursor-default text-white px-3 m-1 rounded"
                                            :style="{background: tag?.color}">
                                            {{ tag?.name }}
                                        </span>
                                    </div>
                                </td>
                                <td class="px-3 d-none d-md-table-cell">
                                    <div class="d-none align-items-center d-md-flex">
                                        <span v-for="tag in caneva?.tags" :key="tag.id" class="d-inline-block cursor-default text-white px-3 m-1 rounded"
                                            :style="{background: tag?.color}">
                                            {{ tag?.name }}
                                        </span>
                                    </div>
                                </td>
                                <td class="px-0 rounded-end">
                                    <button v-tooltip:bottom="'Supprimer le caneva'" @click.stop="this.modalDeleteCanevaShow(caneva.id)" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"><i class="bi bi-trash-fill align-baseline text-center"></i></button>
                                    <button v-tooltip:bottom="'importer le caneva'" class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2 mb-1"
                                        @click.stop="modalImportCanevaShow(caneva.id)">
                                        <span class="bi bi-stars"></span>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <p class="text-center text-muted my-8">
                            <span class="bi bi-folder me-2"></span>Aucun Template. Cliquez sur créer un caneva pour en ajouter un<span class="ms-1 bi bi-arrow-up-right"></span>
                        </p>
                    </div>
                </div>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Table Widget 2-->
    </div>
  </div>
  <ImportCanevaModal :caneva-id="modals.importCaneva.canevaId" v-if="modals.importCaneva.show" @close="close" />
  <CanevaFormModal v-if="modals.editCaneva.show" @close="close" :caneva="this.modals.editCaneva.caneva" />
  <CanevaDeleteConfirmModal v-if="modals.deleteCaneva.show" @close="close" :caneva-id="modals.deleteCaneva.canevaId" />
</template>

<script>
import { CANEVAS_QUERY } from '@/views/canevas/data/canevas_graphql';
import ImportCanevaModal from './components/ImportCanevaModal.vue';
import CanevaFormModal from './components/CanevaFormModal.vue';
import CanevaDeleteConfirmModal from './components/CanevaDeleteConfirmModal.vue';
export default {
    name: "CanevasPage",
    apollo: {
      canevas: {
        query: CANEVAS_QUERY,
        fetchPolicy: 'cache-and-network',
        result({ data }) {
          const canevas = data.canevas;
          canevas.forEach(caneva => {
            this.accordeons[caneva.id] = {
              show: false
            };
          });
        }
      },
    },
    components: {
        ImportCanevaModal,
        CanevaFormModal,
        CanevaDeleteConfirmModal
    },
    data() {
        return {
            canevas: [],
            canevas_search_filter: '',
            accordeons: {},
            modals: {
                importCaneva: {
                  show: false,
                  canevaId: null
                },
                deleteCaneva: {
                  show: false,
                  canevaId: null
                },
                editCaneva: {
                  show: false,
                  caneva: null
                },
                others: {
                  show: false
                }
            }
        };
    },
    computed: {
      canevas_count() {
          return this.getCanevasFiltered.length;
      },
      getCanevasFiltered() {
          return this.canevas.filter(caneva => {
              if (!this.canevas_search_filter) return true;
              return caneva.name.toLowerCase().includes(this.canevas_search_filter.toLowerCase());
          });
      },
      isModalActive() {
          return Object.values(this.modals).some(modal => modal.show);
      },
    },

    methods: {
      modalCreateCanevaShow() {
          this.modals.editCaneva.show = true;
      },
      modalImportCanevaShow(canevaId) {
          this.modals.importCaneva.canevaId = canevaId;
          this.modals.importCaneva.show = true;
      },
      modalDeleteCanevaShow(canevaId) {
          this.modals.deleteCaneva.canevaId = canevaId;
          this.modals.deleteCaneva.show = true;
      },
      modalEditCanevaShow(caneva) {
          this.modals.editCaneva.caneva = caneva;
          this.modals.editCaneva.show = true;
      },
      modalEditCanevaClose() {
          this.modals.editCaneva.caneva = null;
          this.modals.editCaneva.show = false;
      },
      modalImportCanevaClose() {
          this.modals.importCaneva.canevaId = null;
          this.modals.importCaneva.show = false;
      },
      close() {
        this.$apollo.queries.canevas.refetch();
        Object.keys(this.modals).forEach(key => {
          this.modals[key].show = false;
        });
      },
      displayAccordeon(canevaId) {
          this.accordeons[canevaId].show = !this.accordeons[canevaId].show;
      },
      navigateToCaneva(canevaId) {
          this.$router.push({ name: 'caneva_detail', params: { id: canevaId } });
      }
    }
}
</script>

<style lang="scss" scoped>

</style>