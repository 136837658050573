<template>
    <!-- MAIN -->
    <div :class="modalActive ? 'blur-content' : ''">
        <PrintHeader pageTitle="Planning"
                     :sectionTitle="this.printMode === 'project' ? 'Planning du projet' : 'Planning de chantier'"></PrintHeader>
        <div class="row gy-5">
            <div class="col-12" :class="this.printMode === 'chantier' ? 'print-hidden' : ''">
                <div class="card print-hidden">
                    <div class="d-flex card-header border-0 pt-5 justify-content-between">
                        <!-- TITLE -->
                        <div>
                            <h3 class="card-title align-text-bottom flex-column print-hidden">
                                <span class="card-label fw-bolder text-dark fs-3"><i
                                    class="bi fs-3 me-2 bi-calendar4-event"></i>Planning projet</span>
                            </h3>
                        </div>
                        <!-- INPUTS -->
                        <div class="d-flex align-baseline">
                            <div class="me-2">
                                <div class="input-group input-group-solid">
                                    <label class="input-group-text text-muted">Début :</label>
                                    <input type="date" class="form-control form-control-solid"
                                           v-model="this.ganttConfig.startDate">
                                </div>
                            </div>
                            <div class="me-2">
                                <div class="input-group input-group-solid">
                                    <label class="input-group-text text-muted">Précision :</label>
                                    <select class="form-select form-select-solid" v-model="this.ganttConfig.precision">
                                        <option value="day">Jour</option>
                                        <option value="week">Semaine</option>
                                        <option value="month">Mois</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div class="input-group input-group-solid">
                                    <label class="input-group-text text-muted">Fin :</label>
                                    <input type="date" class="form-control form-control-solid"
                                           v-model="this.ganttConfig.endDate">
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <button type="button"
                                    @click="this.modalImportLotShow()"
                                    class="btn me-1 btn-light btn-icon print-hidden"
                                    v-tooltip:bottom="'Importer depuis le planning de chantier'"
                            >
                                <span class="bi align-baseline bi-stars"></span>
                            </button>
                            <button type="button"
                                    @click="this.printGlobalPlanning()"
                                    class="btn me-1 btn-primary btn-icon print-hidden"
                                    v-tooltip:bottom="'Télécharger le planning de projet'"
                            >
                                <span class="bi align-baseline bi-download"></span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="d-flex" ref="planningGlobal">
                            <g-gantt-chart
                                date-format="YYYY-MM-DD"
                                :chart-start="this.ganttConfig.startDate || Date.now()"
                                :chart-end="this.ganttConfig.endDate || Date.now()"
                                :precision="this.ganttConfig.precision"
                                :row-height="50"
                                label-column-title="Phases"
                                label-column-width="250px"
                                width="100%"
                                class="w-100"
                                grid
                                bar-start="startDate"
                                bar-end="endDate"
                                @dragend-bar="dragendBar"
                            >
                                <template #label-column-row="{ label, bars }">
                                    <CustomGGanttLabelColumnRow :label="label" :bars="bars"
                                                                @hidePhase="hidePhase"
                                                                @updatePhase="updatePhase"
                                                                @createSubPhase="createSubPhase"
                                                                @deletePhase="deletePhase"
                                    ></CustomGGanttLabelColumnRow>
                                </template>
                                <template v-for="phase in flattenPhases" :key="phase.id">
                                    <g-gantt-row :label="phase.title" :bars="[ this.localPlanning.phases[phase.id] ]"/>
                                </template>
                            </g-gantt-chart>
                        </div>
                        <div class="mt-2">
                            <button class="btn btn-sm btn-bg-light btn-text-gray-600 btn-active-primary btn-active-white"
                                    @click="modalPlanningAddPhaseShow()">
                                Ajouter une phase<span class="ms-2 bi bi-plus-circle"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card print-planning">
                    <div class="card-body">
                        <img v-if="this.planningGlobalImageUrl && this.printMode === 'project'" :src="this.planningGlobalImageUrl" class="mw-100" alt="capture d'écran du planning pour l'impression">
                    </div>
                </div>
            </div>
            <div class="col-12" :class="this.printMode === 'project' ? 'print-hidden' : ''">
                <div class="card print-hidden">
                    <div class="d-flex card-header border-0 pt-5 justify-content-between">
                        <div>
                            <h3 class="card-title align-text-bottom flex-column print-hidden">
                                <span class="card-label fw-bolder text-dark fs-3"><i class="bi fs-3 me-2 bi-calendar4-event"></i>Chantier</span>
                            </h3>
                        </div>
                        <!-- TOOLBAR -->
                        <div class="d-flex align-baseline">
                            <div class="me-2">
                                <div class="input-group input-group-solid">
                                    <label class="input-group-text text-muted">Début :</label>
                                    <input type="date" class="form-control form-control-solid" v-model="this.ganttConfigChantier.startDate">
                                </div>
                            </div>
                            <div class="me-2">
                                <div class="input-group input-group-solid">
                                    <label class="input-group-text text-muted">Précision :</label>
                                    <select class="form-select form-select-solid" v-model="this.ganttConfigChantier.precision">
                                        <option value="day">Jour</option>
                                        <option value="week">Semaine</option>
                                        <option value="month">Mois</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div class="input-group input-group-solid">
                                    <label class="input-group-text text-muted">Fin :</label>
                                    <input type="date" class="form-control form-control-solid" v-model="this.ganttConfigChantier.endDate">
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <button type="button"
                                    @click="this.printPlanningChantier()"
                                    class="btn btn-primary btn-icon fw-bolder print-hidden"
                                    v-tooltip:bottom="'Télécharger le planning de chantier'"
                            >
                                <span class="bi align-baseline bi-download"></span>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div v-if="this.validatePhasesSchema && this.project && this.ganttConfigChantier.startDate && this.ganttConfigChantier.endDate" class="d-flex" ref="planningChantier">
                            <g-gantt-chart
                                date-format="YYYY-MM-DD"
                                :chart-start="this.ganttConfigChantier.startDate || Date.now()"
                                :chart-end="this.ganttConfigChantier.endDate || Date.now()"
                                :precision="this.ganttConfigChantier.precision"
                                :row-height="50"
                                grid
                                bar-start="startDate"
                                bar-end="endDate"
                                class="w-100"
                            >
                                <template v-for="lot in this.project?.lots" :key="lot.id">
                                    <g-gantt-row :label="lot.fullName" v-if="lot?.startDate && lot?.endDate" :bars="[{startDate: lot.startDate, endDate: lot.endDate, ganttBarConfig: { id: lot.id, hasHandles: true, label: lot.fullName, style: {background: phase?.bgColor || this.currentUser?.org?.persoColorMain, borderRadius: '5px', color: phase?.textColor || 'white', } }, },]" />
                                </template>
                            </g-gantt-chart>
                        </div>
                        <!-- PLANNING DE CHANTIER - VIZ SI PAS DE DATES -->
                        <div v-else>
                            Pour visualiser le planning de chantier, entrez une date sur chacun des lots du chantier ci-dessous.
                        </div>
                        <!-- PLANNING DE CHANTIER - LISTE DES LOTS -->
                        <div class="table-responsive print-hidden mt-8">
                            <table class="table table-borderless mb-0 align-middle">
                                <thead>
                                <tr>
                                    <th class="p-0 min-w-150px"></th>
                                    <th class="p-0 min-w-50px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="lot in this.project?.lots" :key="lot.id">
                                    <tr style="break-inside: avoid; break-after: avoid;">
                                        <td class="px-0" :class="lot.number.includes('.') ? 'ps-5' : ''">
                                            <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                                            <span class="text-gray-700 fw-bold d-block mt-1">{{ lot.assignedCompany?.id ? lot.assignedCompany.company || lot.assignedCompany.name : "Non attribué"}}</span>
                                            <span v-if="lot.startDate || lot.endDate" class="text-gray-700 fw-light d-block mt-1"><i class="bi bi-calendar4-event me-2"></i>{{ lot.startDate ? new Date(lot.startDate).toLocaleDateString() : "À déterminer"}} > {{ lot.endDate ? new Date(lot.endDate).toLocaleDateString() : "À déterminer"}}</span>
                                        </td>
                                        <td class="text-end w-100px pe-0 print-hidden">
                                            <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                                                    v-tooltip:bottom="'Modifier'"
                                                    @click="modalEditLotPlanningShow(lot.id)">
                                                <span class="bi bi-pencil-fill"></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr style="break-inside: avoid;">
                                        <td class="p-0 row" :class="lot.number.includes('.') ? 'ps-5' : 'ps-0'">
                                            <div class="col-1" style="width: 20px;">
                                                <i class="bi bi-chat-text align-baseline me-2"></i>
                                            </div>
                                            <div class="col-11" style="">
                                                <p v-if="lot.comment" class="text-gray-700 fw-light fs-6" style="white-space: pre-wrap;">{{ lot.comment }}</p>
                                                <p v-else class="text-gray-700 fw-light fs-6">Aucun commentaire</p>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card print-planning">
                    <div class="card-body">
                        <!-- PLANNING DE CHANTIER - LISTE DES LOTS -->
                        <div class="table-responsive mt-8" :class="this.printMode === 'project' ? 'print-hidden' : ''">
                            <table class="table table-borderless mb-0 align-middle">
                                <thead>
                                <tr>
                                    <th class="p-0 min-w-150px"></th>
                                    <th class="p-0 min-w-50px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="lot in this.project?.lots" :key="lot.id">
                                    <tr style="break-inside: avoid; break-after: avoid;">
                                        <td class="px-0" :class="lot.number.includes('.') ? 'ps-5' : ''">
                                            <a class="text-gray-800 fw-bolder text-hover-primary fs-6">{{ lot.fullName }}</a>
                                            <span class="text-gray-700 fw-bold d-block mt-1">{{ lot.assignedCompany?.id ? lot.assignedCompany.company || lot.assignedCompany.name : "Non attribué"}}</span>
                                            <span v-if="lot.startDate || lot.endDate" class="text-gray-700 fw-light d-block mt-1"><i class="bi bi-calendar4-event me-2"></i>{{ lot.startDate ? new Date(lot.startDate).toLocaleDateString() : "À déterminer"}} > {{ lot.endDate ? new Date(lot.endDate).toLocaleDateString() : "À déterminer"}}</span>
                                        </td>
                                        <td class="text-end w-100px pe-0 print-hidden">
                                            <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white me-2"
                                                    v-tooltip:bottom="'Modifier'"
                                                    @click="modalEditLotPlanningShow(lot.id)">
                                                <span class="bi bi-pencil-fill"></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr style="break-inside: avoid;">
                                        <td class="p-0 row" :class="lot.number.includes('.') ? 'ps-5' : 'ps-0'">
                                            <div class="col-1" style="width: 20px;">
                                                <i class="bi bi-chat-text align-baseline me-2"></i>
                                            </div>
                                            <div class="col-11" style="">
                                                <p v-if="lot.comment" class="text-gray-700 fw-light fs-6" style="white-space: pre-wrap;">{{ lot.comment }}</p>
                                                <p v-else class="text-gray-700 fw-light fs-6">Aucun commentaire</p>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <!-- PLANNING DE CHANTIER - VISUEL GANTT -->
                        <h2 class="text-center mt-5 mb-8 text-dmserif" :style="{color: this.mainColor}">Visualisation du planning</h2>
                        <img v-if="this.planningChantierImageUrl && this.printMode === 'chantier'" :src="this.planningChantierImageUrl" class="mw-100" alt="capture d'écran du planning pour l'impression">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL CREATE PHASE -->
    <div class="modal"
         tabindex="-1"
         :style="modals.planningCreatePhase.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalPlanningAddPhaseCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div class="pb-5 w-100 w-sm-400px">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">{{ this.modals.planningCreatePhase.modalTitle }}</h3>
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom</label>
                                                <input type="text" v-model="this.modals.planningCreatePhase.item.title"
                                                       class="form-control form-control-lg form-control-solid"
                                                       placeholder="APS">
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de
                                                    début</label>
                                                <input type="date" v-model="this.modals.planningCreatePhase.item.startDate"
                                                       class="form-control form-control-lg form-control-solid">
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                                                <input type="date" v-model="this.modals.planningCreatePhase.item.endDate" class="form-control form-control-lg form-control-solid">
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label
                                                    class="fs-6 me-2 fw-bolder text-dark form-label">Commentaires</label>
                                                <textarea class="form-control form-control-solid form-text" v-model="this.modals.planningCreatePhase.item.comment"></textarea>
                                            </div>

                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Couleur</label>
                                                <input v-model="this.modals.planningCreatePhase.item.colorBg" type="color" class="form-control form-control-lg form-control-solid">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalPlanningAddPhaseCancel()"
                                                    class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalPlanningAddPhaseValidate()"
                                                    :disabled="!this.modals.planningCreatePhase.item.title"
                                                    class="btn btn-lg btn-primary ms-2">
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL EDIT PHASE -->
    <div class="modal"
         tabindex="-1"
         :style="modals.planningEditPhase.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalPlanningEditPhaseCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div class="pb-5 w-100 w-sm-400px">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">{{ this.modals.planningEditPhase.modalTitle }}</h3>
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom</label>
                                                <input type="text" v-model="this.modals.planningEditPhase.item.title"
                                                       class="form-control form-control-lg form-control-solid"
                                                       placeholder="APS">
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                                                <input type="date" v-model="this.modals.planningEditPhase.item.startDate"
                                                       class="form-control form-control-lg form-control-solid">
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                                                <input type="date" v-model="this.modals.planningEditPhase.item.endDate"
                                                       class="form-control form-control-lg form-control-solid">
                                            </div>

                                            <div class="mb-10 w-100">
                                                <label
                                                    class="fs-6 me-2 fw-bolder text-dark form-label">Commentaires</label>
                                                <textarea class="form-control form-control-solid form-text"
                                                          v-model="this.modals.planningEditPhase.item.description"></textarea>
                                            </div>

                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Couleur</label>
                                                <input v-model="this.modals.planningEditPhase.item.colorBg" type="color" class="form-control form-control-lg form-control-solid">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalPlanningEditPhaseCancel()"
                                                    class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalPlanningEditPhaseValidate()"
                                                    :disabled="!this.modals.planningEditPhase.item.title"
                                                    class="btn btn-lg btn-primary ms-2">
                                                Modifier
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL DELETE PHASE -->
    <div class="modal"
         tabindex="-1"
         :style="modals.planningDeletePhase.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalConfirmDeletePhaseCancel()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div class="pb-5 w-100 w-sm-400px">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Supprimer la phase</h3>
                                            </div>

                                            <div class="mb-10 w-100">
                                                <p class="fs-6 me-2 fw-bolder text-dark form-label">Êtes-vous sûr de vouloir supprimer cette phase ? Cela supprimera également les sous-phases associées</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="this.modalConfirmDeletePhaseCancel()"
                                                    class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="this.modalConfirmDeletePhaseValidate()"
                                                    class="btn btn-lg btn-danger ms-2">
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL EDIT LOT -->
    <div class="modal"
         tabindex="-1"
         :style="modals.editLotPlanning.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditLotPlanningCancel">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                      class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-1">
                                                <h3 class="fw-bolder text-dark display-6">Modifier un lot</h3>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Form Group-->
                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de début</label>
                                                <label class="ms-1 fs-8 text-muted form-label"></label>
                                                <input type="date" v-model="modals.editLotPlanning.lot.startDate" class="form-control form-control-lg form-control-solid" placeholder="2023-07-01">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Date de fin</label>
                                                <label class="ms-1 fs-8 text-muted form-label"></label>
                                                <input type="date" v-model="modals.editLotPlanning.lot.endDate" class="form-control form-control-lg form-control-solid" placeholder="2023-08-01">
                                            </div>
                                            <!--end::Form Group-->

                                            <!--begin::Form Group-->
                                            <div class="mt-5 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Commentaire pour le CR</label>
                                                <textarea v-model="modals.editLotPlanning.lot.comment" class="form-control form-control-lg form-control-solid" placeholder="Pose du parquet dans les chambres lundi et mardi"></textarea>
                                            </div>
                                            <!--end::Form Group-->

                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="modalEditLotPlanningCancel" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="modalEditLotPlanningValidate" class="btn btn-lg btn-primary ms-2">
                                                Modifier
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL IMPORT LOTS -->
    <div class="modal"
         tabindex="-1"
         :style="modals.importLot.show ? 'display: block;' : 'display: none;'">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalImportLotClose()">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <form ref="contactModalForm"
                                      class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-1">
                                                <h3 class="fw-bolder text-dark display-6">Importer des lots</h3>
                                            </div>
                                            <!--end::Heading-->

                                            <div class="pb-1">
                                                <div class="mb-10 w-100">
                                                    <label class="fs-6 me-2 fw-bolder text-dark form-label">Importer dans :</label>
                                                    <select v-model="modals.importLot.phase" class="form-select form-select-lg form-select-solid">
                                                        <option :value="null">Importer à la racine du planning</option>
                                                        <option v-for="phase in this.planning?.phases" :key="phase.id" :value="phase.id">{{ phase.title }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="text-start text-muted mb-3">
                                                <a class="cursor-pointer"
                                                   @click="importLotsQuickSelect('all')">
                                                    Tout sélectionner
                                                </a>
                                                /
                                                <a class="cursor-pointer"
                                                   @click="importLotsQuickSelect('none')">
                                                    Aucun
                                                </a>
                                            </div>

                                            <!--begin::Lot Selection-->
                                            <div class="mb-10 w-100">
                                                <div v-for="lot in this.project.lots"
                                                     :key="lot.id"
                                                     class="form-check form-check-custom mb-3">
                                                    <input class="form-check-input" type="checkbox" :value="lot.id"
                                                           v-model="this.modals.importLot.selectedLots" :id="'lot-'+lot.id">
                                                    <label class="form-check-label text-gray-800 ms-2 text-muted"
                                                           :for="'lot-'+lot.id">
                                                        {{ lot.fullName }}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="modalImportLotClose" class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="button" @click="modalImportLotValidate" class="btn btn-lg btn-primary ms-2">
                                                Importer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    CURRENT_USER_QUERY,
    GET_PROJECT_BY_ID,
    PLANNING_CREATE_PHASE_MUTATION, 
    PLANNING_DELETE_PHASE_MUTATION,
    PLANNING_FOR_PROJECT_QUERY, 
    PLANNING_UPDATE_PHASE_MUTATION,
} from "@/graphql/graphql";
import kalmStore from "@/store";
import {ALL_CONTACTS_QUERY} from "@/views/contacts/data/contacts_graphql";
import PrintHeader from "@/views/project/tender/PrintHeader";
import domtoimage from "dom-to-image";
import { toast } from "vue3-toastify"

import CustomGGanttLabelColumnRow from "@/views/project/planning/CustomGGanttLabelColumnRow.vue";
import {GGanttRow} from "@infectoone/vue-ganttastic";
import { MUTATION_PROJECT_UPDATE_LOT } from "@/views/lots/data/lots_graphql";

export default {
    name: "ProjectPlanningNew",
    components: {
        GGanttRow,
        PrintHeader,
        CustomGGanttLabelColumnRow,
    },
    apollo: {
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: kalmStore.getters.currentProjectFocus,
                }
            },
            skip() {
                return !kalmStore.getters.currentProjectFocus || kalmStore.getters.currentProjectFocus === "0" || kalmStore.getters.currentProjectFocus === 0;
            }
        },
        contacts: {
            query: ALL_CONTACTS_QUERY,
        },
        currentUser: {
            query: CURRENT_USER_QUERY,
        },
        planning: {
            query: PLANNING_FOR_PROJECT_QUERY,
            variables() {
                return {
                    projectId: kalmStore.getters.currentProjectFocus,
                }
            },
            skip() {
                return !kalmStore.getters.currentProjectFocus || kalmStore.getters.currentProjectFocus === "0" || kalmStore.getters.currentProjectFocus === 0;
            }
        },
    },
    data() {
        return {
            todos: [],
            todoList: [],
            doneList: [],
            dones: [],
            project: {
                phases: [],
                lots: [],
            },
            planning: {
                phases: [],
            },
            localPlanning: {
                phases: {},
            },
            ganttConfig: {
                precision: "week",
                startDate: "",
                endDate: "",
            },
            ganttConfigChantier: {
                precision: "week",
                startDate: null,
                endDate: null,
            },
            currentUser: {},
            contacts: [],
            projects: [],
            lots: [],
            locations: [],
            modals: {
                importLot: {
                    show: false,
                    phase: 0,
                    projectId: '',
                    selectedLots: [],
                },
                planningDeletePhase: {
                    id: "",
                    show: false,
                },
                planningEditPhase: {
                    show: false,
                    modalTitle: "",
                    item: {
                        id: "",
                        hidden: false,
                        title: "",
                        description: "",
                        startDate: null,
                        endDate: null,
                        colorBg: "",
                    }
                },
                planningCreatePhase: {
                    show: false,
                    modalTitle: "Ajouter une phase",
                    item: {
                        parent: "",
                        name: "",
                        startDate: null,
                        endDate: null,
                        type: "",
                        comment: "",
                        colorBg: "",
                    }
                },
                editLotPlanning: {
                    show: false,
                    lotId: "",
                    lot: {
                        comment: "",
                        startDate: "",
                        endDate: "",
                    }
                }
            },
            modalLocation: {
                show: false,
                default: {
                    name: "Nouvel Emplacement 01",
                },
                current: {
                    name: "Nouvel Emplacement 01",
                },
            },
            modalConfirmDeleteLocation: {
                show: false,
                id: ""
            },
            modalEditLocation: {
                show: false,
                id: "",
                newLocation: {
                    id: "",
                    name: "",
                },
            },
            modalAssignContact: {
                show: false,
                default: {
                    contactId: "",
                    role: "CONTRACTOR",
                },
                current: {
                    contactId: "",
                    role: "CONTRACTOR",
                },
            },
            modalUnassignContact: {
                show: false,
                contactId: '',
            },
            contact_role_options: [
                {
                    id: "MOA",
                    name: "MOA - Maîtrise d'Ouvrage",
                },
                {
                    id: "MOE",
                    name: "MOE - Maîtrise d'Œuvre",
                },
                {
                    id: "CONTRACTOR",
                    name: "Entreprise",
                }
            ],
            editProject: {
                name: "",
                address: "",
                infoOpName: "",
                importantInfo: "",
                archived: "",
            },
            buttonProjectPropertiesLoading: false,
            localTimeline: null,
            localCurrentPhase: null,
            planningGlobalImageUrl: '',
            planningChantierImageUrl: '',
            printMode: 'project',
        }
    },
    mounted() {
        this.loadPlanning(true);
        this.updateDatesPlanningChantier();
    },
    methods: {
        importLotsQuickSelect(mode) {
            if (mode === 'all') {
                console.log('select all lots')
                this.modals.importLot.selectedLots.length = 0;
                this.project.lots.forEach(l => {
                    this.modals.importLot.selectedLots.push(l.id)
                })
            } else {
                this.modals.importLot.selectedLots.length = 0
            }
        },
        updateDatesPlanningChantier() {
            this.ganttConfigChantier.startDate = null;
            this.ganttConfigChantier.endDate = null;
            this.project?.lots.forEach(lot => {
                if(!this.ganttConfigChantier.startDate || lot?.startDate && lot.startDate <= this.ganttConfigChantier?.startDate) this.ganttConfigChantier.startDate = lot.startDate;
                if(!this.ganttConfigChantier.endDate || lot?.endDate && lot.endDate > this.ganttConfigChantier.endDate) this.ganttConfigChantier.endDate = lot.endDate;
            });
        },
        loadPlanning(updateDates = false) {
            this.localCurrentPhase = this.project?.currentPhase;
            // set the displayed current phase id to null if id is not part of available phases
            if (!([...this.timeline].map(i => i.name).includes(this.localCurrentPhase))) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.localCurrentPhase = null;
            }

            if(updateDates) {
                this.flattenPhases.forEach(item => {
                    if (!this.ganttConfig.startDate || item.startDate && item.startDate < this.ganttConfig.startDate) this.ganttConfig.startDate = item.startDate;
                    if (!this.ganttConfig.endDate || item.endDate && item.endDate > this.ganttConfig.endDate) this.ganttConfig.endDate = item.endDate;
                })
            }
        },
        modalConfirmDeletePhaseShow(id){
            this.modals.planningDeletePhase.show = true
            this.modals.planningDeletePhase.id = id
        },
        modalConfirmDeletePhaseCancel(){
            this.modals.planningDeletePhase.show = false;
            this.modals.planningDeletePhase.id = "";
        },
        modalConfirmDeletePhaseValidate(){
            this.$apollo.mutate({
                mutation: PLANNING_DELETE_PHASE_MUTATION,
                variables: {
                    id: this.modals.planningDeletePhase.id,
                    projectId: this.project?.id,
                },
                refetchQueries: [
                    {
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: this.project?.id,
                        }
                    }
                ]
            })
            this.modalConfirmDeletePhaseCancel();
        },
        modalPlanningEditPhaseShow(id, title){
            this.modals.planningEditPhase.item = {
                id: this.localPlanning.phases[id].id,
                hidden: this.localPlanning.phases[id].hidden,
                title: this.localPlanning.phases[id].title,
                description: this.localPlanning.phases[id].description,
                startDate: this.localPlanning.phases[id].startDate,
                endDate: this.localPlanning.phases[id].endDate,
                colorBg: this.localPlanning.phases[id].colorBg,
            }

            /*this.modals.planningEditPhase.item = {
                title: "Phase 1",
                description: "Description de la phase 1",
                startDate: "2023-11-02",
                endDate: "2023-11-30",
                colorBg: "#ff0000",
            }*/
            this.modals.planningEditPhase.modalTitle = title;
            this.modals.planningEditPhase.show = true;
        },
        modalPlanningEditPhaseCancel(){
            this.modals.planningEditPhase.show = false;
        },
        modalPlanningEditPhaseValidate(){
            this.$apollo.mutate({
                mutation: PLANNING_UPDATE_PHASE_MUTATION,
                variables: {
                    id: this.modals.planningEditPhase.item.id,
                    parent: this.modals.planningEditPhase.item.parent,
                    projectId: this.project?.id,
                    hidden: this.modals.planningEditPhase.item.hidden,
                    title: this.modals.planningEditPhase.item.title,
                    description: this.modals.planningEditPhase.item.description,
                    startDate: this.modals.planningEditPhase.item?.startDate,
                    endDate: this.modals.planningEditPhase.item?.endDate,
                    colorBg: this.modals.planningEditPhase.item.colorBg,
                },
                refetchQueries: [
                    {
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: this.project?.id,
                        }
                    }
                ]
            })
            this.modalPlanningEditPhaseCancel();
        },
        hidePhase({id, newHiddenStatus}){

            this.$apollo.mutate({
                mutation: PLANNING_UPDATE_PHASE_MUTATION,
                variables: {
                    id: id,
                    projectId: this.project?.id,
                    hidden: newHiddenStatus,
                },
                refetchQueries: [
                    {
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: this.project?.id,
                        }
                    }
                ]
            })
        },
        updatePhase(id){
            this.modalPlanningEditPhaseShow(id, "Modifier une phase");
        },
        createSubPhase(id){
            this.modalPlanningAddPhaseShow(id);
        },
        deletePhase(id){
            this.modalConfirmDeletePhaseShow(id)
        },
        dragendBar({bar}){

            this.$apollo.mutate({
                mutation: PLANNING_UPDATE_PHASE_MUTATION,
                variables: {
                    id: bar.id,
                    projectId: this.project?.id,
                    startDate: bar.startDate,
                    endDate: bar.endDate,
                },
                refetchQueries: [
                    {
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: this.project?.id,
                        }
                    }
                ]
            })
        },
        printGlobalPlanning() {
            this.printMode = 'project';
            // makes a screenshot of gantt planning and adds it into a print-only div
            domtoimage
                .toPng(this.$refs.planningGlobal)
                .then(dataUrl => this.planningGlobalImageUrl = dataUrl)
                .then(() => {
                    this.$nextTick(function () {
                        window.print();
                    })
                })
        },
        printPlanningChantier() {
            this.printMode = 'chantier';

            domtoimage
                .toPng(this.$refs.planningChantier)
                .then(dataUrl => this.planningChantierImageUrl = dataUrl)
                .then(() => {
                    this.$nextTick(function () {
                        window.print();
                    })
                })
        },
        getContactById(contactId) {
            return this.contacts.find(c => c.id === contactId);
        },
        getLotById(lotId) {
            return this.project?.lots.find(l => l.id === lotId);
        },
        getLocationById(locationId) {
            return this.project?.locations.find(l => l.id === locationId);
        },
        modalImportLotShow() {
            this.modals.importLot.show = true;
        },
        modalImportLotClose() {
            this.modals.importLot.show = false;
            this.modals.importLot.phase = null;
            this.modals.importLot.selectedLots = [];
            this.modals.importLot.projectId = '';
        },
        modalImportLotValidate() {
            this.modals.importLot.selectedLots.forEach(async importedlotId => {
                const importedLot = this.project?.lots.find(i => i.id === importedlotId);

                try {
                    await this.$apollo.mutate({
                        mutation: PLANNING_CREATE_PHASE_MUTATION,
                        variables: {
                            projectId: this.currentProjectId,
                            parent: this.modals.importLot.parent,
                            title: importedLot.fullName,
                            startDate: importedLot?.startDate,
                            endDate: importedLot?.endDate,
                            description: importedLot?.comment,
                        },
                        refetchQueries: [
                            {
                                query: PLANNING_FOR_PROJECT_QUERY,
                                variables: {
                                    projectId: kalmStore.getters.currentProjectFocus,
                                }
                            }
                        ]
                    })
                } catch (e) {
                    console.error(e);
                }
            });

            this.modalImportLotClose();
        },
        modalEditLotPlanningShow(lotId) {
            this.modals.editLotPlanning.lotId = lotId;

            const lot = this.project?.lots.find(l => l.id === lotId);

            this.modals.editLotPlanning.lot.comment = lot.comment;
            this.modals.editLotPlanning.lot.startDate = lot.startDate;
            this.modals.editLotPlanning.lot.endDate = lot.endDate;

            this.modals.editLotPlanning.show = true;
        },
        modalEditLotPlanningCancel() {
            this.modals.editLotPlanning.show = false;

            this.modals.editLotPlanning.lotId = "";
            this.modals.editLotPlanning.lot.comment = "";
            this.modals.editLotPlanning.lot.startDate = "";
            this.modals.editLotPlanning.lot.endDate = "";
        },
        async modalEditLotPlanningValidate() {

            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_PROJECT_UPDATE_LOT,
                    variables: {
                        projectId: kalmStore.getters.currentProjectFocus,
                        lotId: this.modals.editLotPlanning.lotId,
                        comment: this.modals.editLotPlanning.lot.comment,
                        startDate: this.modals.editLotPlanning.lot.startDate,
                        endDate: this.modals.editLotPlanning.lot.endDate,
                    },
                    refetchQueries: [
                        {
                            query: GET_PROJECT_BY_ID,
                            variables: {
                                id: kalmStore.getters.currentProjectFocus,
                            }
                        }
                    ]
                })
                toast.success("Le lot a bien été modifié")
            } catch (e) {
                toast.error("Une erreur est survenue lors de la modification du lot")
                console.error(e);
            }

            this.modalEditLotPlanningCancel();
        },
        modalUnassignContactClose() {
            this.modalUnassignContact.show = false;
            this.modalUnassignContact.contactId = '';
        },
        modalPlanningAddPhaseShow(existingPhaseId) {
            let defaultColor = this.currentUser.org?.persoColorMain;
            if(existingPhaseId) {
                let color = this.localPlanning.phases[existingPhaseId]?.colorBg || this.currentUser.org?.persoColorMain;
                const amount = 40;
                // lighten color from hex -- https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
                defaultColor = '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
            }
            this.modals.planningCreatePhase.item = {
                parent: existingPhaseId,
                name: "",
                startDate: null,
                endDate: null,
                type: "",
                comment: "",
                colorBg: defaultColor || "",
            }
            this.modals.planningCreatePhase.modalTitle = existingPhaseId ? "Ajouter une sous-phase" : "Ajouter une phase";
            this.modals.planningCreatePhase.show = true;
        },
        modalPlanningAddPhaseCancel() {
            this.modals.planningCreatePhase.show = false;
        },
        modalPlanningAddPhaseValidate() {
            this.$apollo.mutate({
                mutation: PLANNING_CREATE_PHASE_MUTATION,
                variables: {
                    parent: this.modals.planningCreatePhase.item.parent,
                    projectId: this.currentProjectId,
                    title: this.modals.planningCreatePhase.item.title,
                    startDate: this.modals.planningCreatePhase.item?.startDate,
                    endDate: this.modals.planningCreatePhase.item?.endDate,
                    description: this.modals.planningCreatePhase.item.comment,
                    colorBg: this.modals.planningCreatePhase.item.colorBg,
                },
                refetchQueries: [
                    {
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: this.currentProjectId,
                        }
                    }
                ],
                // TODO add optimistic response
                // TODO add update function with 2 cases (is subPhase, is parent phase)
                /*update(cache, { data: { createPhase } }) {
                    let cachedPlanning = cache.readQuery({
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: currentProjectId,
                        }
                    })

                    const phase = createPhase.phase

                    cachedPlanning = {
                        planning: {
                            ...cachedPlanning.planning,
                            phases: [
                                ...cachedPlanning.planning.phases,
                                phase,
                            ]
                        }
                    }

                    // Write our data back to the cache.
                    cache.writeQuery({
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: currentProjectId,
                        },
                        data: cachedPlanning
                    })

                    console.log(cache.readQuery({
                        query: PLANNING_FOR_PROJECT_QUERY,
                        variables: {
                            projectId: currentProjectId,
                        }
                    }).planning)
                },*/

            })
            this.modals.planningCreatePhase.show = false;
        },
        calculateEndDateForBar(startDate, endDate){
            function addDay(date) {
                const result = new Date(date);
                result.setDate(result.getDate() + 1);
                return result;
            }

            if (endDate) {
                if(endDate === startDate) {
                    return addDay(startDate);
                } else {
                    return endDate;
                }
            } else {
                if(startDate) {
                    return addDay(startDate);
                }
                else {
                    return null;
                }
            }
        },
        transformGPhaseToGanttBar(phase){
            return {
                id: phase.id,
                hidden: phase.hidden,
                title: phase.title,
                startDate: phase.startDate,
                description: phase.description,
                colorBg: phase.colorBg,
                endDate: this.calculateEndDateForBar(phase.startDate, phase.endDate),
                ganttBarConfig: {
                    id: phase.id,
                    indentationLevel: phase.indentationLevel,
                    mobile: true,
                    hasHandles: true,
                    style: {
                        background: phase?.colorBg || this.currentUser.org?.persoColorMain,
                        borderRadius: '5px',
                    },
                }
            }
        },
        flattenPhasesRecursively(phases) {
            let result = [];

            for (let phase of phases) {
                result.push(JSON.parse(JSON.stringify(this.transformGPhaseToGanttBar(phase))));

                if (phase.subPhases && phase.subPhases.length > 0) {
                    result = result.concat(this.flattenPhasesRecursively(phase.subPhases));
                }
            }

            return result;
        },
    },
    computed: {
        mainColor(){ return this.currentUser.org?.persoColorMain || "#195D5B"},
        flattenPhases: {
            get() {
                const phases = this.flattenPhasesRecursively(this.planning?.phases || []);
                phases.forEach((phase) => {
                    this.localPlanning.phases[phase.id] = phase;
                })
                return phases;
            },
        },
        validatePhasesSchema() {
            return true;
        },
        modalActive() {
            return kalmStore.state.display.profileToggled
                || Object.values(this.modals).some(modal => modal.show);
        },
        isArchived() {
            return this.project?.archived;
        },
        getProjects() {
            return kalmStore.state.description.projects;
        },
        projectProperties: {
            get() {
                return {
                    name: this.editProject.name || this.project?.name,
                    address: this.editProject.address || this.project?.address,
                    infoOpName: this.editProject.infoOpName || this.project?.infoOpName,
                    importantInfo: this.editProject.importantInfo || this.project?.importantInfo,
                    archived: this.editProject.archived,
                }
            },
            set(newValue) {
                this.editProject = newValue;
            }
        },
        timeline: {
            get() {
                if (this.project?.phases) {
                    if (!this.localTimeline) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.localTimeline = this.project?.phases;
                    }
                    return this.localTimeline;
                } else {
                    return []
                }
            },
            set(newValue) {
                this.localTimeline = newValue;
            }
        },
        currentPhase: {
            get() {
                if (!this.localCurrentPhase) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.localCurrentPhase = this.project?.currentPhase;
                    if (!(this.timeline.map(i => i.name).includes(this.localCurrentPhase))) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.localCurrentPhase = null;
                    }
                }
                return this.localCurrentPhase;
            },
            set(newValue) {
                this.localCurrentPhase = newValue;
                this.updateProjectPlanning();
            }
        },
        canSafelyDeleteLot() {
            return kalmStore.getters.canSafelyDeleteLot(kalmStore.state.display.currentProjectFocus, this.modalConfirmDeleteLot.id);
        },
        canSafelyDeleteLocation() {
            return kalmStore.getters.canSafelyDeleteLocation(kalmStore.state.display.currentProjectFocus, this.modalConfirmDeleteLocation.id);
        },
        currentProjectId() {
            return kalmStore.getters.currentProjectFocus;
        }
    },
    watch: {
        project() {
            this.loadPlanning(true);
            this.updateDatesPlanningChantier()
        },
        'planning': {
            deep: true,
            handler(oldValue, newValue) {
                if(oldValue?.id !== newValue?.id) {
                    this.loadPlanning(true);
                }
            }
        },
    },
}
</script>

<style>
.g-label-column-row {
    overflow: visible !important;
}

.g-gantt-tooltip {
    display: none !important;
}

.g-gantt-row-label {
    display: none;
    /*background-color: white !important;
    box-shadow: none;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 300px;*/
}

.g-gantt-row-bars-container {
    border-bottom: hidden !important;
    border-top: hidden !important;
}

.g-timeunits-container, .g-upper-timeunit, .g-timeunit {
    background-color: white !important;
}

.g-timeaxis {
    box-shadow: none;
}
</style>

<style scoped>

.planning-card-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@media screen {
    .print-planning {
        display: none;
    }
}

@media print {
    .print-hidden {
        display: none !important;
    }

    .print-planning {
        display: block !important;
    }

    .planning-card-shadow {
        box-shadow: none;
    }
}
</style>