<template>
    <div
        class="modal"
        tabindex="-1"
        @keydown.esc="this.$emit('close')"
        style="display: block;"
    >
        <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
            <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
                <!--begin::Close-->
                <div
                class="btn btn-icon btn-sm btn-light-primary"
                @click="this.$emit('close')"
                >
                <i class="bi bi-x-lg"></i>
                </div>
                <!--end::Close-->
            </div>

            <div class="modal-body p-0">
                <!--begin::Stepper-->
                <div
                class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between"
                >
                <!--begin::Content0-->
                <div class="d-flex flex-row-fluid justify-content-center">
                    <!--begin::Form-->
                    <form
                    ref="contactModalForm"
                    class="pb-5 w-100 w-sm-400px"
                    novalidate
                    >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                        <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                            <h3 class="fw-bolder text-dark display-6">
                            Confirmer la suppression
                            </h3>
                        </div>
                        </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <div>
                        <button
                            type="button"
                            @click="this.$emit('close')"
                            class="btn btn-lg btn-light"
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            @click="this.$emit('validate')"
                            class="btn btn-lg btn-primary ms-2"
                        >
                            Supprimer
                        </button>
                        </div>
                    </div>
                    <!--end::Actions-->
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Content0-->
                </div>
                <!--end::Stepper-->
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProposalDeleteModal",
};
</script>

