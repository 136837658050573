import gql from 'graphql-tag';

export const LINK_EVENT_CREATE = gql`
    mutation LinkEventCreate($linkId: UUID!, $fileId: UUID!, $name: String!) {
        linkEventCreate(linkId: $linkId, fileId: $fileId, name: $name) {
            linkEvent {
                id
                name
                createdAt
                updatedAt
            }
        }
    }
`;