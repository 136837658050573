import gql from 'graphql-tag';

export const GET_FILES_BY_LINK = gql`
    query FilesByLink($linkId: UUID) {
        filesByLink: filesByLink(linkId: $linkId) {
            id
            url
            isDirectory
            name
            creationDate
            type
            archived
            archivedDate
        }
    }
`;

export const GET_FILES = gql`
    query Files($projectId: UUID, $taskId: UUID, $parentId: UUID) {
        files: files(projectId: $projectId, taskId: $taskId, parentId: $parentId) {
            id,
            url,
            isDirectory,
            type,
            name,
            creationDate,
            tasks {
                id,
                title,
            },
            proposal {
                id,
                name,
            },
            project {
                id,
            },
        }
    }
`;

export const GET_FOLDER_BY_ID = gql`
    query FolderById($folderId: UUID!) {
        folder: folderById(id: $folderId) {
            id,
            url,
            isDirectory,
            type,
            name,
            creationDate,
            parent { id },
        }
    }
`;

export const FILE_UPLOAD = gql`
    mutation FileUpload($file: Upload!, $projectId: UUID, $taskId: UUID, $todoItemId: UUID, $parentId: UUID, $type: String, $proposalId: UUID, $contactId: UUID) {
        fileUpload(file: $file, projectId: $projectId, taskId: $taskId, todoItemId: $todoItemId, parentId: $parentId, type: $type, proposalId: $proposalId, contactId: $contactId) {
            fileInfo {
                id
                url
                isDirectory
                name
                creationDate
            }
        }
    }
`;

export const FILE_UPDATE = gql`
    mutation FileUpdate($fileId: UUID!, $fileName: String!) {
        fileUpdate(id: $fileId, name: $fileName) {
            file {
                id
                name
            }
        }
    }
`;

export const FILE_ARCHIVE = gql`
    mutation FileArchive($fileId: UUID!) {
        fileArchive(id: $fileId) {
            archived
        }
    }
`;

export const CREATE_DIRECTORY = gql`
  mutation CreateDirectory($name: String!, $projectId: UUID, $taskId: UUID, $parentId: UUID) {
    directoryCreate(name: $name, projectId: $projectId, taskId: $taskId, parentId: $parentId) {
      fileInfo {
        id
        isDirectory
        name
        creationDate
      }
    }
  }
`;

export const ASSIGN_TASKS = gql`
    mutation FileAssignTasks($fileId: UUID!, $taskIds: [UUID]) {
        fileAssignTasks(id: $fileId, taskIds: $taskIds) {
            file {
                id
                isDirectory
                name
                creationDate
            }
        }
    }
`;

export const UNASSIGN_TASK = gql`
    mutation FileUnassignTask($fileId: UUID!, $taskToRemoveId: UUID!) {
        fileUnassignTask(id: $fileId, taskToRemoveId: $taskToRemoveId) {
            file {
                id
                isDirectory
                name
                creationDate
            }
        }
    }
`;

export const UNASSIGN_TODO_ITEM = gql`
    mutation FileUnassignTodoItem($fileId: UUID!, $todoItemToRemoveId: UUID!) {
        fileUnassignTodoItem(id: $fileId, todoItemToRemoveId: $todoItemToRemoveId) {
            file {
                id
                isDirectory
                name
                creationDate
            }
        }
    }
`;

export const UNASSIGN_CONTACT = gql`
    mutation FileUnassignContact($fileId: UUID!) {
        fileUnassignContact(id: $fileId) {
            file {
                id
                isDirectory
                name
                creationDate
            }
        }
    }
`;

export const ASSIGN_PROPOSALS = gql`
    mutation FileAssignProposals($fileId: UUID!, $proposalIds: [UUID]) {
        fileAssignProposals(id: $fileId, proposalIds: $proposalIds) {
            file {
                id
                isDirectory
                name
                creationDate
            }
        }
    }
`;

export const UNASSIGN_PROPOSAL = gql`
    mutation FileUnassignProposal($fileId: UUID!, $proposalToRemoveId: UUID!) {
        fileUnassignProposal(id: $fileId, proposalToRemoveId: $proposalToRemoveId) {
            file {
                id
                isDirectory
                name
                creationDate
            }
        }
    }
`;