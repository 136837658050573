<template>
    <div class="modal"
        tabindex="-1"
        style="display: block;"
        id="ImportLotModal">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="closeModal">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div v-if="this.page === 0"
                            class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Importer des lots</h3>
                                                <p class="text-muted">Choisissez le lot d'un projet ou gabarit dont vous
                                                    voulez importer le contenu :</p>
                                            </div>
                                            <!--end::Heading-->

                                            <!--begin::Project Selection-->
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-1 fw-bolder text-dark form-label">Projet</label>
                                                <select v-model="this.projectId"
                                                        class="form-select form-select-solid">
                                                    <option
                                                        v-for="project in [{id: '', name: 'Sélectionnez un projet...', disabled: true}, ...this.projects]"
                                                        :disabled="project.disabled" :key="project.id"
                                                        :value="project.id">{{ project.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <!--end::Project Selection-->

                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="closeModal">
                                                Annuler
                                            </button>
                                            <button class="btn btn-lg btn-primary ms-2"
                                                    @click="this.page++;"
                                                    :disabled="!this.projectId"
                                            >
                                                Suivant
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                        <!--begin::Stepper-->
                        <div v-else
                            class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactEditModalForm"
                                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework">
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Importer des lots</h3>
                                                <p class="text-muted">Choisissez les lot que vous voulez importer :</p>
                                            </div>
                                            <!--end::Heading-->

                                            <div class="text-start text-muted mb-3">
                                                <a class="cursor-pointer"
                                                @click="importLotsQuickSelect('all')">
                                                    Tout sélectionner
                                                </a>
                                                /
                                                <a class="cursor-pointer"
                                                @click="importLotsQuickSelect('none')">
                                                    Aucun
                                                </a>
                                            </div>

                                            <div class="mb-10 w-100">
                                                <div v-for="lot in this.lots"
                                                    :key="lot.id"
                                                    class="form-check form-check-custom mb-3">
                                                    <input class="form-check-input" type="checkbox" :value="lot.id"
                                                        v-model="this.selectedLots" :id="lot.id">
                                                    <label class="form-check-label text-gray-800 ms-2 text-muted"
                                                        :for="lot.id">
                                                        {{ lot.fullName }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button class="btn btn-lg btn-light"
                                                    @click="closeModal">
                                                Annuler
                                            </button>
                                            <button class="btn btn-lg btn-primary ms-2"
                                                    @click="validateImportLot()"
                                                    :disabled="!this.projectId || !this.selectedLots"
                                            >
                                                Importer
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MUTATION_PROJECT_CREATE_LOT
} from "@/views/lots/data/lots_graphql"

import {
    ALL_PROJECTS_SIMPLE_QUERY, GET_PROJECT_BY_ID
} from "@/graphql/graphql"
export default {
    name: 'ImportLotModal',
    apollo: {
        projects: ALL_PROJECTS_SIMPLE_QUERY,
        projectToImport: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: this.projectId
                };
            },
            skip() {
                return !this.projectId;
            },
            update: data => data.project
        }
    },
    data() {
        return {
            page: 0,
            selectedLots: [],
            projectId: null,
            projectToImport: {
                lots: [],
            },
            projects: [],
        }
    },
    props: {
        currentProject: {
            type: Object,
            default: null
        }
    },
    methods: {
        importLotsQuickSelect(mode) {
            if (mode === 'all') {
                console.log('select all lots')
                this.selectedLots.length = 0;
                console.log(this.projectToImport)
                this.projectToImport?.lots.forEach(l => {
                    this.selectedLots.push(l.id)
                })
            } else {
                this.selectedLots.length = 0
            }
        },
        closeModal() {
            this.page = 0;
            this.selectedLots = [];
            this.$emit('close')
        },
        async validateImportLot() {
            console.log(this.currentProject)
            try {
                this.selectedLots.forEach( async (lot) => {
                    const lotData = this.projectToImport.lots.find(l => l.id === lot)
                    const newLot = {
                        number: lotData.number || "0",
                        name: lotData.name || "Nouveau Lot",
                        assignedCompany: null,
                    }
                    
                    if (this.currentProject.lots.find(l => l.number === newLot.number && l.name === newLot.name)) {
                        newLot.name += " (1)"
                    }


                    await this.$apollo.mutate({
                        mutation: MUTATION_PROJECT_CREATE_LOT,
                        variables: {
                            projectId: this.currentProject.id,
                            newLot
                        }
                    })
                })
            } catch (e) {
                console.error(e)
            }

            this.$emit('validate')
        }
    },
    computed: {
        lots() {
            return this.project?.lots
        },
    },
    watch: {
        projectId: {
            immediate: true,
            async handler(newVal) {
                if (!newVal) {
                    this.project = null
                    return
                }
                const response = await this.$apollo.query({
                    query: GET_PROJECT_BY_ID,
                    variables: {
                        id: newVal
                    }
                })
                this.project = response.data.project
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>