<template>
    <div
        class="modal"
        tabindex="-1"
        @keydown.esc="this.$emit('close')"
        style="display: block;"
    >
        <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
            <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
                <!--begin::Close-->
                <div
                class="btn btn-icon btn-sm btn-light-primary"
                @click="this.$emit('close')"
                >
                <i class="bi bi-x-lg"></i>
                </div>
                <!--end::Close-->
            </div>

            <div class="modal-body p-0">
                <!--begin::Stepper-->
                <div
                class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between"
                >
                <!--begin::Content0-->
                <div class="d-flex flex-row-fluid justify-content-center">
                    <!--begin::Form-->
                    <form
                    ref="contactModalForm"
                    class="pb-5 w-100 w-sm-400px"
                    novalidate
                    >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                        <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                            <h3 class="fw-bolder text-dark display-6 mb-0">
                            Mettre à jour
                            </h3>
                            <p class="mt-0 text-dark">
                            toutes les consultations & estimatifs
                            </p>
                            <p class="text-gray-600">
                            Pour correspondre à la dernière version du descriptif,
                            les nouveaux ouvrages seront ajoutés à la
                            consultation, les ouvrages supprimés seront enlevés.
                            </p>

                            <p class="mt-3 text-dark fw-bold">
                            Import personnalisé des nouveaux ouvrages
                            </p>
                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="importUnit"
                            >
                                Unités
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="this.modal.importUnit"
                                id="importUnit"
                            />
                            </div>
                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="importQt"
                            >
                                Quantités
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="this.modal.importQt"
                                id="importQt"
                            />
                            </div>
                            <div class="form-check form-switch mb-3">
                            <label
                                class="form-check-label text-gray-800 ms-2 text-muted"
                                for="importPrice"
                            >
                                Prix unitaires
                            </label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="this.modal.importPrice"
                                id="importPrice"
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                        <div>
                        <button
                            type="button"
                            @click="this.$emit('close')"
                            class="btn btn-lg btn-light"
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            @click="this.$emit('validate')"
                            class="btn btn-lg btn-primary ms-2"
                        >
                            Mettre à jour
                        </button>
                        </div>
                    </div>
                    <!--end::Actions-->
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Content0-->
                </div>
                <!--end::Stepper-->
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProposalUpdateAllModal",
    props: {
        modelValue: {
            type: Object,
            required: true,
        }
    },
    computed: {
        modal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
