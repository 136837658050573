<template>
    <KalmModal :modalTitle="getTitle()" @close="close" id="BatchUpdateTaskModal">
        <template v-slot:body>
            <p class="mb-3">Choisissez les champs à modifier pour cet ensemble de tâches.</p>
            <form @submit.prevent="updateTask">
                <StatusSelect v-model="statusId" title="Status" :default-null-status="true"></StatusSelect>
                <ContactSelect v-model="assignedCompanyId" :contacts="contactOptions" :show-do-nothing-option="true" title="Responsable"></ContactSelect>
                <div class="mt-3 w-100">
                    <label for="color" class="col-form-label">Tâche mise en avant (couleur) :</label>
                    <div class="w-100 d-flex">
                        <input v-model="this.color" type="radio" class="btn-check" id="color_default" :value="null">
                        <label class="flex-grow-1 btn btn-light rounded-end-0" for="color_default">Par défaut</label>

                        <input v-model="this.color" type="radio" class="btn-check" id="color_persoColor1" :value="this.currentUser?.org?.persoColor1">
                        <label class="btn btn-icon btn-light rounded-0" for="color_persoColor1"><span class="rounded-circle h-20px w-20px text-white" :style="'background-color: '+this.currentUser?.org?.persoColor1">1</span></label>

                        <input v-model="this.color" type="radio" class="btn-check" id="color_persoColor2" :value="this.currentUser?.org?.persoColor2">
                        <label class="btn btn-icon btn-light rounded-0" for="color_persoColor2"><span class="rounded-circle h-20px w-20px text-white" :style="'background-color: '+this.currentUser?.org?.persoColor2">2</span></label>

                        <input v-model="this.color" type="radio" class="btn-check" id="color_persoColor3" :value="this.currentUser?.org?.persoColor3">
                        <label class="btn btn-icon btn-light rounded-0" for="color_persoColor3"><span class="rounded-circle h-20px w-20px text-white" :style="'background-color: '+this.currentUser?.org?.persoColor3">3</span></label>

                        <input v-model="this.color" type="radio" class="btn-check" id="color_persoColor4" :value="this.currentUser?.org?.persoColor4">
                        <label class="btn btn-icon btn-light rounded-0" for="color_persoColor4"><span class="rounded-circle h-20px w-20px text-white" :style="'background-color: '+this.currentUser?.org?.persoColor4">4</span></label>

                        <input v-model="this.color" type="radio" class="btn-check" id="color_persoColor5" :value="this.currentUser?.org?.persoColor5">
                        <label class="btn btn-icon btn-light rounded-0" for="color_persoColor5"><span class="rounded-circle h-20px w-20px text-white" :style="'background-color: '+this.currentUser?.org?.persoColor5">5</span></label>

                        <input v-model="this.color" type="radio" class="btn-check" id="color_persoColor6" :value="this.currentUser?.org?.persoColor6">
                        <label class="btn btn-icon btn-light rounded-start-0" for="color_persoColor6"><span class="rounded-circle h-20px w-20px text-white" :style="'background-color: '+this.currentUser?.org?.persoColor6">6</span></label>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Annuler</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
            <button type="button" class="btn btn-primary" @click="updateTask"
                :disabled="!canSave()">Sauvegarder</button>
        </template>
    </KalmModal>
</template>

<script>
import { CURRENT_USER_QUERY, GET_PROJECT_BY_ID } from '@/graphql/graphql';
import kalmStore from '@/store';
import KalmModal from '@/views/_core/components/KalmModal.vue';
import StatusSelect from '@/views/status/presentation/status-select/StatusSelect.vue';
import ContactSelect from '@/views/contacts/presentation/contact-select/ContactSelect.vue';
import { BATCH_UPDATE_TASKS } from '@/views/tasks/data/tasks_graphql';
import { toast } from 'vue3-toastify';

export default {
    name: "BatchUpdateTaskModal",
    components: {
        KalmModal,
        StatusSelect,
        ContactSelect,
    },
    apollo: {
        currentUser: CURRENT_USER_QUERY,
        project: {
            query: GET_PROJECT_BY_ID,
            variables() {
                return {
                    id: kalmStore.getters.currentProjectFocus
                }
            },
            skip() {
                return !kalmStore.getters.currentProjectFocus || kalmStore.getters.currentProjectFocus === '0' || kalmStore.getters.currentProjectFocus === 0;
            }
        }
    },
    props: {
        tasks: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            statusId: null,
            color: null,
            assignedCompanyId: "11111111-1111-1111-1111-111111111111",
        };
    },
    methods: {
        getTitle() {
            return `Modification de ${this.tasks.length} tâches`;
        },
        close() {
            this.$emit('close');
        },
        async updateTask() {
            console.log(this.statusId)
            try {
                await this.$apollo.mutate({
                    mutation: BATCH_UPDATE_TASKS,
                    variables: {
                        ids: this.tasks.map(task => task.id),
                        statusId: this.statusId,
                        color: this.color,
                        assignedCompanyId: this.assignedCompanyId,
                        projectId: kalmStore.getters.currentProjectFocus
                    }
                })

                this.$emit("validate")
                toast.success("Tâches mises à jour avec succès");
            } catch (e) {
                console.error(e);
                toast.error("Erreur lors de la mise à jour des tâches");
            }
            this.close();
        },
        canSave() {
            return this.statusId !== null || this.assignedCompanyId !== null || this.color !== null;
        }
    },
    computed: {
        contactOptions() {
            return this.project?.contacts.map(c => c.contact) || [];
        },
    }
}
</script>

<style lang="scss" scoped>

</style>