import gql from 'graphql-tag';

export const GET_TASKS = gql`
    query Tasks(
        $projectId: UUID!,
        $taskSortOrder: [String],
        $hideArchived: Boolean,
    ) {
        tasks: tasks(
            projectId: $projectId,
            taskSortOrder: $taskSortOrder,
            hideArchived: $hideArchived,
        ) {
            id
            numero
            groupTitle
            title
            description
            ouvrageId
            qt
            startDate
            endDate
            order
            assignedCompany {
                id
                name
            }
            project {
                id
            }
            lot {
                id
                name
                number
                startDate
                endDate
                comment
                fullName
            }
            status {
                id
                name
                type
            }
            color
            locations {
                id
                name
            }
            contacts {
                id
                name
                company
            }
            files {
                id
                name
                url
            }
            layout
        }
    }
`;

export const GET_TASK_BY_ID = gql`
    query GetTaskById($taskId: UUID!) {
        task: getTaskById(taskId: $taskId) {
            id
            numero
            groupTitle
            title
            description
            ouvrageId
            qt
            startDate
            endDate
            order
            assignedCompany {
                id
                name
            }
            project {
                id
            }
            lot {
                id
                name
                number
                startDate
                endDate
                comment
                fullName
            }
            status {
                id
                name
                type
            }
            color
            locations {
                id
                name
            }
            files {
                id
                name
                url
            }
            contacts {
                id
                name
                company
            }
            layout
        }
    }
`;

export const TASK_INPUT = gql`
  input TaskInput {
    title: String!
    description: String
    lotId: UUID!
    projectId: UUID!
    statusId: UUID
    color: String
    assignedCompanyId: UUID
    locationIds: [UUID]
    contactIds: [UUID]
    startDate: Date
    endDate: Date
    order: String
    qt: String
    ouvrageId: String
    layout: Int
  }
`;

export const CREATE_TASK = gql`
  mutation TaskCreate
  (
    $input: TaskInput,
    $duplicateFrom: UUID,
  ) {
    taskCreate(
        input: $input,
        duplicateFrom: $duplicateFrom
    ) {
      task {
        id
      }
    }
  }
`;

export const UPDATE_TASK = gql`
    mutation TaskUpdate($id: UUID!, $input: TaskInput!) {
        taskUpdate(id: $id, input: $input) {
            task {
            id
            numero
            groupTitle
            title
            description
            ouvrageId
            qt
            startDate
            endDate
            order
            assignedCompany {
                id
                name
            }
            project {
                id
            }
            lot {
                id
                name
                number
                startDate
                endDate
                comment
                fullName
            }
            status {
                id
                name
                type
            }
            color
            locations {
                id
                name
            }
            contacts {
                id
                name
                company
            }
            files {
                id
                name
                url
            }
            layout
        }
    }
}
`;

export const DELETE_TASK = gql`
  mutation TaskDelete($id: UUID!) {
    taskDelete(id: $id) {
      deleted
    }
  }
`;

export const BATCH_DELETE_TASKS = gql`
    mutation TaskBatchDelete($projectId: UUID!, $ids: [UUID]!) {
        taskBatchDelete(tasks: $ids, projectId: $projectId) {
            deleted
        }
    }
`;

export const BATCH_UPDATE_TASKS = gql`
    mutation TaskBatchUpdate($projectId: UUID!, $ids: [UUID]!, $color: String, $statusId: UUID, $assignedCompanyId: UUID) {
        taskBatchUpdate(tasks: $ids, projectId: $projectId, color: $color, statusId: $statusId, assignedCompanyId: $assignedCompanyId) {
            updated
        }
    }
`;