<template>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
                <div class="lot-drag-handle handle me-2" style="cursor: grab;">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                        style="width: 15px; height: 100%; display: block;" class="align-self-center">
                        <path fill-opacity="0.2"
                            d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                    </svg>
                </div>
                <div class="form-check">
                    <input @change="markAsDone()" class="form-check-input" type="checkbox" :value="todoSubItem.isDone"
                        :checked="todoSubItem.isDone" :id="todoSubItem.id">
                    <label class="form-check-label" :class="{ 'text-decoration-line-through': todoSubItem.isDone }"
                        :for="todoSubItem.id">
                        {{ todoSubItem.name }}
                    </label>
                </div>
            </div>
            <div class="flex-shrink-0">
                <EditButton @click="editTodoItem"></EditButton>
                <DeleteButton @click="deleteItem"></DeleteButton>
            </div>
        </div>

        <!-- Edit form -->
        <TodoSubItemForm v-if="showTodoSubItemForm" :todo-item="todoSubItem" @close="showTodoSubItemForm = false"
            @save="editItemName" />
    </div>
</template>

<script>
import DeleteButton from '@/views/_core/components/DeleteButton.vue';
import EditButton from '@/views/_core/components/EditButton.vue';
import TodoSubItemForm from '../../todo-form/TodoSubItemForm.vue';
export default {
    name: "TodoSubItem",
    props: {
        todoSubItem: {
            type: Object,
            required: true,
        },
    },
    components: {
        EditButton,
        DeleteButton,
        TodoSubItemForm,
    },
    data() {
        return {
            showTodoSubItemForm: false,
        };
    },
    methods: {
        reset() {
            this.showTodoSubItemForm = false;
        },
        editTodoItem() {
            this.showTodoSubItemForm = true;
        },
        deleteItem() {
            this.$emit("delete");
        },
        editItemName(item) {
            this.reset();
            const newItem = { ...this.todoSubItem, name: item.name }
            this.$emit("edit", newItem);
        },
        markAsDone() {
            const newItem = { ...this.todoSubItem, isDone: !this.todoSubItem.isDone }
            this.$emit("edit", newItem);
        },
    }
}
</script>