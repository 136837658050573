<template>
    <div></div>
    <!--begin::Row-->
    <div class="row g-10 mb-5 mt-2">
        <div class="col-12 col-lg-8">
            <div class="d-flex mt-2">
                <h2 class="align-bottom">Projets actifs</h2>
                <button class="ms-auto mb-3 py-2 px-4 btn btn-light-primary"
                        @click="$router.push({ name: 'projects' });">
                    Voir tous les projets
                </button>
            </div>
            <div class="row g-5 h-100">
                <div class="col-12 col-md-6 g-5" v-for="project in this.lastNProjects(2)" :key="project.id">
                    <div class="card cursor-pointer"
                         @click="$router.push({ name: 'project.home', params: { id: project.id } });">
                        <img class="card-img-top mh-100px object-fit-cover"
                             :src="project?.image?.url || 'https://app.kalm.ai/static/img/kalm-bg.png'"
                             @click="$router.push({ name: 'project.home', params: { id: project.id } });"
                             alt="Image de couverture du projet">
                        <div class="card-body pt-5 pb-3">
                            <div class="fs-4">{{ project.name }}</div>
                        </div>
                        <div class="card-footer d-flex pt-0 pb-4 border-0">
                            <span class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px">
                              {{ project?.currentPhase || "En cours" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="d-flex">
                <h2 class="fw-bolder mt-2">Parrainage</h2>
            </div>
            <div class="row g-5 h-100">
                <div class="col-12 g-5 h-100">
                    <div class="card h-auto">
                        <div class="card-body">
                            <div class="card-title fw-bolder">✨ Gagnez 1 mois gratuit !</div>
                            <div class="card-text">
                                <p>Nous avons une offre de parrainage très simple → 1 mois offert des deux côtés. Pour en bénéficier, rien de plus simple : obtenez votre lien personnalisé unique en cliquant ci-dessous.</p>
                            </div>
                            <div class="mt-5 d-flex justify-content-end">
                                <button class="btn btn-sm btn-primary" @click="this.showTrustlead()">Ça m'intéresse</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <TodosPage :assigned-to-current-user="true"/>
        </div>
        <div class="col-12 col-md-">
            <h2 class="fw-bolder mb-4">Nouveautés sur Kalm</h2>
            <div class="row g-5 h-100">
                <div class="col-12 g-5 h-100">
                    <div class="card h-100">
                        <!--begin::Body-->
                        <div class="card-body">
                            <div id="kt_stats_widget_8_carousel" class="carousel carousel-custom slide" data-bs-ride="carousel" data-bs-interval="8000">
                                <!--begin::Heading-->
                                <div class="d-flex flex-stack flex-wrap">
                                    <!--begin::Label-->
                                    <p class="fs-6 text-muted fw-bold pe-2">Consultez les fonctionnalités à venir sur notre <a target="_blank" href="https://kalmai.notion.site/Kalm-Roadmap-Produit-T4-2024-10ee1e909bce80faa5bce316c5819c94">roadmap publique sur Notion</a></p>
                                    <!--end::Label-->

                                    <!--begin::Carousel Indicators-->
                                    <ol class="p-0 m-0 carousel-indicators carousel-indicators-dots">
                                        <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="0" class="ms-1"></li>
                                        <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="1" class="ms-1 active" aria-current="true"></li>
                                        <li data-bs-target="#kt_stats_widget_8_carousel" data-bs-slide-to="2" class="ms-1"></li>
                                    </ol>
                                    <!--end::Carousel Indicators-->
                                </div>
                                <!--end::Heading-->

                                <!--begin::Carousel-->
                                <div class="carousel-inner pt-8">
                                    <!--begin::Item-->
                                    <div class="carousel-item">
                                        <!--begin::Section-->
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <!--begin::Title-->
                                            <h3 class="fs-3 text-gray-800 fw-bold">🎉 Organisez votre agence avec la Todo</h3>
                                            <!--end::Title-->

                                            <!--begin::Text-->
                                            <p class="text-gray-600 pt-3 mb-0">
                                                Vous pouvez désormais faire de Kalm votre outil du quotidien, en ajoutant des tâches à réaliser pour vous et votre équipe.
                                            </p>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Section-->
                                    </div>
                                    <!--end::Item-->

                                    <!--begin::Item-->
                                    <div class="carousel-item active">
                                        <!--begin::Section-->
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <!--begin::Title-->
                                            <h3 class="fs-3 text-gray-800 fw-bold">✨ Ajoutez une décennale à vos contacts</h3>
                                            <!--end::Title-->

                                            <!--begin::Text-->
                                            <p class="text-gray-600 fw-normal pt-3 mb-0">
                                                Ou n'importe quel autre fichier comme un Kbis, une attestation d'assurance, etc.
                                                Kalm vous permet de stocker tous vos documents importants dans un seul endroit !
                                            </p>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Section-->
                                    </div>
                                    <!--end::Item-->

                                    <!--begin::Item-->
                                    <div class="carousel-item">
                                        <!--begin::Section-->
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <!--begin::Title-->
                                            <h3 class="fs-3 text-gray-800 fw-bold">👷 Nouvelle page consultation des entreprise</h3>
                                            <!--end::Title-->

                                            <!--begin::Text-->
                                            <p class="text-gray-600 pt-3 mb-0">
                                                Vous pouvez maintenant réordonner les entreprises, stocker des fichiers (comme des devis) sur chaque entreprise consultée,
                                                afficher les groupes d'ouvrages, filtrer sur un lot...
                                            </p>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Section-->
                                    </div>
                                    <!--end::Item-->
                                </div>
                                <!--end::Carousel-->
                            </div>
                        </div>
                        <!--end::Body-->

                        <!--begin::Footer-->
                        <div class="card-footer border-0 d-flex flex-stack pt-0 pb-10">
                            <!--begin::Label-->
                            <span class="text-muted fs-6 fw-semibold pe-2">Une question ? Une idée ?</span>
                            <!--end::Label-->

                            <a :href="'https://tally.so/r/nWEr4L?user_email='+currentUser.email" target="_blank" class="btn btn-sm btn-primary fw-bold">Je donne mon avis</a>
                        </div>
                        <!--end::Footer-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Row-->
</template>

<script>
import {ALL_PROJECTS_NAMES_QUERY, CURRENT_USER_QUERY} from "@/graphql/graphql";
import TodosPage from "@/views/todos/presentation/todos/TodosPage.vue";

export default {
    name: "DashboardPage",
    components: {
        TodosPage
    },
    apollo: {
        currentUser: CURRENT_USER_QUERY,
        projects: {
            query: ALL_PROJECTS_NAMES_QUERY,
            fetchPolicy: 'network-and-cache',
        },
    },
    data: () => ({
        currentUser: 'unavailable',
        projects: [{
            name: 'Chargement...',
        }],
    }),
    methods: {
        showTrustlead(){
            window._trustlead?.show()
        },
        lastNProjects(count=2) {
            return JSON.parse(JSON.stringify(this.projects)).filter(p => !p.archived).sort((a, b) => {
                if (a?.lastUpdateTime == null && b?.lastUpdateTime == null) {
                    return a?.name.localeCompare(b?.name)
                } else if (a?.lastUpdateTime == null) {
                    return 1
                } else if (b?.lastUpdateTime == null) {
                    return -1
                } else {
                    return a.lastUpdateTime < b.lastUpdateTime ? 1 : -1
                }
            }).slice(0, count);
        }
    },
    computed: {
    }
}
</script>

<style scoped>
h1,
h2,
h3,
.card {
    transition: all 50ms ease-in-out;
}

.card.clickable:hover {
    transform: scale(1.01);
    cursor: pointer;
}
</style>