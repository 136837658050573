<template>
    <div class="modal" tabindex="-1" style="display: block;" id="AddLocationModal">
        <div class="modal-dialog modal-dialog-centered mw-600px">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-light-primary" @click="$emit('close')">
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body p-0">
                        <!--begin::Stepper-->
                        <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                            <!--begin::Content0-->
                            <div class="d-flex flex-row-fluid justify-content-center">
                                <!--begin::Form-->
                                <div ref="contactModalForm" class="pb-5 w-100 w-sm-400px" novalidate>
                                    <!--begin::Step-->
                                    <div class="pb-5 completed">
                                        <div class="w-100">
                                            <!--begin::Heading-->
                                            <div class="pb-5">
                                                <h3 class="fw-bolder text-dark display-6">Ajouter un emplacement</h3>
                                            </div>
                                            <!--end::Heading-->


                                            <!--begin::Form Group-->
                                            <div class="mb-10 w-100">
                                                <label class="fs-6 me-2 fw-bolder text-dark form-label">Nom de
                                                    l'emplacement</label>
                                                <input type="text" v-model="modalLocationName"
                                                    class="form-control form-control-lg form-control-solid"
                                                    placeholder="Chambre 01">
                                            </div>
                                            <!--end::Form Group-->
                                        </div>
                                    </div>
                                    <!--end::Step-->

                                    <!--begin::Actions-->
                                    <div class="d-flex justify-content-end">
                                        <div>
                                            <button type="button" @click="$emit('close')"
                                                    class="btn btn-lg btn-light">
                                                Annuler
                                            </button>
                                            <button type="submit" @click="validateAddLocation"
                                                    class="btn btn-lg btn-primary ms-2">
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                                <!--end::Form-->
                            </div>
                            <!--end::Content0-->
                        </div>
                        <!--end::Stepper-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MUTATION_PROJECT_CREATE_LOCATION
} from "@/views/locations/data/location_graphql"

export default {
    name: 'AddLocationModal',
    props: {
        modelValue: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        }
    },
    methods: {
        async validateAddLocation() {
            try {
                await this.$apollo.mutate({
                    mutation: MUTATION_PROJECT_CREATE_LOCATION,
                    variables: {
                        projectId: this.projectId,
                        newLocation: {
                            name: this.modalLocationName
                        }
                    }
                })

            } catch (error) {
                console.error(error)
            }
            this.$emit('validate')
        }
    },
    computed: {
        modalLocationName: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>