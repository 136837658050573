<template>
    <div
    class="modal"
    tabindex="-1"
    @keydown.esc="this.$emit('close')"
    style="display: block;"
    >
        <div class="modal-dialog modal-dialog-centered modal-size">
            <div class="modal-content mx-5">
                <div class="container px-10 py-10">
                    <div class="modal-header p-0 d-flex justify-content-end border-0">
                    <!--begin::Close-->
                        <div
                        class="btn btn-icon btn-sm btn-light-primary"
                        @click="this.$emit('close')"
                        >
                            <i class="bi bi-x-lg"></i>
                        </div>
                        <!--end::Close-->
                    </div>
                    <DocumentsPage :callWith="'modal'" :project="projectId" v-model="page" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DocumentsPage from '../DocumentsPage.vue';

export default {
    name: "ExplorerModal",
    props: {
        modelValue: {
            type: String,
            Required: true,
        },
        projectId: {
            type: String,
            Required: true,
        },
    },
    computed: {
        page: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        }
    },
    components: {
        DocumentsPage,
    },
    mounted() {
        console.log(this.modelValue)
    }
}
</script>

<style lang="scss" scoped>
.modal-size {
    max-width: 60%;
}

.iframe {
    height: 600px;
    width: 100%;
}
</style>