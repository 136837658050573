<template>
    <KalmModal modalTitle="Partager" @close="close">
        <template v-slot:body>
            <div>
                <input v-model="link" type="text" class="form-control" id="link">
                <button class="btn btn-primary btn-block mt-2" @click="copyLink">
                    Copier le lien
                </button>
            </div>
        </template>

        <template v-slot:footer>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">Ok</button>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import { LINK_CREATE } from '../../data/links_graphql';
export default {
    name: "LinkCreationModal",
    components: {
        KalmModal,
    },
    props: {
        fileId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            link: undefined,
        };
    },
    mounted() {
        this.createLink(this.fileId);
    },
    methods: {
        async createLink(fileId) {
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: LINK_CREATE,
                    variables: { fileId },
                });
                this.link = window.location.origin + '/shared-files/' + data.linkCreate.link.id;
            } catch (error) {
                console.error("Error creating link:", error);
            }
        },
        copyLink() {
            if (!this.link) return;
            const input = document.getElementById('link');
            input.select();
            document.execCommand('copy');
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>