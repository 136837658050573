import { createRouter, createWebHistory } from 'vue-router';

import MainView from "@/MainView";
import WrapperPage from "@/views/_core/WrapperPage";
import ContactDetailsPage from "@/views/contacts/presentation/contact-details/ContactDetailsPage.vue";
import ContactsPage from "@/views/contacts/presentation/contacts/ContactsPage.vue";
import DashboardPage from "@/views/dashboard/DashboardPage";
import DocumentsPage from "@/views/documents/presentation/file-explorer/DocumentsPage.vue";
import LogoutPage from "@/views/project/LogoutPage";
import ProjectDetail from "@/views/project/ProjectDetail";
import ProjectsPage from "@/views/project/ProjectsPage";
import BiblioDetail from "@/views/project/biblio/BiblioDetail";
import ProjectDescriptionPage from "@/views/project/description/ProjectDescriptionPage";
import ProjectHomePage from "@/views/project/home/ProjectHomePage";
import ProjectMonitoringPage from "@/views/project/monitoring/ProjectMonitoringPage";
import ProjectMonitoringPageOld from "@/views/project/monitoring/ProjectMonitoringPageOld";
import ProjectPlanningPage from "@/views/project/planning/ProjectPlanningPage";
import ProjectPlanningPageNew from "@/views/project/planning/ProjectPlanningPageNew";
import ProjectTenderManagePage from "@/views/project/tender/ProjectTenderManagePage";
import ProjectTenderScenariosPage from "@/views/project/tender/ProjectTenderScenariosPage";
import ProjectTenderTablePage from "@/views/project/tender/ProjectTenderTablePage";
import SettingsAccountPage from "@/views/settings/SettingsAccountPage";
import SettingsSubscriptionPage from "@/views/settings/SettingsSubscriptionPage";
import SettingsTeamPage from "@/views/settings/SettingsTeamPage";
import TasksPage from "@/views/tasks/presentation/tasks/TasksPage.vue";
import TodosPage from "@/views/todos/presentation/todos/TodosPage.vue";
import BibliosPage from "@/views/project/BibliosPage.vue";
import BiblioEditPage from "@/views/project/biblio/BiblioEditPage";
import NotesEditorPage from "@/views/project/notes/NotesEditorPage";
import NotesList from '@/views/project/notes/NotesList.vue';
import ProjectTenderTableLotsPage from "@/views/project/tender/ProjectTenderTableLotsPage.vue";
import CanevasPage from '@/views/canevas/presentation/CanevasPage.vue';
import CanevaItemPage from '@/views/canevas/presentation/CanevaItemPage.vue';
import FilesByLinkPage from "@/views/documents/presentation/files-by-link/FilesByLinkPage.vue";


function authGuard(to, from, next) {
    next();
    /*fetch(process.env.VUE_APP_URL_BACK+"/auth/session/", {credentials: "include"})
        .then(response => response.json())
        .then((data) => {
            if(!data.isAuthenticated) {
                window.location.href = process.env.VUE_APP_URL_BACK+"/auth/login";
            }
            else {
                next();
            }
        })*/
}

const routes = [
    {
        path: '/shared-files/:linkId',
        name: 'shared.files',
        component: FilesByLinkPage,
        meta: {
            requiresAuth: false,
            title: "Documents"
        }
    },
    {
        path: '/',
        name: 'root',
        redirect: '/dashboard',
        component: MainView,
        beforeEnter: authGuard,
        children: [
            {
                path: '/logout',
                name: 'logout',
                component: LogoutPage,
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: DashboardPage,
                beforeEnter: authGuard,
                meta: {
                    title: "Tableau de bord"
                }
            },
            {
                path: '/settings',
                component: WrapperPage,
                beforeEnter: authGuard,
                children: [
                    {
                        path: '',
                        name: 'settings',
                        redirect: '/settings/account',
                    },
                    {
                        path: 'account',
                        name: 'settings.account_settings',
                        component: SettingsAccountPage,
                        meta: {
                            requiresAuth: true,
                            title: "Paramètres"
                        }
                    },
                    {
                        path: 'studio',
                        name: 'settings.studio_settings',
                        component: SettingsTeamPage,
                        meta: {
                            requiresAuth: true,
                            title: "Paramètres"
                        }
                    },
                    {
                        path: 'subscription',
                        name: 'settings.subscription_settings',
                        component: SettingsSubscriptionPage,
                        meta: {
                            requiresAuth: true,
                            title: "Paramètres"
                        }
                    }
                ]
            },
            {
                path: '/contacts',
                name: 'contacts',
                component: ContactsPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Contacts"
                }
            },
            {
                path: '/contacts/:id',
                name: 'contact_detail',
                component: ContactDetailsPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Contact"
                }
            },
            {
                path: '/projects',
                name: 'projects',
                component: ProjectsPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Mes projets"
                }
            },
            {
                path: '/todos',
                name: 'todos',
                component: TodosPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Todos d'agence"
                },
            },
            {
                path: '/canevas',
                name: 'canevas',
                component: CanevasPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Canevas"
                }
            },
            {
                path: '/canevas/:id',
                name: 'caneva_detail',
                component: CanevaItemPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Caneva"
                }
            },
            {
                path: '/documents',
                name: 'documents',
                component: DocumentsPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Documents"
                }
            },
            {
                path: '/documents/:parentId',
                name: 'documents.folder',
                component: DocumentsPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Documents"
                }
            },
            {
                path: '/projects/:id',
                component: ProjectDetail,
                beforeEnter: authGuard,
                children: [
                    {
                        path: '',
                        name: 'project.home',
                        component: ProjectHomePage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Projet"
                        }
                    },
                    {
                        path: 'notes',
                        name: 'project.notes',
                        component: NotesList,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Notes"
                        },
                    },
                    {
                        path: 'notes/:noteId',
                        name: 'project.note',
                        component: NotesEditorPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Note"
                        }
                    },
                    {
                        path: 'planning',
                        name: 'project.planning',
                        component: ProjectPlanningPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Planning"
                        }
                    },
                    {
                        path: 'planning_new',
                        name: 'project.planning_new',
                        component: ProjectPlanningPageNew,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Planning"
                        }
                    },
                    {
                        path: 'consultation',
                        component: WrapperPage,
                        beforeEnter: authGuard,
                        children: [
                            {
                                path: '',
                                name: 'project.tender',
                                redirect: { name: 'project.tender.manage' },
                            },
                            {
                                path: 'manage',
                                name: 'project.tender.manage',
                                component: ProjectTenderManagePage,
                                meta: {
                                    requiresAuth: true,
                                    title: "Consultation - Suivi"
                                }
                            },
                            {
                                path: 'table',
                                name: 'project.tender.table',
                                component: ProjectTenderTablePage,
                                meta: {
                                    requiresAuth: true,
                                    title: "Consultation - Comparatif"
                                }
                            },
                            {
                                path: 'table_lots',
                                name: 'project.tender.table_lots',
                                component: ProjectTenderTableLotsPage,
                                meta: {
                                    requiresAuth: true,
                                    title: "Consultation - Comparatif par lot"
                                }
                            },
                            {
                                path: 'scenarios',
                                name: 'project.tender.scenarios',
                                component: ProjectTenderScenariosPage,
                                meta: {
                                    requiresAuth: true,
                                    title: "Consultation - Scénarios"
                                }
                            },
                        ]
                    },
                    {
                        path: 'description',
                        name: 'project.description',
                        component: ProjectDescriptionPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Descriptif"
                        }
                    },
                    {
                        path: 'monitoring_old',
                        name: 'project.monitoring_old',
                        component: ProjectMonitoringPageOld,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Suivi de chantier (appsheet)"
                        }
                    },
                    {
                        path: 'monitoring_new',
                        name: 'project.monitoring_new',
                        component: ProjectMonitoringPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Chantier (v2)"
                        }
                    },
                    {
                        path: 'documents',
                        name: 'project.documents',
                        component: DocumentsPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Documents"
                        }
                    },
                    {
                        path: 'documents/:parentId',
                        name: 'project.documents.folder',
                        component: DocumentsPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Documents"
                        }
                    },
                    {
                        path: 'tasks',
                        name: 'project.tasks',
                        component: TasksPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Tâches"
                        }
                    },
                    {
                        path: 'todos',
                        name: 'project.todos',
                        component: TodosPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Todos du projet"
                        }
                    },
                ]
            },
            {
                path: '/biblios',
                name: 'biblios',
                component: BibliosPage,
                beforeEnter: authGuard,
                meta: {
                    requiresAuth: true,
                    title: "Bibliothèques d'ouvrages"
                }
            },
            {
                path: '/biblios/:id',
                component: BiblioDetail,
                beforeEnter: authGuard,
                children: [
                    {
                        path: '',
                        name: 'biblio.edit',
                        component: BiblioEditPage,
                        beforeEnter: authGuard,
                        meta: {
                            requiresAuth: true,
                            title: "Bibliothèque d'ouvrages"
                        }
                    },
                ]
            },
        ]
    },
]
const kalmRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (to.name !== "project.description") {
            if (savedPosition) {
                return savedPosition;
            }
            else {
                if (to.hash) {
                    return {
                        el: to.hash,
                        behavior: 'smooth',
                        top: 70,
                    }
                }
                else {
                    return {
                        top: 0,
                    }
                }
            }
        }
    },
    routes
})

kalmRouter.afterEach((to) => {
    document.title = `Kalm | ${to.meta?.title || "L'assistant tout en un des architectes et designers d'intérieur"}`
});


/* TODO : add auth check

router.beforeEach((to) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth && !auth.isLoggedIn()) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return {
            path: '/login',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }
})*/

export default kalmRouter