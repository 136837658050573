<template>
  <main :class="isModalActive ? 'blur-content' : ''">
    <section class="row">
        <div class="col-4">
          <div class="card card-stretch">
            <div class="card-header d-flex justify-content-between align-items-center border-bottom py-3">
              <div class="d-flex align-items-center">
                <AvatarLogo/>
                <div class="mx-3">
                  <span class="text-black fw-bold d-block">
                    {{ contact?.company }}
                  </span>
                  <span class="text-muted fw-bold d-block">
                    {{ contact?.name }}
                  </span>
                </div>
              </div>  
  
              <button class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                      @click="this.modals.editContact.show = true">
                <span class="bi bi-pencil-fill"></span>
              </button>
  
            </div>
            <div class="card-body border-bottom">
              <h2 class="text-black fw-bold d-block">Informations Personnelles</h2>
  
              <ul class="mt-3 px-0">
                <li v-for="(value, key) in getPersonnalInformations" :key="key" class="d-flex">
                  <span class="text-muted fw-bold d-block">{{ key }}:</span>
                  <span class="text-black fw-bold d-block mx-3">{{ value }}</span>
                </li>
              </ul>
            </div>  
            <div class="card-body">
              <h2 class="text-black fw-bold d-block">Autres Informations</h2>
              <ul class="mt-3 px-0">
                <li v-for="(value, key) in getOthersInformations" :key="key" class="d-flex">
                  <span class="text-muted fw-bold d-block">{{ key }}:</span>
                  <span class="text-black fw-bold d-block mx-3">{{ value }}</span>
                </li>
              </ul>
            </div>
          </div>
  
        </div>
        <div class="col-8">
          <div class="card card-stretch">
            <div class="card-body pt-3 mt-n3">
              <h2 class="text-black fw-bold d-block pt-5">Projets</h2>
              <div class="tab-content mt-4" id="projectParticipationTable">
                <div class="table-responsive">
                  <table class="table table-borderless align-middle">
                    <thead>
                      <tr>
                        <th class="p-0 w-50px"></th>
                        <th class="p-0 w-150px"></th>
                        <th class="p-0 min-w-70px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="participation in contact?.projectParticipations" :key="participation.project.id" class="bg-hover-light">
                        <td class="ps-3 rounded-start">
                          <i class="bi bi-bricks fs-1 align-middle align-self-center"></i>
                        </td>
                        <td class="px-0">
                          <span class="text-black fw-bold d-block mt-1">{{ participation.project.name }}</span>
                          <span class="text-muted fw-bold d-block mt-1">{{ participation.role }}</span>
                        </td>
                        <td class="text-end pe-3 rounded-end">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
  
          <div class="card card-stretch mt-5">
            <div class="card-body pt-3 mt-3">
              <h2 class="text-black fw-bold d-block pt-5">Devis</h2>
              <div class="tab-content mt-4" id="projectParticipationTable">
                <div class="table-responsive">
                  <table class="table table-borderless align-middle">
                    <thead>
                      <tr>
                        <th class="p-0 w-50px"></th>
                        <th class="p-0 w-150px"></th>
                        <th class="p-0 min-w-70px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="proposal in contact?.proposals" :key="proposal.id" class="bg-hover-light">
                        <td class="ps-3 rounded-start">
                          <i class="bi fs-1 bi-file-text align-middle align-self-center"></i>
                        </td>
                        <td class="px-0">
                          <span class="text-black fw-bold d-block mt-1">{{ proposal.name }}</span>
                          <span class="text-muted fw-bold d-block mt-1">{{ proposal.project.name }}</span>
                        </td>
                        <td class="text-end pe-3 rounded-end">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    <section class="row mt-5">
      <div class="col-12 card card-stretch">
        <FileList :files="getFiles" :contactId="this.contact?.id" :query="CONTACT_BY_ID" :assignButton="false" :editButton="false" :callback="callbackOnFile" @onDelete="modalConfirmDeleteFileOpen"/>
      </div> 
    </section>
  </main>
  <ContactUpdateModal v-if="modals.editContact.show" v-model="editContact"  @close="closeModal()" />
  <DeleteFileConfirmModal v-if="modals.deleteFile.show" :fileId="fileId" @close="modalConfirmDeleteFileClose" :callback="callbackOnFile" />
</template>

<script>
import AvatarLogo from "@/views/_core/components/AvatarLogo.vue";
import ContactUpdateModal from "../components/ContactUpdateModal.vue";
import DeleteFileConfirmModal from "@/views/documents/presentation/file-explorer/components/DeleteFileConfirmModal.vue";
import { CONTACT_BY_ID } from "../../data/contacts_graphql";
import { FILE_UPDATE } from "@/views/documents/data/files_graphql";
import FileList from "@/views/documents/presentation/file-explorer/components/FileList.vue";

export default {
  name: "ContactDetailsPage",
  components: { 
    AvatarLogo,
    ContactUpdateModal,
    DeleteFileConfirmModal,
    FileList,
  },
  data() {
    return {
      modals: {
        editContact: {
          show: false,
        },
        deleteFile: {
          show: false,
        },
      },
      fileId: null,
    } 
  },
  apollo: {
    contact: {
      query: CONTACT_BY_ID,
      variables() {
        return {
          contactId: this.$route.params.id,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      fetchPolicy: "cache-and-network",
    },
  },
  methods: {
    keepKeys(object, keys) {
      return Object.entries(object)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, _]) => {
          return keys.includes(key)
        })
        .reduce((obj, key) => {
          obj[key[0]] = key[1];
          return obj;
        }, {});
    },
    closeModal() {
      this.modals.editContact.show = false;
    },
    editContactFile(file) {
      this.$apollo.mutate({
        mutation: FILE_UPDATE,
        variables: {
          file: {
            id: file.id,
            name: file.name,
            description: file.description,
          },
        },
      });
    },
    callbackOnFile() {
      this.$apollo.queries.contact.refetch();
    },
    modalConfirmDeleteFileOpen(file) {
      this.fileId = file.id;
      this.modals.deleteFile.show = true;
    },
    modalConfirmDeleteFileClose() {
      this.fileId = null;
      this.modals.deleteFile.show = false;
    },
  },
  computed: {
    getPersonnalInformations() {
      if (!this.contact) return {};

      return this.keepKeys(this.contact, ["name", "email", "company", "phone", 'phone2', "type", 'infoAddress']);
    },
    getOthersInformations() {
      if (!this.contact) return {};

      return this.keepKeys(this.contact, ["infoComment", "infoCorps", "infoInsurance", "infoLine1", "infoLine2", "infoSiret", "infoTva", "infoWebsite"]);
    },
    isModalActive() {
      return this.modals.editContact.show;
    },
    editContact() {
      return {...this.contact};
    },
    getFiles() {
      if (!this.contact) return [];

      return this.contact.files;
    }
  }
}
</script>

<style scoped>

</style>